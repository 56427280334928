@use '../abstracts' as *;

/* contact
********************************************** */
.section-contact .cta_tel {
  width: 100%;
  max-width: 400px;
  @include auto-margin;
}
.section-contact .cta_tel a {
  padding: 24px 40px;
  border: 1px solid;
  background-color: var(--clr-wht, #fff);
  @include mq(sp) {
    display: block;
    padding: 16px;
  }
}