@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  width: calc(100% - 80px - 264px);
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--img {
  overflow: hidden;
  background-color: #f8f8f8;
}
.post--img img:not(.img-cover) {
  inset: 0;
  max-width: 80%;
  margin: auto;
}
.hover-lg .post--img img {
  transition: all .8s ease-out;
}
.hover-lg:hover .post--img img {
  transform: scale(1.05);
}
.post--date {
  letter-spacing: .1em;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(12);
  border: 1px solid;
  background-color: rgba($color: #fff, $alpha: .8);
  padding: .125em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
}
.cat_list a:hover {
  color: var(--clr-wht, #fff);
  border-color: var(--clr-main, #1d7fb7);
  background-color: var(--clr-main, #1d7fb7);
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 264px;
  @include mq(1100) {
    width: 100%;
  }
}
.side_section:not(:last-child) {
  margin-bottom: rem(64);
}
.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1.2;
  margin-bottom: 1em;
}
.side--ttl small {
  opacity: .5;
  letter-spacing: 0.08em;
  display: block;
  margin-top: .5em;
  font-weight: 500;
}
.mce-content-body .side--list a {
  text-decoration: none;
  &:hover {
    opacity: .7;
  }
}
.mce-content-body .side--list .children {
  margin-top: 0;
}
.mce-content-body .side--list .children li::before {
  background-color: var(--clr-wht, #fff);
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: rem(16);
}
.posts-side .post--link:hover {
  opacity: .7;
}
.posts-side .post--img {
  margin-right: rem(16);
  @include rect(64);
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}
.posts-side .post--txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  @include mq(sp) {
    margin-top: 6px;
    -webkit-line-clamp: 2;
    line-height: 1.5;
  }
}

/* --- archive --- */
.side_column .archive_list {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #D3D3D3;
}
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  padding-top: .25em;
  padding-bottom: .25em;
  transition: var(--trans-default, all .4s ease-out);
  &:hover {
    opacity: .7;
  }
  &::after {
    content: '';
    display: block;
    width: rem(8);
    height: rem(8);
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
    transition: var(--trans-default, all .4s ease-out);
  }
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
  padding-bottom: .25em;
}
.side_column .archive_month li {
  position: relative;
  padding-left: 1.5em;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: .5em;
    width: .5em;
    height: 1px;
    border-bottom: 1px solid;
  }
}
.side_column .archive_month a:hover {
  opacity: .7;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
  &:not(:last-child) {
    border-bottom: 1px solid #D3D3D3;
  }
  &:hover {
    background-color: #C9D6DA;
  }
}
.archive-pulldown .archive_list--label {
  margin-right: rem(8);
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: .25em 4em .25em 2em;
  background-color: #fff;
  border: 1px solid;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: .08em;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: .4em;
    right: .8em;
    margin: auto;
    width: .6em;
    height: .6em;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
    transition: all .2s ease-out;
  }
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all .2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* --- cat --- */
  .cat {
    margin-bottom: rem(40);
    z-index: 2;
    @include mq(sp) {
      border: 1px solid;
    }
  }
  .cat--list a {
    position: relative;
    display: block;
    padding: .5em 1em;
    @include mq(sp) {
      background-color: var(--clr-wht, #fff);
    }
    @include mq(sp, min, ps) {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include rect(24);
        background-color: #C9D6DA;
        border-radius: 50%;
        z-index: -1;
        opacity: 0;
        transition: var(--transit-default, all .4s ease-out);
      }
    }
  }
  .cat--list .current-cat a::before,
  .cat--list a:hover::before {
    @include mq(sp, min, ps) {
      opacity: 1;
    }
  }

  @include mq(sp) {
    .cat--label {
      position: relative;
      padding: .5em 1em;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1em;
        margin: auto;
        width: rem(8);
        height: rem(8);
        border-bottom: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg);
      }
    }
    .cat--pulldown {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: z(middle);
      background-color: rgba($color: #fff, $alpha: .8);
      opacity: 0;
      pointer-events: none;
      transition: var(--transit-default, all .4s ease-out);
      &.active {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .cat--close {
      position: relative;
      @include sprect(40);
      align-self: flex-end;
      margin-right: 7%;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 90%;
        height: 1px;
        border-bottom: 1px solid;
      }
      &::before  {
        transform: rotate(45deg);
      }
      &::after  {
        transform: rotate(-45deg);
      }
    }
    .cat--list {
      display: block;
      border: 1px solid;
      width: 86%;
      max-height: 323px;
      overflow: auto;
    }
    .cat--list li:not(:last-child) {
      border-bottom: 1px solid #D3D3D3;
    }
    .cat--list .current-cat a {
      background-color: #C9D6DA;
    }
  }

/* style
**************************************** */
.archive .posts-style {
  @include mq(sp, min, ps) {
    display: flex;
    flex-wrap: wrap;
  }
}
.archive .posts-style .post {
  margin-bottom: 8px;
  @include mq(sp, min, ps) {
    width: calc(33% - 8px);
    margin-right: 8px;
  }
}
.posts-style .post--img {
  width: 100%;
  &::before {
    content: "";
    display: block;
    padding-top: perc(4,3,'%');
  }
}
.posts-style .post--link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.posts-style .post--txtarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: rem(8);
  transition: var(--transit-default, all .4s ease-out);
}
.posts-style .post:hover .post--txtarea {
  background-color: rgba($color: $clr-main, $alpha: .3);
}
.posts-style .post--ttl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
}
.posts-style .post--txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default, all .4s ease-out);
}
.posts-style .post:hover .post--ttl,
.posts-style .post:hover .post--txt {
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  box-shadow: 0 0 16px rgba(90, 116, 138, .3);
  background-color: #fff;
}
.posts-blog .post--img {
  &::before {
    padding-top: 75%;
  }
}
.posts-blog .post--txtarea {
  padding: rem(6) rem(20) rem(12);
  word-break: break-word;
}
.posts-blog .cat_list {
  position: absolute;
  top: rem(8);
  right: rem(8);
}
.posts-blog .post--ttl {  
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 1rem 2rem;
  margin-bottom: 0;
  letter-spacing: .01em;
  @include mq(sp) {
    display: block;
    padding: 1rem 0 1.5rem;
  }
}
.news_list .post:not(:first-child) {
  border-top: 1px solid #D3D3D3;
}
.news_list .post--info {
  margin-right: 2rem;
  @include mq(sp) {
    margin-bottom: 4px;
    margin-right: 0;
  }

  &::before {
    content: "";
    display: inline-block;
    @include rect(16);
    border-radius: 50%;
    border: solid 1px;
    margin-right: 2rem;
    @include mq(sp) {
      margin-right: .5rem;
    }
  }
}
.news_list .post--date {
  min-width: 10em;
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}
