@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 80px;
  padding-bottom: 80px;
  @include mq(sp) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.section_pdg-sm {
  padding-top: 40px;
  padding-bottom: 40px;
  @include mq(sp) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
