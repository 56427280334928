@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-color: var(--bg-off_wht, #f7f7f7);
  height: 400px;
  @include mq(sp) {
    height: 200px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: rem(80);
  letter-spacing: -.025em;
  line-height: 1.5;
  font-family: var(--font-en, "Montserrat", sans-serif);
  @include mq(sp) {
    font-size: rem(40);
  }
}
.page_ttl-jp {
  font-size: 1rem;
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  letter-spacing: -.025em;
}
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
}
.ttl-02,
.ttl-03:not(:last-child),
.ttl-04:not(:last-child) {
  margin-bottom: 1em;
}
.ttl-01 {
  margin-bottom: rem(48);
  font-size: rem(96);
  line-height: 1.2;
  color: var(--clr-main, #1d7fb7);
  @include mq(sp) {
    margin-bottom: rem(24);
    font-size: rem(48);
  }
}
.ttl-01.deco {
  &::after {
    content: '';
    display: block;
    width: 26px;
    border-top: 6px dotted;
    margin-top: rem(28);
    @include mq(sp) {
      width: 14px;
      border-top-width: 3px;
      margin-top: rem(16);
    }
  }
}
.ttl-02 {
  font-size: 2.5rem; /* 40px */
  @include mq(sp) {
    font-size: 1.75rem;
  }
}
.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem; /* 40px */
  @include mq(sp) {
    font-size: 1.5rem;
  }
}
.ttl-03 {
  font-size: 1.75rem; /* 28px */
  @include mq(sp) {
    font-size: 1.5rem;
  }
}
.ttl-04 {
  font-size: 1.5rem; /* 24px */
}
.ttl-04 small {
  display: block;
  opacity: .5;
  margin-top: .5em;
  font-weight: 500;
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
}
