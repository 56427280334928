@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* footer_schedule
********************************************** */
.subpage .footer_schedule {
  margin-top: 40px;
}
.footer_schedule {
  padding-top: 84px;
  padding-bottom: 68px;
  background-color: #fff;
  @include mq(sp) {
    padding-top: 42px;
    padding-bottom: 34px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 30%;
    z-index: 0;
    display: block;
    width: vw(1700);
    background-image: url(/wp/wp-content/themes/standard_sass/images/common/schedule-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    @include mq(med) {
      width: 91%;
    }
    @include mq(sp) {
      width: 93%;
      bottom: 20%;
    }
  }
}
.footer_schedule .ttl-01 {
  position: absolute;
  z-index: 20;
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
  @include mq(med) {
    left: 1%;
    right: auto;
  }
  @include mq(sp, min) {
    writing-mode: vertical-rl;
    font-feature-settings: "pkna";
    transform: rotate(180deg);
    bottom: 20%;
    right: vw(1700);
  }
  @include mq(sp) {
    top: -.5em;
    left: 3%;
  }
}
.footer_schedule .inner {
  @include mq(1400) {
    margin-right: 3%;
  }
  @include mq(1400) {
    max-width: calc(94% - calc(100% - vw(1700)));
  }
  @include mq(med) {
    max-width: 86%;
  }
  @include mq(sp) {
    max-width: 95%;
    margin-right: 0;
  }
}
.xo-event-calendar {
  font-family: "Sen", sans-serif;
  text-align: center;
}
.xo-months {
  display: flex;
  justify-content: space-between;
  @include mq(sp) {
    display: block;
  }
}
.xo-month-wrap {
  width: 32%;
  padding: vw(32);
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  @include mq(sp) {
    width: 100%;
    padding: 24px;

    &:last-child {
      display: none;
    }
  }
}
.xo-event-calendar table.xo-month .month-header>span {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  font-size: rem(24);
  letter-spacing: -.05em;
  line-height: 1;
}
.xo-event-calendar table.xo-month .month-header>span span {
  font-size: rem(40);
  letter-spacing: -.05em;
}
.xo-event-calendar table.xo-month,
.xo-event-calendar table.xo-month td,
.xo-event-calendar table.xo-month th {
  border: none;
}
.xo-event-calendar table.xo-month td,
.xo-event-calendar table.xo-month th {
  padding-top: 16px;
  padding-bottom: 16px;
}
.xo-event-calendar table.xo-month .month-dayname td {
  padding: 10px 4px;
}
.xo-event-calendar table.xo-month .month-dayname td div {
  height: auto;
  font-size: rem(20);
  letter-spacing: -.08em;
  text-align: center;
  border-radius: 50%;
}
.xo-event-calendar table.xo-month>thead th.sunday,
.xo-event-calendar table.xo-month .month-dayname td:first-child div {
  color: #9A796F;
}
.xo-event-calendar table.xo-month>thead th.saturday,
.xo-event-calendar table.xo-month .month-dayname td:last-child div {
  color: #486E7A;
}
.xo-event-calendar table.xo-month .month-dayname td div.today {
  color: var(--clr-body, #333);
}
.xo-event-calendar p.holiday-title {
  text-align: left;
}
.xo-event-calendar p.holiday-title span {
  border-radius: 50%;
}
.xo-event-calendar p.holiday-title span {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  transform: translateY(.4rem);
}

/* footer_reserve
********************************************** */
.footer_reserve {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 100px;
  margin-bottom: -80px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 50px;
    margin-bottom: -40px;
  }
}
.footer_reserve .ttl-01 {
  margin-bottom: rem(12);
}
.reserve--cta .gnav--contact a {
  width: 240px;
  height: 64px;
  padding-top: .5em;
  padding-bottom: .5em;
  line-height: 1.2;
  @include mq(sp) {
    width: 100%;
    height: 68px;
    padding-top: .75em;
    margin-top: 8px;
  }
}
.reserve--cta .gnav--contact .font-en {
  @include mq(sp) {
    display: inline-block;
    margin-top: 4px;
  }
}
.reserve--cta .gnav--tel a {
  width: 240px;
  height: 64px;
  border: solid 1px;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  @include mq(sp) {
    width: 100%;
  }
  
  &::before {
    display: none;
  }
}
.reserve--cta .gnav--contact + .gnav--tel {
  @include mq(sp, min) {
    margin-left: 40px;
  }
  @include mq(sp) {
    margin-top: 4px;
  }
}

/* footer_top
********************************************** */
.footer,
.footer_top {
  background-color: #393939;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer-bg.jpg);
  background-size: cover;
}
.footer_top {
  padding-top: 96px;
  padding-bottom: 32px;
  @include mq(sp) {
    padding-top: 80px;
  }
}
.footer_top .inner {
  max-width: 1200px;
  @include mq(sp) {
    width: 76%;
  }
}
.footer--logo {
  transform: translateX(-56px);
  @include mq(sp) {
    transform: translateX(-28px);
  }

  img {
    @include mq(sp) {
      width: 102px;
    }
  }
}
.footer_top .txtarea {
  @include mq(sp) {
    width: 100%;
  }
}

/* ---sns_area --- */
.sns_area {
  margin-top: 28px;
  @include mq(sp) {
    margin-top: 24px;
  }
}
.sns--list li:not(:last-child) {
  margin-right: .5rem;
  @include mq(sp) {
    margin-right: 1rem;
  }
}
.sns--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  @include mq(sp) {
    width: 1.75rem;
    height: 1.75rem;
  }
}
.sns--list a:hover {
  opacity: .7;
}
.sns--list svg {
  width: 30px;
  fill: #fff;
}

/* ---fnav --- */
.fnav {
  @include mq(sp) {
    width: 100%;
    margin-top: 40px;
  }
}
.fnav--menu  {
  @include mq(sp, min) {
    margin-top: 24px;
    margin-left: vw(148);
  }
  @include mq(sp) {
    width: 50%;
  }
}
.fnav--menu a {
  position: relative;
  display: block;
  @include mq(sp, min) {
    margin-bottom: .75rem;
    padding-left: 2em;
  }
  @include mq(sp) {
    margin-bottom: .5rem;
  }

  @include mq(sp, min) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      margin: auto;
      opacity: 0;
      transition: var(--transit-default, all .4s ease-out);
    }
    &::before {
      left: .5em;
      width: .5em;
      height: .5em;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);
    }
    &::after {
      left: 0;
      width: 1em;
      height: 1px;
      border-bottom: 1px solid;
    }
    &:hover::before,
    &:hover::after {
      opacity: 1;
    }
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: 8px 0;
  color: #fff;
  background-color: #141515;
  @include mq(sp) {
    line-height: 1.5;
  }
}
.pbl a {
  opacity: .5;
  &:hover {
    opacity: 1;
  }
}
.copyright small {
  font-size: rem(10);
}

@include mq(sp, min) {
  .footer_btm .copyright {
    margin-right: 1.5em;
  }
}
