@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .anchor {
  padding-top: 60px;
  margin-top: -60px;
}
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child { width: 100%; }
.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--child .img-detail { height: 100%; }
.lps_parts--img_text .bg-wh .img_text--txt { padding: 5%; }
.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}
.lps_parts--img_text .text { justify-content: flex-start; }
.lps_parts--img_text .img_text--txt table { width: 100%; }
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}
.lps_parts--img_text .inner:not(.inner-lg) .img-detail {
  border-radius: 50%;
  overflow: hidden;
}
.lps_parts--img_text .inner:not(.inner-lg) .img-detail::before {
  padding-top: 100%;
}

@include mq(sp, min, ps) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@include mq(sp) {
  .lps_parts--img_text .anchor {
    padding-top: 8px;
    margin-top: -8px;
  }
  .lps_parts--img_text .bg-wh .img_text--txt { padding: 32px 24px; }
  .lps_parts--img_text .btn { text-align: center; }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img-detail {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}