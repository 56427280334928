@use "../abstracts" as *;

/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--bg-off_wht, #f7f7f7);
}
.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}
.bg-sub {
  background-color: #eee;
}
.bg-wh {
  background-color: var(--bg-wht, #fff);
}