@use '../abstracts' as *;

/* hero
********************************************** */
.home {
  overflow: hidden;
}
.hero {
  position: relative;
  padding: 56px vw(40) 0;
  @include mq(sp) {
    padding: 28px vw(64) 0;
    margin-bottom: 80px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    z-index: 100;
    background-image: url(/wp/wp-content/themes/standard_sass/images/home/fv-deco.png);
    background-repeat: no-repeat;
    background-position: top 10% center;
    pointer-events: none;
    mix-blend-mode: screen;
  }
}

.hero_bg {
  height: 584px;
  @include mq(sp) {
    height: 280px;
  }
}

.hero_catch {
  position: absolute;
  top: 69%;
  right: vw(120);
  font-size: rem(14);
  line-height: 1.5;
  letter-spacing: .05em;
  @include mq(sp) {
    top: auto;
    right: 3%;
    bottom: -22%;
    max-width: calc(100% - 150px);
    font-size: rem(8);
  }
}
.hero .copyright {
  position: absolute;
  top: 30%;
  left: vw(56);
  text-align: center;
  writing-mode: vertical-rl;
  font-feature-settings: 'pkna';
  @include mq(sp) {
    display: none;
  }
}

.hero_cta {
  position: absolute;
  left: vw(90);
  bottom: -170px;
  z-index: 200;
  width: rem(220);
  padding: rem(12) rem(12) rem(24);
  background-color: rgba(255, 255, 255, .9);
  border-radius: 110px 110px 0 0;
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  font-size: rem(14);
  line-height: 1.5;
  text-align: center;
  @include mq(sp) {
    bottom: -60%;
    left: 1%;
    transform: scale(.6,.6);
    transform-origin: left bottom;
  }

  &::before {
    content: '';
    position: absolute;
    top: -24px;
    left: 8px;
    right: 0;
    display: block;
    height: 57px;
    background-image: url(/wp/wp-content/themes/standard_sass/images/home/cta-txt.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
}
.hero--contact a {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  @include rect(195);
  transform: translateY(0px)
}
.hero--contact svg {
  width: 18px;
  height: 24px;
}
.hero--contact .font-en {
  margin-bottom: 20px;
  font-size: rem(24);
  line-height: 1;
}
.hero--tel {
  margin-bottom: 8px;
}
.hero--tel .num {
  font-size: rem(24);
  letter-spacing: -.04em;
}

.hero_slides {
  position: absolute;
  top: -117px;
  right: 0;
  width: 1400px;
  max-width: 80%;
  @include mq(sp) {
    top: -54px;
    left: 6%;
    right: auto;
    width: 150vw;
    max-width: none;
  }
}
.hero_slide .slick-list, 
.stylist_slide .slick-list {
  overflow: visible !important;
}
.hero_slide .child {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}
.hero_slide .child img {
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  opacity: 0;
}
.hero_slide .child .img_lft {
  width: 34.28%;
  margin-top: 108px;
  transform: translateY(16px);
  animation: fade_top cubic-bezier(.215, .61, .355, 1) 2.5s forwards;
  animation-delay: .5s;
  @include mq(sp) {
    width: 48%;
    margin-top: 54px;
  }
}
.hero_slide .child .img_ctr {
  width: 34.28%;
  margin-top: 204px;
  transform: translateY(-16px);
  animation: fade_btm cubic-bezier(.215, .61, .355, 1) 2.5s forwards;
  animation-delay: .9s;
  @include mq(sp) {
    width: 48%;
    margin-top: 90px;
  }
}
.hero_slide .child .img_rgt {
  width: 25.71%;
  transform: translateY(16px);
  animation: fade_top cubic-bezier(.215, .61, .355, 1) 2.5s forwards;
  animation-delay: 1.3s;
  @include mq(sp) {
    display: none;
  }
}
@keyframes fade_top {
  0%   { opacity: 0; transform: translateY(16px); }
  100% { opacity: 1; transform: translateY(0); }
}
@keyframes fade_btm {
  0%   { opacity: 0; transform: translateY(-16px); }
  100% { opacity: 1; transform: translateY(0); }
}

.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}
.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}