@use '../abstracts' as *;

/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
  @include mq(sp, min, ps) {
    @include mq(med) {
      overflow-y: auto;
      overflow-x: auto;
    }
  }
  @include mq(sp) {
    font-size: 14px;
  }
}
body {
  font-family: var(--font-primary, YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic ProN', sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 400;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, .08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #333);
  -webkit-font-smoothing: antialiased;
  padding-top: 117px;
  word-break: break-word;
  @include mq(sp, min, ps) {
    @include mq(med) {
      min-width: 1080px;
    }
  }
  @include mq(med) {
    padding-top: 64px;
  }
  @include mq(sp) {
    -webkit-text-size-adjust: none;
  }
}
ul,
ol { list-style: none; }
small { 
  font-size: .875rem; /* 14px */
  @include mq(sp) {
    font-size: .813rem; /* 13px */ 
  }
}
/*! purgecss start ignore */
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all .4s ease-out);
  outline : none;
}
a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
/*! purgecss end ignore */
a:focus {
  text-decoration: none !important;
}
img {
  vertical-align: middle;
  @include mq(sp) {
    max-width: 100%;
    height: auto;
  }
}
address,
em,
i {
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}
svg {
  transition: var(--transit-default, all .4s ease-out);
}
