@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn > *,
.btn-2 > * {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: .79em 1em;
  width: 100%;
  max-width: 240px;
  color: var(--btn-clr-main, #1d7fb7);
  text-align: center;
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp) {
    max-width: 100%;
  }
  &:not(.btn-cta) {
    border: 1px solid;
  }
  .arrow {
    position: absolute;
    top: 50%;
    right: rem(16);
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(30);
    border-radius: 50%;
    border: 1px solid;
    @include mq(sp) {
      right: 1.5rem;
      @include rect(24);
      padding: 4px;
    }

    svg {
      max-width: 100%;
      height: auto;
    }
  }
  .arrow .cls-2 {
    fill: none;
    stroke: var(--btn-clr-main, #1d7fb7);
    stroke-width: 1px;
    transition: var(--transit-default, all .4s ease-out);
  }
  &:hover,
  a:hover & {
    color: var(--btn-clr-main-hvr, #fff);
    border-color: var(--btn-bg-main-hvr, #1d7fb7);
    background-color: var(--btn-bg-main-hvr, #1d7fb7);
    svg {
      fill: var(--btn-clr-main-hvr, #fff);
    }
    .arrow .cls-2 {
      stroke: var(--btn-clr-main-hvr, #fff);
    }
  }
}
.btn-2 > * {
  width: auto;
  max-width: none;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
  &:not(.btn-cta) {
    border: none;
    border-bottom: solid 1px transparent;
  }
  .arrow {
    right: 0;
    transition: var(--transit-default, all .4s ease-out);
  }
  &:hover,
  a:hover & {
    color: var(--btn-clr-main, #1d7fb7);
    border-color: var(--btn-clr-main, #1d7fb7);
    background-color: transparent;
    .arrow {
      background-color: var(--btn-clr-main, #1d7fb7);
    }
  }
}
.flex > .btn a {
  max-width: 100%;
}
.btn.txt-ctr a {
  @include auto-margin;
}

/* --- btn-next_page --- */
.btn-next_page a {
  max-width: 100%;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #fff);
  .arrow .cls-2 {
    stroke: var(--btn-clr-wht, #fff);
  }
  &:hover {
    color: var(--btn-clr-wht-hvr, #333);
    border-color: var(--btn-bg-wht-hvr, #fff);
    background-color: var(--btn-bg-wht-hvr, #fff);
    svg {
      fill: var(--btn-clr-wht-hvr, #333);
    }
    .arrow .cls-2 {
      stroke: var(--btn-clr-wht-hvr, #333);
    }
  }
}
.btn-wh svg {
  fill: var(--btn-clr-wht, #fff);
}

/* --- btn-cta --- */
.btn-cta a {
  color: var(--btn-clr-cta, #fff);
  border-color: var(--btn-bg-cta, #1d7fb7) !important;
  background-color: var(--btn-bg-cta, #1d7fb7);
  &:hover {
    color: var(--btn-clr-cta-hvr, #fff);
    background-color: var(--btn-bg-cta-hvr, #015691);
  }
} 

@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}