@use "../abstracts" as *;

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
  @include mq(sp) {
    margin-bottom: 20px;
  }
}
.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: 20px 48px 20px 104px;
  @include mq(sp) {
    padding: 12px 24px 12px 52px;
  }
}
.lps_parts--faq .ttl-03 {
  background-color: var(--clr-main, #1d7fb7);
  margin-bottom: 0 !important;
  font-size: 1.5rem; /* 24px */
  color: #fff;
  @include mq(sp) {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
.lps_parts--faq .mce-content-body {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--clr-main, #1d7fb7);
  @include mq(sp) {
    line-height: 1.5;
  }
}
.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  left: 48px;
  display: block;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
  font-size: 2rem; /* 32px */
  @include mq(sp) {
    left: 20px;
    font-size: 1.5rem;
  }
}
.lps_parts--faq .ttl-03::after {
  content: "Q";
  top: 12px;
  color: #fff;
  @include mq(sp) {
    top: 6px;
  }
}
.lps_parts--faq .mce-content-body::after {
  content: "A";
  top: 2px;
  color: var(--clr-main, #1d7fb7);
  @include mq(sp) {
    top: 6px;
  }
}
.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}
.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
      float: none !important;
      margin-top: 2px;
      margin-left: 0;
      margin-right: auto;
      margin-bottom: 8px !important;
      border-radius: 8px;
  }
}
