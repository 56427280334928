@use '../abstracts' as *;

/* ##############################################################################

    SINGLE

############################################################################## */

  /* --- アイキャッチ --- */
  .eyecatch {
    margin-bottom: 2rem;
  }

  /* --- 目次 --- */
  .ez-toc-debug-messages {
    display: none;
  }
  div#ez-toc-container {
    padding: 24px;
    background: #fafafa;
  }
  .ez-toc-title-container {
    margin-bottom: 10px;
  }
  div#ez-toc-container p.ez-toc-title {
    color: #000;
    font-weight: bold;
    font-size: 1.25rem;
  }
  #ez-toc-container.counter-hierarchy ul {
    font-size: 1.0625rem;
  }
  #ez-toc-container.counter-hierarchy ul li {
    margin-top: 8px;
  }
  #ez-toc-container.counter-hierarchy ul ul {
    margin-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a {
    position: relative;
    display: inline-block;
    padding-left: 1rem;
  }
  #ez-toc-container.counter-hierarchy ul ul a::before {
    content: "";
    position: absolute;
    top: .65rem;
    left: 0;
    width: .6rem;
    height: 0;
    border-bottom: 1px solid;
  }

/* pass
**************************************** */
  #pass p {
    margin-bottom: 1em;
  }
  #pass input[name="post_password"] {
    padding: 5px;
    border: solid 1px #aaa;
    outline: none;
  }
  #pass input[type="submit"] {
    padding: 4px 16px;
    letter-spacing: .15em;
    background-color: #ddd;
    border-radius: 4px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: var(--transit-default, all .4s ease-out);
  }
  #pass input[type="submit"]:hover {
    opacity: .7;
  }

/* blog--share
**************************************** */
  .blog--share,
  .blog--related {
    margin-top: rem(40);
  }
  .blog--share_ttl {
    line-height: 1.2;
  }
  .blog--share_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .375rem; /* 6px */
    @include mq(sp) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .blog--share_list a {
    display: flex;
    @include center-flex;
    height: 40px;
    padding: .5em;
    line-height: 1;
    font-size: .75rem; /* 12px */
    font-weight: bold;
    border: 1px solid #ddd;
  }
  .blog--share_list li {
    &.twitter a { color: #2BA1F2; }
    &.facebook a { color: #2477F2; }
    &.line a { color: #27C754; }
    &.pocket a { color: #EF4056; }
    &.linkedin a { color: #2867B2; }
    &.hatena a { color: #29A4DE; }
  }
  .blog--share_list img {
    margin-right: 10px;
  }
  .blog--share_list a:hover {
    opacity: .5;
  }

/* single-style
**************************************** */
  .single-style .main_column .ttl-02 {
    margin-bottom: 1.5rem;
  }
  .single-style .lps_parts--slide li {
    display: flex !important;
    background-color: #f8f8f8;

    &:before {
      content: "";
      display: block;
      padding-top: 110%;
    }

    img {
      max-height: 100%;
      width: auto;
    }
  }

  .post-stylist {
    margin-top: 80px;
    margin-bottom: 80px;
    padding-top: 40px;
    border-top: solid 1px;
    @include mq(sp) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  .post-stylist .imgarea {
    width: 30%;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 30px rgb(90 116 138 / 20%);
    &::before {
      padding-top: 100%;
    }
    @include mq(sp) {
      width: 70%;
      max-width: 260px;
    }
  }
  .post-stylist .txtarea {
    @include mq(sp, min) {
      flex: 1;
      padding-left: 40px;
    }
    @include mq(sp) {
      width: 100%;
      padding-top: 24px;
    }
  }
  .post-stylist .writer {
    line-height: 1.2;
  }
  .post-stylist .instagram {
    display: inline-block;
    width: 1.5rem;
    margin-left: .5rem;
    transform: translateY(2px);
  }

/* blog--related
**************************************** */
  .posts-scroll {
    overflow: auto;
    padding-bottom: 2rem;
  }
  .posts-scroll .post {
    display: block;
    width: 46%;
    flex-shrink: 0;
    @include mq(sp) {
      width: 70%;
    }
  }
  .posts-scroll .post:not(:last-child) {
    margin-right: 1rem;
  }
  .posts-scroll:not(.posts-style) .post--img {
    margin-bottom: 1rem;
    &::before {
      padding-top: perc(3,4,'%');
    }
  }
  .posts-scroll .post--txt {
    overflow: hidden; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; 
  }
  .posts-scroll::-webkit-scrollbar {
    height: 6px;
  }
  .posts-scroll::-webkit-scrollbar-thumb {
    background-color: #999;
  }
  .posts-scroll::-webkit-scrollbar-track-piece {
    background-color: #eee;
  }

/* wp-pagenavi
**************************************** */
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: rem(40);
  }
  .wp-pagenavi .pages {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 1em;
  }
  .wp-pagenavi a {
    position: relative;
    padding: .35em 1em;
    background-color: var(--clr-main, #1d7fb7);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .wp-pagenavi a:hover {
    opacity: .7;
  }
  .wp-pagenavi a {
    color: #fff;
  }
  .wp-pagenavi > *:not(.pages) {
    max-width: calc(100% / 2.6);
  }
  .archive .wp-pagenavi > *:not(.pages) {
    display: flex;
    justify-content: center;
    align-items: center;
    @include rect(40);
    @include mq(sp) {
      @include rect(32);
    }
  }
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: 40px;
  }
  .wp-pagenavi > *:not(.pages) {
    margin-bottom: 4px;
  }
  .wp-pagenavi > *:not(.pages):not(:last-child) {
    margin-right: 4px;
  }
  .wp-pagenavi .extend {
    width: 24px !important;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top: 1px solid;
    border-right: 1px solid;
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    @include rect(8);
  }
  .wp-pagenavi a[rel="prev"]::before,
  .wp-pagenavi .first::before,
  .wp-pagenavi .first::after {
    transform: rotate(-135deg);
  }
  .wp-pagenavi a[rel="next"]::before,
  .wp-pagenavi .last::before,
  .wp-pagenavi .last::after {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="prev"]::before {
    transform: rotate(45deg);
  }
  .wp-pagenavi-single a[rel="next"]::before {
    transform: rotate(-135deg);
  }
  .wp-pagenavi .first::before,
  .wp-pagenavi .last::before {
    left: rem(-6);
  }
  .wp-pagenavi .first::after,
  .wp-pagenavi .last::after {
    right: rem(-6);
  }

@include mq(sp) {
  .wp-pagenavi-single a[rel="prev"],
  .wp-pagenavi-single a[rel="next"] {
    width: sprem(32);
  }  
}
