@use "sass:math";
@use '../abstracts' as *;

/* --- margin 　古い・削除--- */
.mgn-btm8 { margin-bottom: 8px; }
.mgn-btm16 { margin-bottom: 16px; }
.mgn-btm24 { margin-bottom: 24px; }
.mgn-btm32 { margin-bottom: 32px; }
.mgn-btm40 { margin-bottom: 40px; }
.mgn-btm48 { margin-bottom: 48px; }
.mgn-btm56 { margin-bottom: 56px; }
.mgn-btm64 { margin-bottom: 64px; }
.mgn-btm72 { margin-bottom: 72px; }
.mgn-btm80 { margin-bottom: 80px; }

@for $i from 0 through 150 {
  .mgn-#{$i} {margin: math.div($i, 16)rem;}
  .mgn-top#{$i} {margin-top: math.div($i, 16)rem;}
  .mgn-rgt#{$i} {margin-right: math.div($i, 16)rem;}
  .mgn-btm#{$i} {margin-bottom: math.div($i, 16)rem;}
  .mgn-lft#{$i} {margin-left: math.div($i, 16)rem;}
}

@include mq(sp) {
  /* --- margin --- 古い・削除*/
  .mgn-btm16 { margin-bottom: 8px; }
  .mgn-btm24 { margin-bottom: 16px; }
  .mgn-btm32 { margin-bottom: 16px; }
  .mgn-btm40 { margin-bottom: 24px; }
  .mgn-btm48 { margin-bottom: 24px; }
  .mgn-btm56 { margin-bottom: 32px; }
  .mgn-btm64 { margin-bottom: 32px; }
  .mgn-btm72 { margin-bottom: 40px; }
  .mgn-btm80 { margin-bottom: 40px; }
    
  // @for $i from 0 through 150 {
  //   .mgn-#{$i} {margin: math.div($i, 14)rem;}
  //   .mgn-top#{$i} {margin-top: math.div($i, 14)rem;}
  //   .mgn-rgt#{$i} {margin-right: math.div($i, 14)rem;}
  //   .mgn-btm#{$i} {margin-bottom: math.div($i, 14)rem;}
  //   .mgn-lft#{$i} {margin-left: math.div($i, 14)rem;}
  // }
}

@for $i from 0 through 15 {
  .mgn-#{$i}_em {margin: #{$i}em;}
  .mgn-top#{$i}_em {margin-top: #{$i}em;}
  .mgn-rgt#{$i}_em {margin-right: #{$i}em;}
  .mgn-btm#{$i}_em {margin-bottom: #{$i}em;}
  .mgn-lft#{$i}_em {margin-left: #{$i}em;}
}