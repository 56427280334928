@use '../abstracts' as *;

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  @include auto-margin;
  padding-left: 86px;
  counter-increment: number;
  @include mq(sp) {
    padding-left: 48px;
  }
}
.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 32px;
  }
}
.lps_parts--flow .ttl-03 {
  font-size: 2rem; /* 32px */
  color: var(--clr-main, #1d7fb7);
  @include mq(sp) {
    font-size: 1.429rem; /* 20px */
    line-height: 1.5;
  }
  &::after {
    content: counter(number, decimal-leading-zero);
    position: absolute;
    top: -8px;
    left: -80px;
    display: flex;
    @include center-flex;
    @include rect(64);
    line-height: 50px;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    background-color: var(--clr-main, #1D7FB7);
    font-family: var(--font-en, 'Montserrat', sans-serif);
    font-weight: 400;
    font-size: 1.625rem; /* 26px */
    letter-spacing: 0;
    @include mq(sp) {
      top: -2px;
      left: -48px;
      @include rect(36);
      font-size: 1.286rem; /* 18px */
    }
  }
}
.lps_parts--flow .flow_item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid var(--clr-main, #1d7fb7);
  @include mq(sp) {
    top: 38px;
    left: 14px;
    height: calc(100% - 48px);
  }
}
.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}
.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}
.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}
.lps_parts--flow .text img {
  float: left;
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
  @include mq(sp) {
    float: none !important;
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
  }
}
