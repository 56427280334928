@use '../abstracts/' as *;

/* home_concept
********************************************** */
.home_concept {
  padding-top: 260px;
  background-image: 
    url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-a-blue.png),
    url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-o-blue.png),
    url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-c-blue.png),
    url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-t-blue.png);
  background-position:
    top 24px right,
    top 40% left 23%,
    bottom 21% right 8%,
    bottom 72px left 4%;
  background-repeat: no-repeat;
  @include mq(sp) {
    padding-top: 130px;
  }
}
.concept .txtarea {
  flex: 1;
  padding-left: 5%;
  padding-right: 5%;
}
.concept .txt-deco {
  font-size: rem(14);
  line-height: 2.28;
  letter-spacing: .01em;
  color: #B7C8CD;
}

// concept-01
.concept-01 .imgarea {
  position: relative;
  width: 50%;
  margin-right: 1%;
  padding-top: 108px;
  @include mq(1150) {
    width: 45%;
  }
  @include mq(sp) {
    width: 100%;
    padding-top: 32px;
  }
}
.concept-01 .imgarea .img-01 {
  width: min(vw(800), 800px);
  height: 450px;
  margin-left: auto;
  margin-right: 0;
  @include mq(sp) {
    width: 90%;
    height: 240px;
  }
}
.concept-01 .imgarea .img-02 {
  position: absolute;
  bottom: -228px;
  right: min(vw(570), 570px);
  width: min(vw(388), 388px);
  height: 300px;
  @include mq(sp) {
    bottom: -80px;
    width: 50%;
    height: 150px;
    right: auto;
    left: 0;
  }
}
.concept-01 .deco-01 {
  position: absolute;
  top: 40%;
  right: 8%;
  width: min(vw(302), 302px);
  @include mq(sp) {
    top: 84%;
    right: 2%;
    width: 40%;
  }
}

// concept-02
.concept-02 {
  margin-top: -120px;
  margin-bottom: 134px;
  @include mq(sp) {
    position: relative;
    z-index: 10;
    margin-top: 110px;
    margin-bottom: 32px;
  }
}
.concept-02 .txtarea {
  padding-left: 24%;
  @include mq(2000 ,min) {
    padding-left: 30%;
  }
  @include mq(sp) {
    padding-left: 5%;
    padding-right: 20%;
  }
}
.concept-02 .imgarea {
  position: relative;
  width: 42%;
  @include mq(sp) {
    width: 100%;
  }
}
.concept-02 .imgarea .img-03 {
  width: min(vw(640), 640px);
  height: 360px;
  @include mq(sp) {
    width: 80%;
    height: 170px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: 0;
  }
}
.concept-02 .deco-02 {
  position: absolute;
  top: 51%;
  left: -17%;
  width: min(vw(378), 378px);
  mix-blend-mode: multiply;
  @include mq(sp) {
    width: 45%;
    left: 2%;
  }
}

// concept-03
.concept-03 {
  margin-bottom: 140px;
  @include mq(sp) {
    margin-bottom: 72px;
  }
}
.concept-03 .txtarea {
  position: relative;
  padding-top: 88px;
  @include mq(sp) {
    padding-top: 0;
  }
}
.concept-03 .txt-deco {
  @include mq(sp, min) {
    position: absolute;
    right: 20%;
    bottom: 40px;
  }
  @include mq(sp) {
    margin-top: 24px;
    padding-left: 20%;
  }
}
.concept-03 .imgarea {
  position: relative;
  width: 61.5%;
  margin-right: 1%;
  @include mq(sp) {
    width: 100%;
    margin-top: 24px;
  }
}
.concept-03 .imgarea .img-04 {
  width: min(vw(800), 800px);
  height: 480px;
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  @include mq(sp) {
    width: 80%;
    height: 240px;
    margin-left: 0;
    margin-right: auto;
  }
}
.concept-03 .imgarea .img-05 {
  position: absolute;
  bottom: -260px;
  right: min(vw(1040), 1040px);
  width: min(vw(137), 137px);
  height: 300px;
  @include mq(sp) {
    right: 5%;
    bottom: -60px;
    width: 20%;
    height: 150px;
  }
}

// concept-04
.concept-04 {
  @include mq(sp) {
    margin-top: -48px;
  }
}
.concept-04 .txtarea {
  padding-left: 10%;
  @include mq(sp) {
    padding-left: 5%;
    padding-right: 30%;
  }
}
.concept-04 .imgarea {
  position: relative;
  width: 44%;
  @include mq(sp) {
    width: 100%;
  }
}
.concept-04 .imgarea .img-06 {
  width: min(vw(800), 800px);
  height: 318px;
  @include mq(sp) {
    width: 80%;
    height: 100px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: 0;
  }
}
.concept-04 .deco-03 {
  position: absolute;
  top: -107%;
  left: 4.5%;
  z-index: -1;
  width: min(vw(404), 404px);
  @include mq(sp) {
    top: -152%;
    left: auto;
    right: -3%;
    width: 50%;
  }
}


/* home_about
********************************************** */
.home_about {
  height: 720px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/about-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    height: auto;
  }
}
.home_about .bg {
  display: flex;
  @include center-flex;
  width: 870px;
  max-width: calc(100% - 180px);
  height: 100%;
  padding-left: 108px;
  padding-right: 108px;
  margin-left: 90px;
  background-color: #fff;
  @include mq(sp) {
    max-width: 90%;
    padding: 12% 8%;
    margin-left: 5%;
  }
}
.home_about .bg div {
  width: 100%;
}
.home_about p {
  max-width: 550px;
  margin-bottom: rem(40);
  letter-spacing: -.05em;
}

/* home_menu
********************************************** */
.home_menu {
  position: relative;
  padding-top: 124px;
  padding-bottom: 110px;
  @include mq(sp) {
    padding-top: 62px;
    padding-bottom: 8px;
  }
}
.home_menu .bg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/menu-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.home_menu .inner {
  max-width: 1232px;
}
.home_menu .ttl-01 {
  position: absolute;
  bottom: -.6em;
  left: .3em;
  z-index: 20;
  line-height: 1;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-feature-settings: 'pkna';
  mix-blend-mode: normal;
  @include mq(sp) {
    bottom: .4em;
    left: -.4em;
  }
}

.home_menu .txtarea {
  position: relative;
  z-index: 10;
  width: 56.1%;
  padding: 7% 8% 6%;
  background-color: rgba(255,255,255,.9);
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  letter-spacing: -.05em;
  @include mq(sp) {
    width: 90%;
    padding: 8% 8% 10%;
    transform: translateY(-40px);
  }
}

.menu_slide {
  width: 43.8%;
  transform: translateX(16%);
  background-color: #fff;
  @include mq(sp) {
    width: 100%;
    transform: translateX(0);
  }
}
.menu_slide .slick-list,
.stylist_slide .slick-list,
.style_slide .slick-list {
  overflow: visible !important;
}
.menu_slide .post--img {
  height: 540px;
  @include mq(sp) {
    height: 280px;
  }
}
.menu_slide .num {
  position: absolute;
  top: rem(-64);
  left: rem(-64);
  z-index: 10;
  @include rect(96);
  display: flex;
  @include center-flex;
  border-radius: 50%;
  font-size: rem(48);
  line-height: 1;
  @include mq(sp) {
    top: rem(-32);
    left: rem(-32);
  }
}
.menu_slide .num .circle {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.menu_slide .num .circle:nth-child(1) { right: 0; }
.menu_slide .num .circle:nth-child(2) { left: 0; }
.menu_slide .num .circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid;
  transition: all .4s ease-out;
}
.menu_slide .num .circle:nth-child(1)::before {
  top: 0;
  right: 0;
  border-left: 0;
  border-radius: 0 100% 100% 0% / 0 50% 50% 0;
  transform-origin: left;
}
.menu_slide .num .circle:nth-child(2)::before {
  bottom: 0;
  left: 0;
  border-right: 0;
  border-radius: 100% 0 0 100% / 50% 0 0 50%;
  transform-origin: right;
}
.menu_slide .slick-active .num .circle {
  opacity: 1;
}
.menu_slide .slick-active .num .circle:nth-child(1)::before {
  animation: circle_progress 4s linear forwards;
}
.menu_slide .slick-active .num .circle:nth-child(2)::before {
  animation: circle_progress 4s linear 4s forwards;
}
@keyframes circle_progress {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
}

.menu_slide .slick-arrow,
.stylist_slide .slick-arrow,
.style_slide .slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 100;
  display: flex !important;
  justify-content: center;
  align-items: center;
  @include rect(48);
  padding: 12px;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp) {
    display: none !important;
  }

  &.prev-arrow {
    right: -100px;
    transform: scale(-1, 1);
  }
  &.next-arrow {
    right: -180px;
  }

  svg {
    width: 100%;
    height: auto;
  }
  .cls-2 {
    fill: none;
    stroke: var(--btn-clr-main, #1d7fb7);
    transition: var(--transit-default, all .4s ease-out);
  }

  &:hover {
    color: var(--btn-clr-main-hvr, #fff);
    border-color: var(--btn-bg-main-hvr, #1d7fb7);
    background-color: var(--btn-bg-main-hvr, #1d7fb7);
    svg {
      fill: var(--btn-clr-main-hvr, #fff);
    }
    .cls-2 {
      stroke: var(--btn-clr-main-hvr, #fff);
    }
  }
}

.menu_slide .slick-dots {
  position: absolute;
  top: 64px;
  left: -20px;
  display: flex;
  flex-direction: column;
}
.menu_slide .slick-dots li {
  display: flex;
}
.menu_slide .slick-dots button {
  @include rect(8);
  padding: 0;
  margin-bottom: 4px;
  font-size: 0;
  border: solid 1px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
}
.menu_slide .slick-dots .slick-active button {
  background-color:  var(--clr-body);
}

/* home_stylist
********************************************** */
.home_stylist {
  padding-top: 104px;
  padding-bottom: 128px;
  background-image: 
    url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-a-blue.png),
    url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-o-blue.png);
  background-position:
    top 104px right,
    top 42% left 12%;
  background-repeat: no-repeat;
  @include mq(sp) {
    padding-top: 52px;
    padding-bottom: 64px;
    background-position:
      top 54px right -15%,
      bottom 10% left -200px;
  }
}
.home_style {
  padding-top: 104px;
  padding-bottom: 128px;
  background-color: #F3F1EE;
  @include mq(sp) {
    padding-top: 52px;
    padding-bottom: 64px;
  }
}
.home_stylist .ttl-01,
.home_style .ttl-01 {
  margin-bottom: rem(16);
}
.stylist_slide .slick-list li,
.style_slide .slick-list li {
  width: 20%;
  padding: 0 vw(28);
  @include mq(sp) {
    width: 33%;
    padding: 0 8px;
  }
}
.stylist_slide .link,
.style_slide .link {
  display: block;
}
.stylist_slide .post--img,
.style_slide .post--img {
  position: relative;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(90, 116, 138, .2);
  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}
.stylist_slide .post--img {
  margin-bottom: rem(32);
  @include mq(sp) {
    margin-bottom: rem(24);
  }
}
.stylist_slide .more,
.style_slide .more {
  position: absolute;
  inset: 0;
  display: flex;
  @include center-flex;
  color: #fff;
  background-color: rgba(44, 44, 44, 0.3);
  opacity: 0;
  font-size: rem(18);
  transition: var(--transit-default, all 0.4s ease-out);
}
.stylist_slide .link:hover .more,
.style_slide .link:hover .more {
  opacity: 1;
}
.stylist_slide .txt {
  letter-spacing: -.025em;
}
.stylist_slide .name {
  font-size: rem(24);
  letter-spacing: -.025em;
}

.stylist_slide .slick-arrow,
.style_slide .slick-arrow {
  top: -208px;
  bottom: auto;

  &.prev-arrow {
    right: 380px;
  }
  &.next-arrow {
    right: 300px;
  }
}
.stylist_slide .slick-dots,
.style_slide .slick-dots {
  display: flex;
  width: 80%;
  max-width: 800px;
  height: 2px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
  background-color: #C9D6DA;
  background: linear-gradient(to bottom, rgb(255,255,255) 49%,rgb(201,214,218) 50%,rgb(201,214,218) 50%);
  overflow: hidden;
  @include mq(sp) {
    margin-top: 36px;
  }
}
.stylist_slide .slick-dots li,
.style_slide .slick-dots li {
  flex: 1;
}
.stylist_slide .slick-dots button,
.style_slide .slick-dots button {
  display: block;
  width: 100%;
  height: 2px;
  padding: 0;
  margin: 0;
  font-size: 0;
  border: none;
  border-radius: 8px;
  background-color: #C9D6DA;
  background: linear-gradient(to bottom, rgb(255,255,255) 49%,rgb(201,214,218) 50%,rgb(201,214,218) 50%);
  cursor: pointer;
}
.stylist_slide .slick-dots .slick-active button,
.style_slide .slick-dots .slick-active button {
  background: #6F909A;
  border-radius: 8px;
}
.style_slide .slick-dots,
.style_slide .slick-dots button {
  background: linear-gradient(to bottom, rgb(243,241,238) 49%,rgb(35,35,35) 50%,rgb(35,35,35) 50%);
}
.style_slide .slick-dots .slick-active button {
  background: #232323
}

.home_stylist .btn-2 a,
.home_style .btn-2 a {
  padding-left: 0;
  padding-right: 2.5rem;
  font-size: rem(24);
  
  .arrow {
    @include rect(18);
    padding: 4px;
  }
}


/* home_grayhair
********************************************** */
.home_grayhair {
  padding-bottom: 96px;
  background-image: 
    url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-c-blue.png),
    url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-t-blue.png);
  background-position:
    top 20% right 16%,
    bottom 32px left 2%;
  background-repeat: no-repeat;
  @include mq(sp) {
    padding-bottom: 48px;
    background-position:
      top 0 right -200px,
      bottom 0 left -100px;
  }
}
.home_grayhair .ttl-01,
.home_news .ttl-01,
.home_blog .ttl-01 {
  margin-bottom: rem(32);
}
.home_grayhair .bnrarea {
  display: inline-block;
}
.home_grayhair .deco {
  position: absolute;
  top: -20%;
  left: -31%;
  z-index: -1;
  width: min(vw(378), 378px);
  @include mq(sp) {
    top: -85%;
    left: -29%;
    width: 50%;
  }
}
.home_grayhair .bnrarea .img {
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
}


/* home_post
********************************************** */
.home_post {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/post-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  @include mq(sp) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.home_post .inner {
  max-width: 1024px;
}

/* --- home_news --- */
.home_news .news_list {
  background-color: #fff;
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  @include mq(sp) {
    padding-left: 1.5rem;
    padding-right: 1.25rem;
  }
}

/* --- home_blog --- */
.home_blog .posts-blog .post {
  box-shadow: 0 0 30px rgba(90, 116, 138, .3);
  @include mq(sp) {
    &:last-child {
      display: none;
    }
  }
}


/* home_recruit
********************************************** */
.home_recruit {
  height: 590px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/recruit-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include mq(sp) {
    height: auto;
    background-position: left 37% center;
  }
}
.home_recruit .inner {
  max-width: 1500px;
  height: 100%;
}
.home_recruit .txtarea {
  @include mq(sp) {
    width: 60%;
    margin-left: auto;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.home_recruit .ttl-01 {
  @include mq(380) {
    font-size: 2.75rem;
  }
}

/* home_info
********************************************** */
.home_info {
  padding-top: 80px;
  padding-bottom: 64px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/concept-deco03.svg);
  background-repeat: no-repeat;
  background-size: 475px auto;
  background-position: left 47% bottom 8%;
  @include mq(sp) {
    padding-top: 56px;
    padding-bottom: 80px;
    background-size: 50% auto;
    background-position: right 10% bottom 30%;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    width: vw(400);
    background-color: #F4F4F4;
  }
}
.home_info .txtarea {
  flex: 1;
  padding-left: vw(202);
  line-height: 1.8;
  letter-spacing: -.05em;
}
.home_info .imgarea {
  width: vw(830);
  margin-top: -24px;
  padding-right: vw(56);
  @include mq(sp) {
    width: 100%;
    margin-top: 16px;
  }
}
.home_info .imgarea .img-01,
.home_info .imgarea .img-02 {
  max-width: 90%;
  box-shadow: 0 0 30px rgba(90, 116, 138, .2);
  @include mq(sp) {
    max-width: 60%;
  }
}
.home_info .imgarea .img-02 {
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 0;
  @include mq(sp) {
    margin-top: -40px;
  }
}