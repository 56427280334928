@charset "utf-8";
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */

  html, body, div, span, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  abbr, address, cite, code,
  del, dfn, em, img, ins, kbd, q, samp,
  small, strong, sub, sup, var,
  b, i,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }
  
  *,
  *:after,
  *::before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  body {
    line-height: 1;
    background: #fff;
    color: #333;
  }

  article, aside, canvas, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section, summary, main {
    display: block;
  }

  nav ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  a {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

  ins {
    background-color: #ff9;
    color: #333;
    text-decoration: none;
  }

  mark {
    background-color: #ff9;
    color: #333; 
    font-style: italic;
    font-weight: bold;
  }

  del {
    text-decoration: line-through;
  }

  abbr[title], dfn[title] {
    border-bottom: 1px dotted #333;
    cursor: help;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 1em 0;
    padding: 0;
  }

  input, select {
    vertical-align: middle;
  }


  /*-- ClearFix --*/
  /* For modern browsers */
  .clearfix:before,
  .clearfix:after {
    content:"";
    display:table;
  }
  .clearfix:after {
    clear:both;
  } 
  /* For IE 6/7 (trigger hasLayout) */
  .clearfix {
    zoom:1;
  }
  /*隙間消し*/
  li img {
      vertical-align: bottom;
  }

  /* Reset input[type="search"] */
  input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
  }
  input[type="search"]:focus {
    outline-offset: -2px;
  }
  input[type="search"]::-webkit-search-decoration {
    display: none;
  }

  /* iOS reset */
  input[type="submit"] {
    -webkit-appearance: none;
  }


  @media print, screen and (min-width: 768px) {
    a { 
      -webkit-tap-highlight-color:rgba(0, 0, 0, 0); 
    }

    @-moz-document url-prefix() {
      * { font-feature-settings: "palt"; }
    }
    @media screen and (-webkit-min-device-pixel-ratio:0) {
      * { font-feature-settings: "palt"; }
    }
  }