@use '../abstracts' as *;

/* flex
**************************************** */

/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}
.flex-j-end {
  justify-content: flex-end;
}
.flex-j-ctr {
  justify-content: center;
}
.flex-j-between {
  justify-content: space-between;
}
.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}
.flex-a-end {
  align-items: flex-end;
}
.flex-a-ctr {
  align-items: center;
}
.flex-a-baseline {
  align-items: baseline;
}
.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}
.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-start {
  align-content: flex-start;
}
.flex-c-end {
  align-content: flex-end;
}
.flex-c-ctr {
  align-content: center;
}
.flex-c-baseline {
  align-content: baseline;
}
.flex-c-stretch {
  align-content: stretch;
}

@include mq(sp) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}

.flex-col2,
.flex-col3,
.flex-col4 {
  flex-wrap: wrap;
}
.flex-col1 > * {
  width: 100%;
}
.flex-col2 > * {
  width: 50%;
}
.flex-col3 > * {
  width: calc(100% / 3);
}
.flex-col4 > * {
  width: 25%;
}
.flex-col2.gap { gap: 4%; }
.flex-col3.gap { gap: 3%; }
.flex-col4.gap { gap: 2%; }
.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}
.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}
.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

@include mq(sp, min) {
  .flex-col2.gap-wide { gap: 6%; }
  .flex-col3.gap-wide { gap: 5%; }
  .flex-col4.gap-wide { gap: 4%; }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col2.column-gap > *,
  .flex-col3.column-gap > *,
  .flex-col4.column-gap > * {
    margin-bottom: 0;
  }
}

@include mq(sp) {
  .flex-col2.flex-sp-block > *,
  .flex-col3.flex-sp-block > *,
  .flex-col4.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
  .flex-col3.gap.flex-sp-block > *,
  .flex-col4.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
  .flex-col3.gap.flex-sp-block > *:last-child,
  .flex-col4.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-col4.gap.flex-sp-col3 > * { width: 32%; }
  .flex-col4.gap.flex-sp-col2 > * { width: 49%; }
  .flex-col3.gap.flex-sp-col2 > * { width: 48.5%; }
}