@charset "UTF-8";
:root {
  --ltr-space-default: .01em;
  --line-height-default: 2;
  --line-height-hdr: 1.4;
  --line-height-none: 1;
  --font-primary: "Sen", "こぶりなゴシック W3 JIS2004", "Koburina Gothic W3 JIS2004";
  --font-secondary: "ヒラギノ角ゴ オールド W6 JIS2004", "Hiragino Sans Old W6 JIS2004";
  --font-secondary--light: "ヒラギノ角ゴ オールド W3 JIS2004", "Hiragino Sans Old W3 JIS2004";
  --font-jp: "Sen", "こぶりなゴシック W6 JIS2004", "Koburina Gothic W6 JIS2004";
  --font-en: "Sen", sans-serif;
  --font-noto-sans: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro","メイリオ", "Meiryo", "游ゴシック", "Yu Gothic", "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  --fz-primary: 1.125rem;
  --fz-half: .5rem;
  --clr-main: #2C2C2C;
  --clr-txt-sub: #171C60;
  --clr-body: #171923;
  --clr-dark-blue: #101560;
  --clr-link: #333;
  --clr-wht: #fff;
  --clr-blk: #000;
  --bg-main: #1D7FB7;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: rgba(195,183,168,.2);
  --bg-blk: #000;
  --btn-clr-main: var(--clr-main);
  --btn-bg-main: var(--clr-wht);
  --btn-clr-main-hvr: var(--clr-wht);
  --btn-bg-main-hvr: var(--clr-main);
  --btn-clr-wht: var(--clr-wht);
  --btn-bg-wht: var(--clr-main);
  --btn-clr-wht-hvr: var(--clr-main);
  --btn-bg-wht-hvr: var(--clr-wht);
  --btn-clr-cta: var(--clr-wht);
  --btn-bg-cta: var(--clr-main);
  --btn-clr-cta-hvr: var(--clr-wht);
  --btn-bg-cta-hvr: #565656;
  --brd-main: #1D7FB7;
  --transit-default: all 0.4s ease-out;
}

/* ##############################################################################

    KEYFRAMES

############################################################################## */
/* ##############################################################################

    style info : リセット及びフォントサイズ

############################################################################## */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

*,
*:after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  line-height: 1;
  background: #fff;
  color: #333;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary, main {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

ins {
  background-color: #ff9;
  color: #333;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #333;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted #333;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

/*-- ClearFix --*/
/* For modern browsers */
.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */
.clearfix {
  zoom: 1;
}

/*隙間消し*/
li img {
  vertical-align: bottom;
}

/* Reset input[type="search"] */
input[type=search] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
}

input[type=search]:focus {
  outline-offset: -2px;
}

input[type=search]::-webkit-search-decoration {
  display: none;
}

/* iOS reset */
input[type=submit] {
  -webkit-appearance: none;
}

@media print, screen and (min-width: 768px) {
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  @-moz-document url-prefix() {
    * {
      font-feature-settings: "palt";
    }
  }
}
@media screen and (min-width: 768px) and (-webkit-min-device-pixel-ratio: 0) {
  * {
    font-feature-settings: "palt";
  }
}
/* ##############################################################################

    COMMON

############################################################################## */
html {
  font-size: 16px;
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  html {
    overflow-y: auto;
    overflow-x: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  html {
    font-size: 14px;
  }
}

body {
  font-family: var(--font-primary, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  line-height: var(--line-height-default, 1.8);
  font-weight: 400;
  font-feature-settings: "palt";
  letter-spacing: var(--ltr-space-default, 0.08em);
  *font-size: small;
  *font: x-small;
  color: var(--clr-body, #333);
  -webkit-font-smoothing: antialiased;
  padding-top: 117px;
  word-break: break-word;
}
@media screen and (min-width: 48em) and (max-width: 67.5em) {
  body {
    min-width: 1080px;
  }
}
@media screen and (max-width: 67.5em) {
  body {
    padding-top: 64px;
  }
}
@media screen and (max-width: 47.9375em) {
  body {
    -webkit-text-size-adjust: none;
  }
}

ul,
ol {
  list-style: none;
}

small {
  font-size: 0.875rem; /* 14px */
}
@media screen and (max-width: 47.9375em) {
  small {
    font-size: 0.813rem; /* 13px */
  }
}

/*! purgecss start ignore */
a,
a[href="javascript:void(0);"]:hover {
  color: var(--clr-link, #333);
  text-decoration: none;
  transition: var(--transit-default, all 0.4s ease-out);
  outline: none;
}

a[href="javascript:void(0);"],
a[href="javascript:void(0);"]::before {
  color: inherit;
  cursor: default;
  opacity: 1;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/*! purgecss end ignore */
a:focus {
  text-decoration: none !important;
}

img {
  vertical-align: middle;
}
@media screen and (max-width: 47.9375em) {
  img {
    max-width: 100%;
    height: auto;
  }
}

address,
em,
i {
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  line-height: var(--line-height-hdr, 1.4);
}

svg {
  transition: var(--transit-default, all 0.4s ease-out);
}

/* Wysiwyg editor
 **************************************** */
.mce-content-body ol {
  list-style: decimal;
}

.mce-content-body ul:not(:first-child),
.mce-content-body ol:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body ul:not(:last-child),
.mce-content-body ol:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body ul li,
.mce-content-body ol li {
  position: relative;
  margin: 0;
  border-bottom: none;
  padding: 0;
  margin-left: 2em;
  padding-top: 0.5em;
}

.mce-content-body ul li {
  list-style: none;
}

.mce-content-body ul li::before {
  content: "";
  position: absolute;
  top: 1em;
  left: -1.3em;
  width: 0.625rem;
  height: 0.625rem;
  border: 1px solid var(--clr-main, #1D7FB7);
  background-color: var(--clr-main, #1D7FB7);
}

.mce-content-body ul[style="list-style-type: circle;"] li::before {
  background-color: var(--clr-wht, #fff);
}

.mce-content-body ul:not([style="list-style-type: square;"]) li::before {
  border-radius: 50%;
}

.mce-content-body img {
  margin: 1em 0;
  max-width: 100%;
  height: auto;
}

.mce-content-body hr {
  border-color: #D3D3D3;
}

.mce-content-body .box {
  padding: 24px 32px;
  background-color: rgba(195, 183, 168, 0.2);
  border: 1px solid var(--clr-main, #1D7FB7);
}

.mce-content-body .box:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body .box:not(:last-child) {
  margin-bottom: 2em;
}

.mce-content-body .box-wh {
  background-color: var(--bg-wht, #fff);
  box-shadow: 0 0 1rem rgba(44, 44, 44, 0.1);
  border: 0;
}

.mce-content-body .alignleft {
  float: left;
  clear: left;
  margin-right: 1em;
  margin-bottom: 1em;
}

.mce-content-body .alignright {
  float: right;
  clear: right;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mce-content-body .aligncenter {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}

.mce-content-body img.aligncenter {
  display: block;
}

.mce-content-body .alignleft:last-child,
.mce-content-body .alignright:last-child,
.mce-content-body .aligncenter:last-child {
  margin-bottom: 0;
}

.mce-content-body p {
  letter-spacing: 0.1em;
}

.mce-content-body p:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body strong {
  font-weight: bold;
}

.mce-content-body blockquote {
  margin: 1em 0;
  padding: 15px 40px;
  border-radius: 3px;
  position: relative;
  display: inline-block;
  font-style: oblique;
  color: #666;
  background-color: #f8f8f8;
}

.mce-content-body blockquote p {
  margin-bottom: 0;
}

.mce-content-body blockquote::before,
.mce-content-body blockquote::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  opacity: 0.25;
}

.mce-content-body blockquote::before {
  background-image: url("../images/common/icon-quote_left.svg");
  top: 13px;
  left: 10px;
}

.mce-content-body blockquote::after {
  background-image: url("../images/common/icon-quote_right.svg");
  bottom: 13px;
  right: 10px;
}

.mce-content-body a,
.txt-link {
  text-decoration: underline;
  color: #77a5b3;
}

.mce-content-body a:hover {
  text-decoration: none;
}

.mce-content-body .footer_text small {
  display: block;
  margin-top: 8px;
  text-align: right;
}

.mce-content-body table {
  border: none;
  height: auto !important;
}

.mce-content-body table:not(:first-child) {
  margin-top: 1em;
}

.mce-content-body table:not(:last-child) {
  margin-bottom: 1em;
}

.mce-content-body th,
.mce-content-body td,
.mce-content-body tr {
  height: auto !important;
}

.mce-content-body th,
.mce-content-body td {
  padding: 1em;
  border: 1px solid var(--clr-main, #1d7fb7);
  vertical-align: middle;
}

.mce-content-body th {
  min-width: 120px;
  text-align: left;
}

.mce-content-body sup {
  font-size: 0.75rem; /* 12px */
  color: #555;
}

.bold {
  font-weight: bold;
  color: var(--clr-main, #1d7fb7);
}

/* iframe */
.mce-content-body iframe {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  vertical-align: bottom;
}

.mce-content-body iframe:not(:first-child) {
  margin-top: 2em;
}

.mce-content-body iframe:not(:last-child) {
  margin-bottom: 2em;
}

@media screen and (max-width: 47.9375em) {
  /* table scroll */
  .table-sp-scroll {
    white-space: nowrap;
  }
  .scroll {
    overflow-x: scroll;
    margin-bottom: 1em;
    -webkit-overflow-scrolling: touch;
  }
  .scroll table {
    margin: 0;
  }
  .scroll--cap {
    text-align: right;
    margin: 1em 0 0 !important;
    opacity: 0.25;
    font-size: 0.875rem; /* 14px */
  }
}
@media screen and (max-width: 47.9375em) {
  .mce-content-body p:not([class]) {
    text-align: left !important;
  }
  .mce-content-body .alignright,
.mce-content-body .alignleft {
    width: 100% !important;
    float: none;
    margin: 1em auto;
    display: block;
  }
  .mce-content-body blockquote {
    padding: 15px;
  }
  .mce-content-body blockquote::before {
    top: 0;
    left: 0;
  }
  .mce-content-body blockquote::after {
    bottom: 0;
    right: 0;
  }
  .mce-content-body ul li::before {
    width: 0.5rem;
    height: 0.5rem;
  }
  .mce-content-body table {
    max-width: none !important;
  }
  .mce-content-body th {
    font-weight: bold;
  }
  .mce-content-body th,
.mce-content-body td {
    padding: 0.5em 1em;
  }
  .table-sp-full {
    width: 100% !important;
  }
  .table-sp-full th,
.table-sp-full td {
    flex: 1;
  }
  .table-sp-block,
.table-sp-block th,
.table-sp-block td {
    width: 100% !important;
  }
  .table-sp-block th,
.table-sp-block td {
    display: block;
  }
  .mce-content-body .box {
    padding: 24px;
  }
}
/* 管理画面エディタ用
*************************************************** */
.mce-content-body.wp-editor {
  padding: 24px !important;
}

@media screen and (max-width: 47.9375em) {
  .mce-content-body.wp-editor table tr *:first-child:nth-last-child(2),
.mce-content-body.wp-editor table tr *:first-child:nth-last-child(2) ~ * {
    display: table-cell;
    width: auto !important;
  }
}
/* add quicktag
*************************************************** */
/* 見出し */
.editor-ttl:not(:first-child),
.editor-ttl2:not(:first-child),
.editor-ttl3:not(:first-child) {
  margin-top: 1em;
}

.editor-ttl:not(:last-child),
.editor-ttl2:not(:last-child),
.editor-ttl3:not(:last-child) {
  margin-bottom: 0.5em;
}

.editor-ttl {
  font-size: 2rem; /* 32px */
  padding-bottom: 0.25em;
  border-bottom: 1px solid var(--clr-main, #1D7FB7);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl {
    font-size: 1.4rem;
  }
}

.editor-ttl2 {
  font-size: 1.5rem; /* 24px */
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .editor-ttl2 {
    font-size: 1.25rem;
  }
}

.editor-ttl3 {
  position: relative;
  font-size: 1.125rem; /* 18px */
  padding-left: 1em;
}

.editor-ttl3::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.313em; /* 5px */
  height: 100%;
  background-color: var(--clr-main, #1d7fb7);
  border-radius: 0.15em;
}

/* 注意書き */
.txt-attention {
  display: block;
  text-indent: -1em;
  padding-left: 1em;
  font-size: 0.875rem; /* 14px */
  color: #77a5b3;
}

.txt-attention::before {
  content: "※";
}

/* テーブル */
.mce-content-body table:not(.table1) th,
.table2 th {
  background-color: rgba(195, 183, 168, 0.2);
}

.table1 th,
.table1 td {
  position: relative;
  border: 0;
  padding: 1em 1.5em;
}
@media screen and (max-width: 47.9375em) {
  .table1 th,
.table1 td {
    padding: 0.5em 0.5em;
  }
}

.table1 th {
  color: var(--clr-main, #1d7fb7);
  font-size: 1.125rem; /* 18px */
}
@media screen and (max-width: 47.9375em) {
  .table1 th {
    font-size: 1rem;
  }
}

.table1 th::before,
.table1 td::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: calc(100% - 3px);
}

.table1 th::before {
  left: 0;
  height: 2px;
  background-color: var(--clr-main, #1d7fb7);
}

.table1 td::before {
  right: 0;
  height: 1px;
  background-color: #D3D3D3;
}

/* IE */
_:-ms-input-placeholder, :root .table1 th {
  border-bottom: 2px solid var(--clr-main, #1d7fb7);
}

_:-ms-input-placeholder, :root .table1 td {
  border-bottom: 1px solid #D3D3D3;
}

_:-ms-input-placeholder, :root .table1 th::before,
_:-ms-input-placeholder, :root .table1 td::before {
  display: none;
}

@media screen and (max-width: 47.9375em) {
  /* テーブル */
  .table2.table-sp-block th,
.table2.table-sp-block td {
    border-bottom: 0;
  }
  .table2.table-sp-block tr:last-child > *:last-child {
    border-bottom: 1px solid var(--clr-main, #1d7fb7);
  }
  .table1.table-sp-block td::before {
    display: none;
  }
  /* 注意書き */
  .txt-attention {
    font-size: 0.929rem; /* 13px */
  }
}
/* --- font-size --- */
.fz-8 {
  font-size: 0.5rem;
}

.fz-9 {
  font-size: 0.5625rem;
}

.fz-10 {
  font-size: 0.625rem;
}

.fz-11 {
  font-size: 0.6875rem;
}

.fz-12 {
  font-size: 0.75rem;
}

.fz-13 {
  font-size: 0.8125rem;
}

.fz-14 {
  font-size: 0.875rem;
}

.fz-15 {
  font-size: 0.9375rem;
}

.fz-16 {
  font-size: 1rem;
}

.fz-17 {
  font-size: 1.0625rem;
}

.fz-18 {
  font-size: 1.125rem;
}

.fz-19 {
  font-size: 1.1875rem;
}

.fz-20 {
  font-size: 1.25rem;
}

.fz-21 {
  font-size: 1.3125rem;
}

.fz-22 {
  font-size: 1.375rem;
}

.fz-23 {
  font-size: 1.4375rem;
}

.fz-24 {
  font-size: 1.5rem;
}

.fz-25 {
  font-size: 1.5625rem;
}

.fz-26 {
  font-size: 1.625rem;
}

.fz-27 {
  font-size: 1.6875rem;
}

.fz-28 {
  font-size: 1.75rem;
}

.fz-29 {
  font-size: 1.8125rem;
}

.fz-30 {
  font-size: 1.875rem;
}

.fz-31 {
  font-size: 1.9375rem;
}

.fz-32 {
  font-size: 2rem;
}

.fz-33 {
  font-size: 2.0625rem;
}

.fz-34 {
  font-size: 2.125rem;
}

.fz-35 {
  font-size: 2.1875rem;
}

.fz-36 {
  font-size: 2.25rem;
}

.fz-37 {
  font-size: 2.3125rem;
}

.fz-38 {
  font-size: 2.375rem;
}

.fz-39 {
  font-size: 2.4375rem;
}

.fz-40 {
  font-size: 2.5rem;
}

.fz-41 {
  font-size: 2.5625rem;
}

.fz-42 {
  font-size: 2.625rem;
}

.fz-43 {
  font-size: 2.6875rem;
}

.fz-44 {
  font-size: 2.75rem;
}

.fz-45 {
  font-size: 2.8125rem;
}

.fz-46 {
  font-size: 2.875rem;
}

.fz-47 {
  font-size: 2.9375rem;
}

.fz-48 {
  font-size: 3rem;
}

.fz-49 {
  font-size: 3.0625rem;
}

.fz-50 {
  font-size: 3.125rem;
}

.fz-51 {
  font-size: 3.1875rem;
}

.fz-52 {
  font-size: 3.25rem;
}

.fz-53 {
  font-size: 3.3125rem;
}

.fz-54 {
  font-size: 3.375rem;
}

.fz-55 {
  font-size: 3.4375rem;
}

.fz-56 {
  font-size: 3.5rem;
}

.fz-57 {
  font-size: 3.5625rem;
}

.fz-58 {
  font-size: 3.625rem;
}

.fz-59 {
  font-size: 3.6875rem;
}

.fz-60 {
  font-size: 3.75rem;
}

.fz-61 {
  font-size: 3.8125rem;
}

.fz-62 {
  font-size: 3.875rem;
}

.fz-63 {
  font-size: 3.9375rem;
}

.fz-64 {
  font-size: 4rem;
}

.fz-65 {
  font-size: 4.0625rem;
}

.fz-66 {
  font-size: 4.125rem;
}

.fz-67 {
  font-size: 4.1875rem;
}

.fz-68 {
  font-size: 4.25rem;
}

.fz-69 {
  font-size: 4.3125rem;
}

.fz-70 {
  font-size: 4.375rem;
}

.fz-71 {
  font-size: 4.4375rem;
}

.fz-72 {
  font-size: 4.5rem;
}

.fz-73 {
  font-size: 4.5625rem;
}

.fz-74 {
  font-size: 4.625rem;
}

.fz-75 {
  font-size: 4.6875rem;
}

.fz-76 {
  font-size: 4.75rem;
}

.fz-77 {
  font-size: 4.8125rem;
}

.fz-78 {
  font-size: 4.875rem;
}

.fz-79 {
  font-size: 4.9375rem;
}

.fz-80 {
  font-size: 5rem;
}

.fz-81 {
  font-size: 5.0625rem;
}

.fz-82 {
  font-size: 5.125rem;
}

.fz-83 {
  font-size: 5.1875rem;
}

.fz-84 {
  font-size: 5.25rem;
}

.fz-85 {
  font-size: 5.3125rem;
}

.fz-86 {
  font-size: 5.375rem;
}

.fz-87 {
  font-size: 5.4375rem;
}

.fz-88 {
  font-size: 5.5rem;
}

.fz-89 {
  font-size: 5.5625rem;
}

.fz-90 {
  font-size: 5.625rem;
}

.fz-91 {
  font-size: 5.6875rem;
}

.fz-92 {
  font-size: 5.75rem;
}

.fz-93 {
  font-size: 5.8125rem;
}

.fz-94 {
  font-size: 5.875rem;
}

.fz-95 {
  font-size: 5.9375rem;
}

.fz-96 {
  font-size: 6rem;
}

.fz-97 {
  font-size: 6.0625rem;
}

.fz-98 {
  font-size: 6.125rem;
}

.fz-99 {
  font-size: 6.1875rem;
}

.fz-100 {
  font-size: 6.25rem;
}

.fz-101 {
  font-size: 6.3125rem;
}

.fz-102 {
  font-size: 6.375rem;
}

.fz-103 {
  font-size: 6.4375rem;
}

.fz-104 {
  font-size: 6.5rem;
}

.fz-105 {
  font-size: 6.5625rem;
}

.fz-106 {
  font-size: 6.625rem;
}

.fz-107 {
  font-size: 6.6875rem;
}

.fz-108 {
  font-size: 6.75rem;
}

.fz-109 {
  font-size: 6.8125rem;
}

.fz-110 {
  font-size: 6.875rem;
}

.fz-111 {
  font-size: 6.9375rem;
}

.fz-112 {
  font-size: 7rem;
}

.fz-113 {
  font-size: 7.0625rem;
}

.fz-114 {
  font-size: 7.125rem;
}

.fz-115 {
  font-size: 7.1875rem;
}

.fz-116 {
  font-size: 7.25rem;
}

.fz-117 {
  font-size: 7.3125rem;
}

.fz-118 {
  font-size: 7.375rem;
}

.fz-119 {
  font-size: 7.4375rem;
}

.fz-120 {
  font-size: 7.5rem;
}

.fz-121 {
  font-size: 7.5625rem;
}

.fz-122 {
  font-size: 7.625rem;
}

.fz-123 {
  font-size: 7.6875rem;
}

.fz-124 {
  font-size: 7.75rem;
}

.fz-125 {
  font-size: 7.8125rem;
}

.fz-126 {
  font-size: 7.875rem;
}

.fz-127 {
  font-size: 7.9375rem;
}

.fz-128 {
  font-size: 8rem;
}

.fz-129 {
  font-size: 8.0625rem;
}

.fz-130 {
  font-size: 8.125rem;
}

.fz-131 {
  font-size: 8.1875rem;
}

.fz-132 {
  font-size: 8.25rem;
}

.fz-133 {
  font-size: 8.3125rem;
}

.fz-134 {
  font-size: 8.375rem;
}

.fz-135 {
  font-size: 8.4375rem;
}

.fz-136 {
  font-size: 8.5rem;
}

.fz-137 {
  font-size: 8.5625rem;
}

.fz-138 {
  font-size: 8.625rem;
}

.fz-139 {
  font-size: 8.6875rem;
}

@media screen and (max-width: 47.9375em) {
  .fz-8 {
    font-size: 0.5rem;
  }
  .fz-9 {
    font-size: 0.5714285714rem;
  }
  .fz-10 {
    font-size: 0.6428571429rem;
  }
  .fz-11 {
    font-size: 0.7142857143rem;
  }
  .fz-12 {
    font-size: 0.7857142857rem;
  }
  .fz-13 {
    font-size: 0.8571428571rem;
  }
  .fz-14 {
    font-size: 0.9285714286rem;
  }
  .fz-15 {
    font-size: 1rem;
  }
  .fz-16 {
    font-size: 1.0714285714rem;
  }
  .fz-17 {
    font-size: 1.1428571429rem;
  }
  .fz-18 {
    font-size: 1.2142857143rem;
  }
  .fz-19 {
    font-size: 1.2857142857rem;
  }
  .fz-20 {
    font-size: 1.3571428571rem;
  }
  .fz-21 {
    font-size: 1.4285714286rem;
  }
  .fz-22 {
    font-size: 1.5rem;
  }
  .fz-23 {
    font-size: 1.5714285714rem;
  }
  .fz-24 {
    font-size: 1.6428571429rem;
  }
  .fz-25 {
    font-size: 1.7142857143rem;
  }
  .fz-26 {
    font-size: 1.7857142857rem;
  }
  .fz-27 {
    font-size: 1.8571428571rem;
  }
  .fz-28 {
    font-size: 1.9285714286rem;
  }
  .fz-29 {
    font-size: 2rem;
  }
  .fz-30 {
    font-size: 2.0714285714rem;
  }
  .fz-31 {
    font-size: 2.1428571429rem;
  }
  .fz-32 {
    font-size: 2.2142857143rem;
  }
  .fz-33 {
    font-size: 2.2857142857rem;
  }
  .fz-34 {
    font-size: 2.3571428571rem;
  }
  .fz-35 {
    font-size: 2.4285714286rem;
  }
  .fz-36 {
    font-size: 2.5rem;
  }
  .fz-37 {
    font-size: 2.5714285714rem;
  }
  .fz-38 {
    font-size: 2.6428571429rem;
  }
  .fz-39 {
    font-size: 2.7142857143rem;
  }
  .fz-40 {
    font-size: 2.7857142857rem;
  }
  .fz-41 {
    font-size: 2.8571428571rem;
  }
  .fz-42 {
    font-size: 2.9285714286rem;
  }
  .fz-43 {
    font-size: 3rem;
  }
  .fz-44 {
    font-size: 3.0714285714rem;
  }
  .fz-45 {
    font-size: 3.1428571429rem;
  }
  .fz-46 {
    font-size: 3.2142857143rem;
  }
  .fz-47 {
    font-size: 3.2857142857rem;
  }
  .fz-48 {
    font-size: 3.3571428571rem;
  }
  .fz-49 {
    font-size: 3.4285714286rem;
  }
  .fz-50 {
    font-size: 3.5rem;
  }
  .fz-51 {
    font-size: 3.5714285714rem;
  }
  .fz-52 {
    font-size: 3.6428571429rem;
  }
  .fz-53 {
    font-size: 3.7142857143rem;
  }
  .fz-54 {
    font-size: 3.7857142857rem;
  }
  .fz-55 {
    font-size: 3.8571428571rem;
  }
  .fz-56 {
    font-size: 3.9285714286rem;
  }
  .fz-57 {
    font-size: 4rem;
  }
  .fz-58 {
    font-size: 4.0714285714rem;
  }
  .fz-59 {
    font-size: 4.1428571429rem;
  }
  .fz-60 {
    font-size: 4.2142857143rem;
  }
  .fz-61 {
    font-size: 4.2857142857rem;
  }
  .fz-62 {
    font-size: 4.3571428571rem;
  }
  .fz-63 {
    font-size: 4.4285714286rem;
  }
  .fz-64 {
    font-size: 4.5rem;
  }
  .fz-65 {
    font-size: 4.5714285714rem;
  }
  .fz-66 {
    font-size: 4.6428571429rem;
  }
  .fz-67 {
    font-size: 4.7142857143rem;
  }
  .fz-68 {
    font-size: 4.7857142857rem;
  }
  .fz-69 {
    font-size: 4.8571428571rem;
  }
  .fz-70 {
    font-size: 4.9285714286rem;
  }
  .fz-71 {
    font-size: 5rem;
  }
  .fz-72 {
    font-size: 5.0714285714rem;
  }
  .fz-73 {
    font-size: 5.1428571429rem;
  }
  .fz-74 {
    font-size: 5.2142857143rem;
  }
  .fz-75 {
    font-size: 5.2857142857rem;
  }
  .fz-76 {
    font-size: 5.3571428571rem;
  }
  .fz-77 {
    font-size: 5.4285714286rem;
  }
  .fz-78 {
    font-size: 5.5rem;
  }
  .fz-79 {
    font-size: 5.5714285714rem;
  }
  .fz-80 {
    font-size: 5.6428571429rem;
  }
  .fz-81 {
    font-size: 5.7142857143rem;
  }
  .fz-82 {
    font-size: 5.7857142857rem;
  }
  .fz-83 {
    font-size: 5.8571428571rem;
  }
  .fz-84 {
    font-size: 5.9285714286rem;
  }
  .fz-85 {
    font-size: 6rem;
  }
  .fz-86 {
    font-size: 6.0714285714rem;
  }
  .fz-87 {
    font-size: 6.1428571429rem;
  }
  .fz-88 {
    font-size: 6.2142857143rem;
  }
  .fz-89 {
    font-size: 6.2857142857rem;
  }
  .fz-90 {
    font-size: 6.3571428571rem;
  }
  .fz-91 {
    font-size: 6.4285714286rem;
  }
  .fz-92 {
    font-size: 6.5rem;
  }
  .fz-93 {
    font-size: 6.5714285714rem;
  }
  .fz-94 {
    font-size: 6.6428571429rem;
  }
  .fz-95 {
    font-size: 6.7142857143rem;
  }
  .fz-96 {
    font-size: 6.7857142857rem;
  }
  .fz-97 {
    font-size: 6.8571428571rem;
  }
  .fz-98 {
    font-size: 6.9285714286rem;
  }
  .fz-99 {
    font-size: 7rem;
  }
  .fz-100 {
    font-size: 7.0714285714rem;
  }
  .fz-101 {
    font-size: 7.1428571429rem;
  }
  .fz-102 {
    font-size: 7.2142857143rem;
  }
  .fz-103 {
    font-size: 7.2857142857rem;
  }
  .fz-104 {
    font-size: 7.3571428571rem;
  }
  .fz-105 {
    font-size: 7.4285714286rem;
  }
  .fz-106 {
    font-size: 7.5rem;
  }
  .fz-107 {
    font-size: 7.5714285714rem;
  }
  .fz-108 {
    font-size: 7.6428571429rem;
  }
  .fz-109 {
    font-size: 7.7142857143rem;
  }
  .fz-110 {
    font-size: 7.7857142857rem;
  }
  .fz-111 {
    font-size: 7.8571428571rem;
  }
  .fz-112 {
    font-size: 7.9285714286rem;
  }
  .fz-113 {
    font-size: 8rem;
  }
  .fz-114 {
    font-size: 8.0714285714rem;
  }
  .fz-115 {
    font-size: 8.1428571429rem;
  }
  .fz-116 {
    font-size: 8.2142857143rem;
  }
  .fz-117 {
    font-size: 8.2857142857rem;
  }
  .fz-118 {
    font-size: 8.3571428571rem;
  }
  .fz-119 {
    font-size: 8.4285714286rem;
  }
  .fz-120 {
    font-size: 8.5rem;
  }
  .fz-121 {
    font-size: 8.5714285714rem;
  }
  .fz-122 {
    font-size: 8.6428571429rem;
  }
  .fz-123 {
    font-size: 8.7142857143rem;
  }
  .fz-124 {
    font-size: 8.7857142857rem;
  }
  .fz-125 {
    font-size: 8.8571428571rem;
  }
  .fz-126 {
    font-size: 8.9285714286rem;
  }
  .fz-127 {
    font-size: 9rem;
  }
  .fz-128 {
    font-size: 9.0714285714rem;
  }
  .fz-129 {
    font-size: 9.1428571429rem;
  }
  .fz-130 {
    font-size: 9.2142857143rem;
  }
  .fz-131 {
    font-size: 9.2857142857rem;
  }
  .fz-132 {
    font-size: 9.3571428571rem;
  }
  .fz-133 {
    font-size: 9.4285714286rem;
  }
  .fz-134 {
    font-size: 9.5rem;
  }
  .fz-135 {
    font-size: 9.5714285714rem;
  }
  .fz-136 {
    font-size: 9.6428571429rem;
  }
  .fz-137 {
    font-size: 9.7142857143rem;
  }
  .fz-138 {
    font-size: 9.7857142857rem;
  }
  .fz-139 {
    font-size: 9.8571428571rem;
  }
}
/* txt
**************************************** */
.txt-lg {
  font-size: 1.125rem;
}

.txt-sm {
  font-size: 0.875rem;
}

.txt-xs {
  font-size: 0.75rem;
}

.txt-wh,
.txt-wh * {
  color: var(--clr-wht, #fff);
}

.txt-main,
.txt-main * {
  color: var(--clr-main, #1d7fb7);
}

.txt-sub,
.txt-sub * {
  color: var(--clr-sub, #eee);
}

/* --- font --- */
h1, h2, h3, h4, h5, h6, th {
  font-weight: 400;
}

.font-jp,
.bold {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-weight: 700;
}

.font-en {
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}

.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@media screen and (max-width: 47.9375em) {
  .txt-lg {
    font-size: sprem(15);
  }
  .txt-sm {
    font-size: sprem(13);
  }
  .txt-xs {
    font-size: sprem(11);
  }
  .txt-ctr-pc,
.txt-rgt-pc {
    text-align: left;
  }
}
/* bg
********************************************** */
.lps_sec:nth-child(2n) {
  background-color: var(--bg-off_wht, #f7f7f7);
}

.bg-main {
  background-color: var(--bg-main, #1D7FB7);
}

.bg-sub {
  background-color: #eee;
}

.bg-wh {
  background-color: var(--bg-wht, #fff);
}

/* --- pc or sp --- */
.pc-none,
.pc-none-inline,
.pc-none-table,
.pc-none-flex {
  display: none;
}

.sp-none {
  display: block;
}

.sp-none-inline {
  display: inline;
}

.sp-none-table {
  display: table;
}

.sp-none-flex {
  display: flex;
}

@media screen and (max-width: 47.9375em) {
  /* --- pc or sp --- */
  .sp-none,
.sp-none-inline,
.sp-none-table,
.sp-none-flex {
    display: none;
  }
  .pc-none {
    display: block;
  }
  .pc-none-inline {
    display: inline;
  }
  .pc-none-table {
    display: table;
  }
  .pc-none-flex {
    display: flex;
  }
}
/* img
********************************************** */
.img-ctr {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-trim {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-trim.pos_rel::before {
  content: "";
  display: block;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-cover.pos_ab {
  inset: 0;
  margin: auto;
}

.img-contain {
  object-fit: contain;
}

/* --- margin 　古い・削除--- */
.mgn-btm8 {
  margin-bottom: 8px;
}

.mgn-btm16 {
  margin-bottom: 16px;
}

.mgn-btm24 {
  margin-bottom: 24px;
}

.mgn-btm32 {
  margin-bottom: 32px;
}

.mgn-btm40 {
  margin-bottom: 40px;
}

.mgn-btm48 {
  margin-bottom: 48px;
}

.mgn-btm56 {
  margin-bottom: 56px;
}

.mgn-btm64 {
  margin-bottom: 64px;
}

.mgn-btm72 {
  margin-bottom: 72px;
}

.mgn-btm80 {
  margin-bottom: 80px;
}

.mgn-0 {
  margin: 0 rem;
}

.mgn-top0 {
  margin-top: 0 rem;
}

.mgn-rgt0 {
  margin-right: 0 rem;
}

.mgn-btm0 {
  margin-bottom: 0 rem;
}

.mgn-lft0 {
  margin-left: 0 rem;
}

.mgn-1 {
  margin: 0.0625 rem;
}

.mgn-top1 {
  margin-top: 0.0625 rem;
}

.mgn-rgt1 {
  margin-right: 0.0625 rem;
}

.mgn-btm1 {
  margin-bottom: 0.0625 rem;
}

.mgn-lft1 {
  margin-left: 0.0625 rem;
}

.mgn-2 {
  margin: 0.125 rem;
}

.mgn-top2 {
  margin-top: 0.125 rem;
}

.mgn-rgt2 {
  margin-right: 0.125 rem;
}

.mgn-btm2 {
  margin-bottom: 0.125 rem;
}

.mgn-lft2 {
  margin-left: 0.125 rem;
}

.mgn-3 {
  margin: 0.1875 rem;
}

.mgn-top3 {
  margin-top: 0.1875 rem;
}

.mgn-rgt3 {
  margin-right: 0.1875 rem;
}

.mgn-btm3 {
  margin-bottom: 0.1875 rem;
}

.mgn-lft3 {
  margin-left: 0.1875 rem;
}

.mgn-4 {
  margin: 0.25 rem;
}

.mgn-top4 {
  margin-top: 0.25 rem;
}

.mgn-rgt4 {
  margin-right: 0.25 rem;
}

.mgn-btm4 {
  margin-bottom: 0.25 rem;
}

.mgn-lft4 {
  margin-left: 0.25 rem;
}

.mgn-5 {
  margin: 0.3125 rem;
}

.mgn-top5 {
  margin-top: 0.3125 rem;
}

.mgn-rgt5 {
  margin-right: 0.3125 rem;
}

.mgn-btm5 {
  margin-bottom: 0.3125 rem;
}

.mgn-lft5 {
  margin-left: 0.3125 rem;
}

.mgn-6 {
  margin: 0.375 rem;
}

.mgn-top6 {
  margin-top: 0.375 rem;
}

.mgn-rgt6 {
  margin-right: 0.375 rem;
}

.mgn-btm6 {
  margin-bottom: 0.375 rem;
}

.mgn-lft6 {
  margin-left: 0.375 rem;
}

.mgn-7 {
  margin: 0.4375 rem;
}

.mgn-top7 {
  margin-top: 0.4375 rem;
}

.mgn-rgt7 {
  margin-right: 0.4375 rem;
}

.mgn-btm7 {
  margin-bottom: 0.4375 rem;
}

.mgn-lft7 {
  margin-left: 0.4375 rem;
}

.mgn-8 {
  margin: 0.5 rem;
}

.mgn-top8 {
  margin-top: 0.5 rem;
}

.mgn-rgt8 {
  margin-right: 0.5 rem;
}

.mgn-btm8 {
  margin-bottom: 0.5 rem;
}

.mgn-lft8 {
  margin-left: 0.5 rem;
}

.mgn-9 {
  margin: 0.5625 rem;
}

.mgn-top9 {
  margin-top: 0.5625 rem;
}

.mgn-rgt9 {
  margin-right: 0.5625 rem;
}

.mgn-btm9 {
  margin-bottom: 0.5625 rem;
}

.mgn-lft9 {
  margin-left: 0.5625 rem;
}

.mgn-10 {
  margin: 0.625 rem;
}

.mgn-top10 {
  margin-top: 0.625 rem;
}

.mgn-rgt10 {
  margin-right: 0.625 rem;
}

.mgn-btm10 {
  margin-bottom: 0.625 rem;
}

.mgn-lft10 {
  margin-left: 0.625 rem;
}

.mgn-11 {
  margin: 0.6875 rem;
}

.mgn-top11 {
  margin-top: 0.6875 rem;
}

.mgn-rgt11 {
  margin-right: 0.6875 rem;
}

.mgn-btm11 {
  margin-bottom: 0.6875 rem;
}

.mgn-lft11 {
  margin-left: 0.6875 rem;
}

.mgn-12 {
  margin: 0.75 rem;
}

.mgn-top12 {
  margin-top: 0.75 rem;
}

.mgn-rgt12 {
  margin-right: 0.75 rem;
}

.mgn-btm12 {
  margin-bottom: 0.75 rem;
}

.mgn-lft12 {
  margin-left: 0.75 rem;
}

.mgn-13 {
  margin: 0.8125 rem;
}

.mgn-top13 {
  margin-top: 0.8125 rem;
}

.mgn-rgt13 {
  margin-right: 0.8125 rem;
}

.mgn-btm13 {
  margin-bottom: 0.8125 rem;
}

.mgn-lft13 {
  margin-left: 0.8125 rem;
}

.mgn-14 {
  margin: 0.875 rem;
}

.mgn-top14 {
  margin-top: 0.875 rem;
}

.mgn-rgt14 {
  margin-right: 0.875 rem;
}

.mgn-btm14 {
  margin-bottom: 0.875 rem;
}

.mgn-lft14 {
  margin-left: 0.875 rem;
}

.mgn-15 {
  margin: 0.9375 rem;
}

.mgn-top15 {
  margin-top: 0.9375 rem;
}

.mgn-rgt15 {
  margin-right: 0.9375 rem;
}

.mgn-btm15 {
  margin-bottom: 0.9375 rem;
}

.mgn-lft15 {
  margin-left: 0.9375 rem;
}

.mgn-16 {
  margin: 1 rem;
}

.mgn-top16 {
  margin-top: 1 rem;
}

.mgn-rgt16 {
  margin-right: 1 rem;
}

.mgn-btm16 {
  margin-bottom: 1 rem;
}

.mgn-lft16 {
  margin-left: 1 rem;
}

.mgn-17 {
  margin: 1.0625 rem;
}

.mgn-top17 {
  margin-top: 1.0625 rem;
}

.mgn-rgt17 {
  margin-right: 1.0625 rem;
}

.mgn-btm17 {
  margin-bottom: 1.0625 rem;
}

.mgn-lft17 {
  margin-left: 1.0625 rem;
}

.mgn-18 {
  margin: 1.125 rem;
}

.mgn-top18 {
  margin-top: 1.125 rem;
}

.mgn-rgt18 {
  margin-right: 1.125 rem;
}

.mgn-btm18 {
  margin-bottom: 1.125 rem;
}

.mgn-lft18 {
  margin-left: 1.125 rem;
}

.mgn-19 {
  margin: 1.1875 rem;
}

.mgn-top19 {
  margin-top: 1.1875 rem;
}

.mgn-rgt19 {
  margin-right: 1.1875 rem;
}

.mgn-btm19 {
  margin-bottom: 1.1875 rem;
}

.mgn-lft19 {
  margin-left: 1.1875 rem;
}

.mgn-20 {
  margin: 1.25 rem;
}

.mgn-top20 {
  margin-top: 1.25 rem;
}

.mgn-rgt20 {
  margin-right: 1.25 rem;
}

.mgn-btm20 {
  margin-bottom: 1.25 rem;
}

.mgn-lft20 {
  margin-left: 1.25 rem;
}

.mgn-21 {
  margin: 1.3125 rem;
}

.mgn-top21 {
  margin-top: 1.3125 rem;
}

.mgn-rgt21 {
  margin-right: 1.3125 rem;
}

.mgn-btm21 {
  margin-bottom: 1.3125 rem;
}

.mgn-lft21 {
  margin-left: 1.3125 rem;
}

.mgn-22 {
  margin: 1.375 rem;
}

.mgn-top22 {
  margin-top: 1.375 rem;
}

.mgn-rgt22 {
  margin-right: 1.375 rem;
}

.mgn-btm22 {
  margin-bottom: 1.375 rem;
}

.mgn-lft22 {
  margin-left: 1.375 rem;
}

.mgn-23 {
  margin: 1.4375 rem;
}

.mgn-top23 {
  margin-top: 1.4375 rem;
}

.mgn-rgt23 {
  margin-right: 1.4375 rem;
}

.mgn-btm23 {
  margin-bottom: 1.4375 rem;
}

.mgn-lft23 {
  margin-left: 1.4375 rem;
}

.mgn-24 {
  margin: 1.5 rem;
}

.mgn-top24 {
  margin-top: 1.5 rem;
}

.mgn-rgt24 {
  margin-right: 1.5 rem;
}

.mgn-btm24 {
  margin-bottom: 1.5 rem;
}

.mgn-lft24 {
  margin-left: 1.5 rem;
}

.mgn-25 {
  margin: 1.5625 rem;
}

.mgn-top25 {
  margin-top: 1.5625 rem;
}

.mgn-rgt25 {
  margin-right: 1.5625 rem;
}

.mgn-btm25 {
  margin-bottom: 1.5625 rem;
}

.mgn-lft25 {
  margin-left: 1.5625 rem;
}

.mgn-26 {
  margin: 1.625 rem;
}

.mgn-top26 {
  margin-top: 1.625 rem;
}

.mgn-rgt26 {
  margin-right: 1.625 rem;
}

.mgn-btm26 {
  margin-bottom: 1.625 rem;
}

.mgn-lft26 {
  margin-left: 1.625 rem;
}

.mgn-27 {
  margin: 1.6875 rem;
}

.mgn-top27 {
  margin-top: 1.6875 rem;
}

.mgn-rgt27 {
  margin-right: 1.6875 rem;
}

.mgn-btm27 {
  margin-bottom: 1.6875 rem;
}

.mgn-lft27 {
  margin-left: 1.6875 rem;
}

.mgn-28 {
  margin: 1.75 rem;
}

.mgn-top28 {
  margin-top: 1.75 rem;
}

.mgn-rgt28 {
  margin-right: 1.75 rem;
}

.mgn-btm28 {
  margin-bottom: 1.75 rem;
}

.mgn-lft28 {
  margin-left: 1.75 rem;
}

.mgn-29 {
  margin: 1.8125 rem;
}

.mgn-top29 {
  margin-top: 1.8125 rem;
}

.mgn-rgt29 {
  margin-right: 1.8125 rem;
}

.mgn-btm29 {
  margin-bottom: 1.8125 rem;
}

.mgn-lft29 {
  margin-left: 1.8125 rem;
}

.mgn-30 {
  margin: 1.875 rem;
}

.mgn-top30 {
  margin-top: 1.875 rem;
}

.mgn-rgt30 {
  margin-right: 1.875 rem;
}

.mgn-btm30 {
  margin-bottom: 1.875 rem;
}

.mgn-lft30 {
  margin-left: 1.875 rem;
}

.mgn-31 {
  margin: 1.9375 rem;
}

.mgn-top31 {
  margin-top: 1.9375 rem;
}

.mgn-rgt31 {
  margin-right: 1.9375 rem;
}

.mgn-btm31 {
  margin-bottom: 1.9375 rem;
}

.mgn-lft31 {
  margin-left: 1.9375 rem;
}

.mgn-32 {
  margin: 2 rem;
}

.mgn-top32 {
  margin-top: 2 rem;
}

.mgn-rgt32 {
  margin-right: 2 rem;
}

.mgn-btm32 {
  margin-bottom: 2 rem;
}

.mgn-lft32 {
  margin-left: 2 rem;
}

.mgn-33 {
  margin: 2.0625 rem;
}

.mgn-top33 {
  margin-top: 2.0625 rem;
}

.mgn-rgt33 {
  margin-right: 2.0625 rem;
}

.mgn-btm33 {
  margin-bottom: 2.0625 rem;
}

.mgn-lft33 {
  margin-left: 2.0625 rem;
}

.mgn-34 {
  margin: 2.125 rem;
}

.mgn-top34 {
  margin-top: 2.125 rem;
}

.mgn-rgt34 {
  margin-right: 2.125 rem;
}

.mgn-btm34 {
  margin-bottom: 2.125 rem;
}

.mgn-lft34 {
  margin-left: 2.125 rem;
}

.mgn-35 {
  margin: 2.1875 rem;
}

.mgn-top35 {
  margin-top: 2.1875 rem;
}

.mgn-rgt35 {
  margin-right: 2.1875 rem;
}

.mgn-btm35 {
  margin-bottom: 2.1875 rem;
}

.mgn-lft35 {
  margin-left: 2.1875 rem;
}

.mgn-36 {
  margin: 2.25 rem;
}

.mgn-top36 {
  margin-top: 2.25 rem;
}

.mgn-rgt36 {
  margin-right: 2.25 rem;
}

.mgn-btm36 {
  margin-bottom: 2.25 rem;
}

.mgn-lft36 {
  margin-left: 2.25 rem;
}

.mgn-37 {
  margin: 2.3125 rem;
}

.mgn-top37 {
  margin-top: 2.3125 rem;
}

.mgn-rgt37 {
  margin-right: 2.3125 rem;
}

.mgn-btm37 {
  margin-bottom: 2.3125 rem;
}

.mgn-lft37 {
  margin-left: 2.3125 rem;
}

.mgn-38 {
  margin: 2.375 rem;
}

.mgn-top38 {
  margin-top: 2.375 rem;
}

.mgn-rgt38 {
  margin-right: 2.375 rem;
}

.mgn-btm38 {
  margin-bottom: 2.375 rem;
}

.mgn-lft38 {
  margin-left: 2.375 rem;
}

.mgn-39 {
  margin: 2.4375 rem;
}

.mgn-top39 {
  margin-top: 2.4375 rem;
}

.mgn-rgt39 {
  margin-right: 2.4375 rem;
}

.mgn-btm39 {
  margin-bottom: 2.4375 rem;
}

.mgn-lft39 {
  margin-left: 2.4375 rem;
}

.mgn-40 {
  margin: 2.5 rem;
}

.mgn-top40 {
  margin-top: 2.5 rem;
}

.mgn-rgt40 {
  margin-right: 2.5 rem;
}

.mgn-btm40 {
  margin-bottom: 2.5 rem;
}

.mgn-lft40 {
  margin-left: 2.5 rem;
}

.mgn-41 {
  margin: 2.5625 rem;
}

.mgn-top41 {
  margin-top: 2.5625 rem;
}

.mgn-rgt41 {
  margin-right: 2.5625 rem;
}

.mgn-btm41 {
  margin-bottom: 2.5625 rem;
}

.mgn-lft41 {
  margin-left: 2.5625 rem;
}

.mgn-42 {
  margin: 2.625 rem;
}

.mgn-top42 {
  margin-top: 2.625 rem;
}

.mgn-rgt42 {
  margin-right: 2.625 rem;
}

.mgn-btm42 {
  margin-bottom: 2.625 rem;
}

.mgn-lft42 {
  margin-left: 2.625 rem;
}

.mgn-43 {
  margin: 2.6875 rem;
}

.mgn-top43 {
  margin-top: 2.6875 rem;
}

.mgn-rgt43 {
  margin-right: 2.6875 rem;
}

.mgn-btm43 {
  margin-bottom: 2.6875 rem;
}

.mgn-lft43 {
  margin-left: 2.6875 rem;
}

.mgn-44 {
  margin: 2.75 rem;
}

.mgn-top44 {
  margin-top: 2.75 rem;
}

.mgn-rgt44 {
  margin-right: 2.75 rem;
}

.mgn-btm44 {
  margin-bottom: 2.75 rem;
}

.mgn-lft44 {
  margin-left: 2.75 rem;
}

.mgn-45 {
  margin: 2.8125 rem;
}

.mgn-top45 {
  margin-top: 2.8125 rem;
}

.mgn-rgt45 {
  margin-right: 2.8125 rem;
}

.mgn-btm45 {
  margin-bottom: 2.8125 rem;
}

.mgn-lft45 {
  margin-left: 2.8125 rem;
}

.mgn-46 {
  margin: 2.875 rem;
}

.mgn-top46 {
  margin-top: 2.875 rem;
}

.mgn-rgt46 {
  margin-right: 2.875 rem;
}

.mgn-btm46 {
  margin-bottom: 2.875 rem;
}

.mgn-lft46 {
  margin-left: 2.875 rem;
}

.mgn-47 {
  margin: 2.9375 rem;
}

.mgn-top47 {
  margin-top: 2.9375 rem;
}

.mgn-rgt47 {
  margin-right: 2.9375 rem;
}

.mgn-btm47 {
  margin-bottom: 2.9375 rem;
}

.mgn-lft47 {
  margin-left: 2.9375 rem;
}

.mgn-48 {
  margin: 3 rem;
}

.mgn-top48 {
  margin-top: 3 rem;
}

.mgn-rgt48 {
  margin-right: 3 rem;
}

.mgn-btm48 {
  margin-bottom: 3 rem;
}

.mgn-lft48 {
  margin-left: 3 rem;
}

.mgn-49 {
  margin: 3.0625 rem;
}

.mgn-top49 {
  margin-top: 3.0625 rem;
}

.mgn-rgt49 {
  margin-right: 3.0625 rem;
}

.mgn-btm49 {
  margin-bottom: 3.0625 rem;
}

.mgn-lft49 {
  margin-left: 3.0625 rem;
}

.mgn-50 {
  margin: 3.125 rem;
}

.mgn-top50 {
  margin-top: 3.125 rem;
}

.mgn-rgt50 {
  margin-right: 3.125 rem;
}

.mgn-btm50 {
  margin-bottom: 3.125 rem;
}

.mgn-lft50 {
  margin-left: 3.125 rem;
}

.mgn-51 {
  margin: 3.1875 rem;
}

.mgn-top51 {
  margin-top: 3.1875 rem;
}

.mgn-rgt51 {
  margin-right: 3.1875 rem;
}

.mgn-btm51 {
  margin-bottom: 3.1875 rem;
}

.mgn-lft51 {
  margin-left: 3.1875 rem;
}

.mgn-52 {
  margin: 3.25 rem;
}

.mgn-top52 {
  margin-top: 3.25 rem;
}

.mgn-rgt52 {
  margin-right: 3.25 rem;
}

.mgn-btm52 {
  margin-bottom: 3.25 rem;
}

.mgn-lft52 {
  margin-left: 3.25 rem;
}

.mgn-53 {
  margin: 3.3125 rem;
}

.mgn-top53 {
  margin-top: 3.3125 rem;
}

.mgn-rgt53 {
  margin-right: 3.3125 rem;
}

.mgn-btm53 {
  margin-bottom: 3.3125 rem;
}

.mgn-lft53 {
  margin-left: 3.3125 rem;
}

.mgn-54 {
  margin: 3.375 rem;
}

.mgn-top54 {
  margin-top: 3.375 rem;
}

.mgn-rgt54 {
  margin-right: 3.375 rem;
}

.mgn-btm54 {
  margin-bottom: 3.375 rem;
}

.mgn-lft54 {
  margin-left: 3.375 rem;
}

.mgn-55 {
  margin: 3.4375 rem;
}

.mgn-top55 {
  margin-top: 3.4375 rem;
}

.mgn-rgt55 {
  margin-right: 3.4375 rem;
}

.mgn-btm55 {
  margin-bottom: 3.4375 rem;
}

.mgn-lft55 {
  margin-left: 3.4375 rem;
}

.mgn-56 {
  margin: 3.5 rem;
}

.mgn-top56 {
  margin-top: 3.5 rem;
}

.mgn-rgt56 {
  margin-right: 3.5 rem;
}

.mgn-btm56 {
  margin-bottom: 3.5 rem;
}

.mgn-lft56 {
  margin-left: 3.5 rem;
}

.mgn-57 {
  margin: 3.5625 rem;
}

.mgn-top57 {
  margin-top: 3.5625 rem;
}

.mgn-rgt57 {
  margin-right: 3.5625 rem;
}

.mgn-btm57 {
  margin-bottom: 3.5625 rem;
}

.mgn-lft57 {
  margin-left: 3.5625 rem;
}

.mgn-58 {
  margin: 3.625 rem;
}

.mgn-top58 {
  margin-top: 3.625 rem;
}

.mgn-rgt58 {
  margin-right: 3.625 rem;
}

.mgn-btm58 {
  margin-bottom: 3.625 rem;
}

.mgn-lft58 {
  margin-left: 3.625 rem;
}

.mgn-59 {
  margin: 3.6875 rem;
}

.mgn-top59 {
  margin-top: 3.6875 rem;
}

.mgn-rgt59 {
  margin-right: 3.6875 rem;
}

.mgn-btm59 {
  margin-bottom: 3.6875 rem;
}

.mgn-lft59 {
  margin-left: 3.6875 rem;
}

.mgn-60 {
  margin: 3.75 rem;
}

.mgn-top60 {
  margin-top: 3.75 rem;
}

.mgn-rgt60 {
  margin-right: 3.75 rem;
}

.mgn-btm60 {
  margin-bottom: 3.75 rem;
}

.mgn-lft60 {
  margin-left: 3.75 rem;
}

.mgn-61 {
  margin: 3.8125 rem;
}

.mgn-top61 {
  margin-top: 3.8125 rem;
}

.mgn-rgt61 {
  margin-right: 3.8125 rem;
}

.mgn-btm61 {
  margin-bottom: 3.8125 rem;
}

.mgn-lft61 {
  margin-left: 3.8125 rem;
}

.mgn-62 {
  margin: 3.875 rem;
}

.mgn-top62 {
  margin-top: 3.875 rem;
}

.mgn-rgt62 {
  margin-right: 3.875 rem;
}

.mgn-btm62 {
  margin-bottom: 3.875 rem;
}

.mgn-lft62 {
  margin-left: 3.875 rem;
}

.mgn-63 {
  margin: 3.9375 rem;
}

.mgn-top63 {
  margin-top: 3.9375 rem;
}

.mgn-rgt63 {
  margin-right: 3.9375 rem;
}

.mgn-btm63 {
  margin-bottom: 3.9375 rem;
}

.mgn-lft63 {
  margin-left: 3.9375 rem;
}

.mgn-64 {
  margin: 4 rem;
}

.mgn-top64 {
  margin-top: 4 rem;
}

.mgn-rgt64 {
  margin-right: 4 rem;
}

.mgn-btm64 {
  margin-bottom: 4 rem;
}

.mgn-lft64 {
  margin-left: 4 rem;
}

.mgn-65 {
  margin: 4.0625 rem;
}

.mgn-top65 {
  margin-top: 4.0625 rem;
}

.mgn-rgt65 {
  margin-right: 4.0625 rem;
}

.mgn-btm65 {
  margin-bottom: 4.0625 rem;
}

.mgn-lft65 {
  margin-left: 4.0625 rem;
}

.mgn-66 {
  margin: 4.125 rem;
}

.mgn-top66 {
  margin-top: 4.125 rem;
}

.mgn-rgt66 {
  margin-right: 4.125 rem;
}

.mgn-btm66 {
  margin-bottom: 4.125 rem;
}

.mgn-lft66 {
  margin-left: 4.125 rem;
}

.mgn-67 {
  margin: 4.1875 rem;
}

.mgn-top67 {
  margin-top: 4.1875 rem;
}

.mgn-rgt67 {
  margin-right: 4.1875 rem;
}

.mgn-btm67 {
  margin-bottom: 4.1875 rem;
}

.mgn-lft67 {
  margin-left: 4.1875 rem;
}

.mgn-68 {
  margin: 4.25 rem;
}

.mgn-top68 {
  margin-top: 4.25 rem;
}

.mgn-rgt68 {
  margin-right: 4.25 rem;
}

.mgn-btm68 {
  margin-bottom: 4.25 rem;
}

.mgn-lft68 {
  margin-left: 4.25 rem;
}

.mgn-69 {
  margin: 4.3125 rem;
}

.mgn-top69 {
  margin-top: 4.3125 rem;
}

.mgn-rgt69 {
  margin-right: 4.3125 rem;
}

.mgn-btm69 {
  margin-bottom: 4.3125 rem;
}

.mgn-lft69 {
  margin-left: 4.3125 rem;
}

.mgn-70 {
  margin: 4.375 rem;
}

.mgn-top70 {
  margin-top: 4.375 rem;
}

.mgn-rgt70 {
  margin-right: 4.375 rem;
}

.mgn-btm70 {
  margin-bottom: 4.375 rem;
}

.mgn-lft70 {
  margin-left: 4.375 rem;
}

.mgn-71 {
  margin: 4.4375 rem;
}

.mgn-top71 {
  margin-top: 4.4375 rem;
}

.mgn-rgt71 {
  margin-right: 4.4375 rem;
}

.mgn-btm71 {
  margin-bottom: 4.4375 rem;
}

.mgn-lft71 {
  margin-left: 4.4375 rem;
}

.mgn-72 {
  margin: 4.5 rem;
}

.mgn-top72 {
  margin-top: 4.5 rem;
}

.mgn-rgt72 {
  margin-right: 4.5 rem;
}

.mgn-btm72 {
  margin-bottom: 4.5 rem;
}

.mgn-lft72 {
  margin-left: 4.5 rem;
}

.mgn-73 {
  margin: 4.5625 rem;
}

.mgn-top73 {
  margin-top: 4.5625 rem;
}

.mgn-rgt73 {
  margin-right: 4.5625 rem;
}

.mgn-btm73 {
  margin-bottom: 4.5625 rem;
}

.mgn-lft73 {
  margin-left: 4.5625 rem;
}

.mgn-74 {
  margin: 4.625 rem;
}

.mgn-top74 {
  margin-top: 4.625 rem;
}

.mgn-rgt74 {
  margin-right: 4.625 rem;
}

.mgn-btm74 {
  margin-bottom: 4.625 rem;
}

.mgn-lft74 {
  margin-left: 4.625 rem;
}

.mgn-75 {
  margin: 4.6875 rem;
}

.mgn-top75 {
  margin-top: 4.6875 rem;
}

.mgn-rgt75 {
  margin-right: 4.6875 rem;
}

.mgn-btm75 {
  margin-bottom: 4.6875 rem;
}

.mgn-lft75 {
  margin-left: 4.6875 rem;
}

.mgn-76 {
  margin: 4.75 rem;
}

.mgn-top76 {
  margin-top: 4.75 rem;
}

.mgn-rgt76 {
  margin-right: 4.75 rem;
}

.mgn-btm76 {
  margin-bottom: 4.75 rem;
}

.mgn-lft76 {
  margin-left: 4.75 rem;
}

.mgn-77 {
  margin: 4.8125 rem;
}

.mgn-top77 {
  margin-top: 4.8125 rem;
}

.mgn-rgt77 {
  margin-right: 4.8125 rem;
}

.mgn-btm77 {
  margin-bottom: 4.8125 rem;
}

.mgn-lft77 {
  margin-left: 4.8125 rem;
}

.mgn-78 {
  margin: 4.875 rem;
}

.mgn-top78 {
  margin-top: 4.875 rem;
}

.mgn-rgt78 {
  margin-right: 4.875 rem;
}

.mgn-btm78 {
  margin-bottom: 4.875 rem;
}

.mgn-lft78 {
  margin-left: 4.875 rem;
}

.mgn-79 {
  margin: 4.9375 rem;
}

.mgn-top79 {
  margin-top: 4.9375 rem;
}

.mgn-rgt79 {
  margin-right: 4.9375 rem;
}

.mgn-btm79 {
  margin-bottom: 4.9375 rem;
}

.mgn-lft79 {
  margin-left: 4.9375 rem;
}

.mgn-80 {
  margin: 5 rem;
}

.mgn-top80 {
  margin-top: 5 rem;
}

.mgn-rgt80 {
  margin-right: 5 rem;
}

.mgn-btm80 {
  margin-bottom: 5 rem;
}

.mgn-lft80 {
  margin-left: 5 rem;
}

.mgn-81 {
  margin: 5.0625 rem;
}

.mgn-top81 {
  margin-top: 5.0625 rem;
}

.mgn-rgt81 {
  margin-right: 5.0625 rem;
}

.mgn-btm81 {
  margin-bottom: 5.0625 rem;
}

.mgn-lft81 {
  margin-left: 5.0625 rem;
}

.mgn-82 {
  margin: 5.125 rem;
}

.mgn-top82 {
  margin-top: 5.125 rem;
}

.mgn-rgt82 {
  margin-right: 5.125 rem;
}

.mgn-btm82 {
  margin-bottom: 5.125 rem;
}

.mgn-lft82 {
  margin-left: 5.125 rem;
}

.mgn-83 {
  margin: 5.1875 rem;
}

.mgn-top83 {
  margin-top: 5.1875 rem;
}

.mgn-rgt83 {
  margin-right: 5.1875 rem;
}

.mgn-btm83 {
  margin-bottom: 5.1875 rem;
}

.mgn-lft83 {
  margin-left: 5.1875 rem;
}

.mgn-84 {
  margin: 5.25 rem;
}

.mgn-top84 {
  margin-top: 5.25 rem;
}

.mgn-rgt84 {
  margin-right: 5.25 rem;
}

.mgn-btm84 {
  margin-bottom: 5.25 rem;
}

.mgn-lft84 {
  margin-left: 5.25 rem;
}

.mgn-85 {
  margin: 5.3125 rem;
}

.mgn-top85 {
  margin-top: 5.3125 rem;
}

.mgn-rgt85 {
  margin-right: 5.3125 rem;
}

.mgn-btm85 {
  margin-bottom: 5.3125 rem;
}

.mgn-lft85 {
  margin-left: 5.3125 rem;
}

.mgn-86 {
  margin: 5.375 rem;
}

.mgn-top86 {
  margin-top: 5.375 rem;
}

.mgn-rgt86 {
  margin-right: 5.375 rem;
}

.mgn-btm86 {
  margin-bottom: 5.375 rem;
}

.mgn-lft86 {
  margin-left: 5.375 rem;
}

.mgn-87 {
  margin: 5.4375 rem;
}

.mgn-top87 {
  margin-top: 5.4375 rem;
}

.mgn-rgt87 {
  margin-right: 5.4375 rem;
}

.mgn-btm87 {
  margin-bottom: 5.4375 rem;
}

.mgn-lft87 {
  margin-left: 5.4375 rem;
}

.mgn-88 {
  margin: 5.5 rem;
}

.mgn-top88 {
  margin-top: 5.5 rem;
}

.mgn-rgt88 {
  margin-right: 5.5 rem;
}

.mgn-btm88 {
  margin-bottom: 5.5 rem;
}

.mgn-lft88 {
  margin-left: 5.5 rem;
}

.mgn-89 {
  margin: 5.5625 rem;
}

.mgn-top89 {
  margin-top: 5.5625 rem;
}

.mgn-rgt89 {
  margin-right: 5.5625 rem;
}

.mgn-btm89 {
  margin-bottom: 5.5625 rem;
}

.mgn-lft89 {
  margin-left: 5.5625 rem;
}

.mgn-90 {
  margin: 5.625 rem;
}

.mgn-top90 {
  margin-top: 5.625 rem;
}

.mgn-rgt90 {
  margin-right: 5.625 rem;
}

.mgn-btm90 {
  margin-bottom: 5.625 rem;
}

.mgn-lft90 {
  margin-left: 5.625 rem;
}

.mgn-91 {
  margin: 5.6875 rem;
}

.mgn-top91 {
  margin-top: 5.6875 rem;
}

.mgn-rgt91 {
  margin-right: 5.6875 rem;
}

.mgn-btm91 {
  margin-bottom: 5.6875 rem;
}

.mgn-lft91 {
  margin-left: 5.6875 rem;
}

.mgn-92 {
  margin: 5.75 rem;
}

.mgn-top92 {
  margin-top: 5.75 rem;
}

.mgn-rgt92 {
  margin-right: 5.75 rem;
}

.mgn-btm92 {
  margin-bottom: 5.75 rem;
}

.mgn-lft92 {
  margin-left: 5.75 rem;
}

.mgn-93 {
  margin: 5.8125 rem;
}

.mgn-top93 {
  margin-top: 5.8125 rem;
}

.mgn-rgt93 {
  margin-right: 5.8125 rem;
}

.mgn-btm93 {
  margin-bottom: 5.8125 rem;
}

.mgn-lft93 {
  margin-left: 5.8125 rem;
}

.mgn-94 {
  margin: 5.875 rem;
}

.mgn-top94 {
  margin-top: 5.875 rem;
}

.mgn-rgt94 {
  margin-right: 5.875 rem;
}

.mgn-btm94 {
  margin-bottom: 5.875 rem;
}

.mgn-lft94 {
  margin-left: 5.875 rem;
}

.mgn-95 {
  margin: 5.9375 rem;
}

.mgn-top95 {
  margin-top: 5.9375 rem;
}

.mgn-rgt95 {
  margin-right: 5.9375 rem;
}

.mgn-btm95 {
  margin-bottom: 5.9375 rem;
}

.mgn-lft95 {
  margin-left: 5.9375 rem;
}

.mgn-96 {
  margin: 6 rem;
}

.mgn-top96 {
  margin-top: 6 rem;
}

.mgn-rgt96 {
  margin-right: 6 rem;
}

.mgn-btm96 {
  margin-bottom: 6 rem;
}

.mgn-lft96 {
  margin-left: 6 rem;
}

.mgn-97 {
  margin: 6.0625 rem;
}

.mgn-top97 {
  margin-top: 6.0625 rem;
}

.mgn-rgt97 {
  margin-right: 6.0625 rem;
}

.mgn-btm97 {
  margin-bottom: 6.0625 rem;
}

.mgn-lft97 {
  margin-left: 6.0625 rem;
}

.mgn-98 {
  margin: 6.125 rem;
}

.mgn-top98 {
  margin-top: 6.125 rem;
}

.mgn-rgt98 {
  margin-right: 6.125 rem;
}

.mgn-btm98 {
  margin-bottom: 6.125 rem;
}

.mgn-lft98 {
  margin-left: 6.125 rem;
}

.mgn-99 {
  margin: 6.1875 rem;
}

.mgn-top99 {
  margin-top: 6.1875 rem;
}

.mgn-rgt99 {
  margin-right: 6.1875 rem;
}

.mgn-btm99 {
  margin-bottom: 6.1875 rem;
}

.mgn-lft99 {
  margin-left: 6.1875 rem;
}

.mgn-100 {
  margin: 6.25 rem;
}

.mgn-top100 {
  margin-top: 6.25 rem;
}

.mgn-rgt100 {
  margin-right: 6.25 rem;
}

.mgn-btm100 {
  margin-bottom: 6.25 rem;
}

.mgn-lft100 {
  margin-left: 6.25 rem;
}

.mgn-101 {
  margin: 6.3125 rem;
}

.mgn-top101 {
  margin-top: 6.3125 rem;
}

.mgn-rgt101 {
  margin-right: 6.3125 rem;
}

.mgn-btm101 {
  margin-bottom: 6.3125 rem;
}

.mgn-lft101 {
  margin-left: 6.3125 rem;
}

.mgn-102 {
  margin: 6.375 rem;
}

.mgn-top102 {
  margin-top: 6.375 rem;
}

.mgn-rgt102 {
  margin-right: 6.375 rem;
}

.mgn-btm102 {
  margin-bottom: 6.375 rem;
}

.mgn-lft102 {
  margin-left: 6.375 rem;
}

.mgn-103 {
  margin: 6.4375 rem;
}

.mgn-top103 {
  margin-top: 6.4375 rem;
}

.mgn-rgt103 {
  margin-right: 6.4375 rem;
}

.mgn-btm103 {
  margin-bottom: 6.4375 rem;
}

.mgn-lft103 {
  margin-left: 6.4375 rem;
}

.mgn-104 {
  margin: 6.5 rem;
}

.mgn-top104 {
  margin-top: 6.5 rem;
}

.mgn-rgt104 {
  margin-right: 6.5 rem;
}

.mgn-btm104 {
  margin-bottom: 6.5 rem;
}

.mgn-lft104 {
  margin-left: 6.5 rem;
}

.mgn-105 {
  margin: 6.5625 rem;
}

.mgn-top105 {
  margin-top: 6.5625 rem;
}

.mgn-rgt105 {
  margin-right: 6.5625 rem;
}

.mgn-btm105 {
  margin-bottom: 6.5625 rem;
}

.mgn-lft105 {
  margin-left: 6.5625 rem;
}

.mgn-106 {
  margin: 6.625 rem;
}

.mgn-top106 {
  margin-top: 6.625 rem;
}

.mgn-rgt106 {
  margin-right: 6.625 rem;
}

.mgn-btm106 {
  margin-bottom: 6.625 rem;
}

.mgn-lft106 {
  margin-left: 6.625 rem;
}

.mgn-107 {
  margin: 6.6875 rem;
}

.mgn-top107 {
  margin-top: 6.6875 rem;
}

.mgn-rgt107 {
  margin-right: 6.6875 rem;
}

.mgn-btm107 {
  margin-bottom: 6.6875 rem;
}

.mgn-lft107 {
  margin-left: 6.6875 rem;
}

.mgn-108 {
  margin: 6.75 rem;
}

.mgn-top108 {
  margin-top: 6.75 rem;
}

.mgn-rgt108 {
  margin-right: 6.75 rem;
}

.mgn-btm108 {
  margin-bottom: 6.75 rem;
}

.mgn-lft108 {
  margin-left: 6.75 rem;
}

.mgn-109 {
  margin: 6.8125 rem;
}

.mgn-top109 {
  margin-top: 6.8125 rem;
}

.mgn-rgt109 {
  margin-right: 6.8125 rem;
}

.mgn-btm109 {
  margin-bottom: 6.8125 rem;
}

.mgn-lft109 {
  margin-left: 6.8125 rem;
}

.mgn-110 {
  margin: 6.875 rem;
}

.mgn-top110 {
  margin-top: 6.875 rem;
}

.mgn-rgt110 {
  margin-right: 6.875 rem;
}

.mgn-btm110 {
  margin-bottom: 6.875 rem;
}

.mgn-lft110 {
  margin-left: 6.875 rem;
}

.mgn-111 {
  margin: 6.9375 rem;
}

.mgn-top111 {
  margin-top: 6.9375 rem;
}

.mgn-rgt111 {
  margin-right: 6.9375 rem;
}

.mgn-btm111 {
  margin-bottom: 6.9375 rem;
}

.mgn-lft111 {
  margin-left: 6.9375 rem;
}

.mgn-112 {
  margin: 7 rem;
}

.mgn-top112 {
  margin-top: 7 rem;
}

.mgn-rgt112 {
  margin-right: 7 rem;
}

.mgn-btm112 {
  margin-bottom: 7 rem;
}

.mgn-lft112 {
  margin-left: 7 rem;
}

.mgn-113 {
  margin: 7.0625 rem;
}

.mgn-top113 {
  margin-top: 7.0625 rem;
}

.mgn-rgt113 {
  margin-right: 7.0625 rem;
}

.mgn-btm113 {
  margin-bottom: 7.0625 rem;
}

.mgn-lft113 {
  margin-left: 7.0625 rem;
}

.mgn-114 {
  margin: 7.125 rem;
}

.mgn-top114 {
  margin-top: 7.125 rem;
}

.mgn-rgt114 {
  margin-right: 7.125 rem;
}

.mgn-btm114 {
  margin-bottom: 7.125 rem;
}

.mgn-lft114 {
  margin-left: 7.125 rem;
}

.mgn-115 {
  margin: 7.1875 rem;
}

.mgn-top115 {
  margin-top: 7.1875 rem;
}

.mgn-rgt115 {
  margin-right: 7.1875 rem;
}

.mgn-btm115 {
  margin-bottom: 7.1875 rem;
}

.mgn-lft115 {
  margin-left: 7.1875 rem;
}

.mgn-116 {
  margin: 7.25 rem;
}

.mgn-top116 {
  margin-top: 7.25 rem;
}

.mgn-rgt116 {
  margin-right: 7.25 rem;
}

.mgn-btm116 {
  margin-bottom: 7.25 rem;
}

.mgn-lft116 {
  margin-left: 7.25 rem;
}

.mgn-117 {
  margin: 7.3125 rem;
}

.mgn-top117 {
  margin-top: 7.3125 rem;
}

.mgn-rgt117 {
  margin-right: 7.3125 rem;
}

.mgn-btm117 {
  margin-bottom: 7.3125 rem;
}

.mgn-lft117 {
  margin-left: 7.3125 rem;
}

.mgn-118 {
  margin: 7.375 rem;
}

.mgn-top118 {
  margin-top: 7.375 rem;
}

.mgn-rgt118 {
  margin-right: 7.375 rem;
}

.mgn-btm118 {
  margin-bottom: 7.375 rem;
}

.mgn-lft118 {
  margin-left: 7.375 rem;
}

.mgn-119 {
  margin: 7.4375 rem;
}

.mgn-top119 {
  margin-top: 7.4375 rem;
}

.mgn-rgt119 {
  margin-right: 7.4375 rem;
}

.mgn-btm119 {
  margin-bottom: 7.4375 rem;
}

.mgn-lft119 {
  margin-left: 7.4375 rem;
}

.mgn-120 {
  margin: 7.5 rem;
}

.mgn-top120 {
  margin-top: 7.5 rem;
}

.mgn-rgt120 {
  margin-right: 7.5 rem;
}

.mgn-btm120 {
  margin-bottom: 7.5 rem;
}

.mgn-lft120 {
  margin-left: 7.5 rem;
}

.mgn-121 {
  margin: 7.5625 rem;
}

.mgn-top121 {
  margin-top: 7.5625 rem;
}

.mgn-rgt121 {
  margin-right: 7.5625 rem;
}

.mgn-btm121 {
  margin-bottom: 7.5625 rem;
}

.mgn-lft121 {
  margin-left: 7.5625 rem;
}

.mgn-122 {
  margin: 7.625 rem;
}

.mgn-top122 {
  margin-top: 7.625 rem;
}

.mgn-rgt122 {
  margin-right: 7.625 rem;
}

.mgn-btm122 {
  margin-bottom: 7.625 rem;
}

.mgn-lft122 {
  margin-left: 7.625 rem;
}

.mgn-123 {
  margin: 7.6875 rem;
}

.mgn-top123 {
  margin-top: 7.6875 rem;
}

.mgn-rgt123 {
  margin-right: 7.6875 rem;
}

.mgn-btm123 {
  margin-bottom: 7.6875 rem;
}

.mgn-lft123 {
  margin-left: 7.6875 rem;
}

.mgn-124 {
  margin: 7.75 rem;
}

.mgn-top124 {
  margin-top: 7.75 rem;
}

.mgn-rgt124 {
  margin-right: 7.75 rem;
}

.mgn-btm124 {
  margin-bottom: 7.75 rem;
}

.mgn-lft124 {
  margin-left: 7.75 rem;
}

.mgn-125 {
  margin: 7.8125 rem;
}

.mgn-top125 {
  margin-top: 7.8125 rem;
}

.mgn-rgt125 {
  margin-right: 7.8125 rem;
}

.mgn-btm125 {
  margin-bottom: 7.8125 rem;
}

.mgn-lft125 {
  margin-left: 7.8125 rem;
}

.mgn-126 {
  margin: 7.875 rem;
}

.mgn-top126 {
  margin-top: 7.875 rem;
}

.mgn-rgt126 {
  margin-right: 7.875 rem;
}

.mgn-btm126 {
  margin-bottom: 7.875 rem;
}

.mgn-lft126 {
  margin-left: 7.875 rem;
}

.mgn-127 {
  margin: 7.9375 rem;
}

.mgn-top127 {
  margin-top: 7.9375 rem;
}

.mgn-rgt127 {
  margin-right: 7.9375 rem;
}

.mgn-btm127 {
  margin-bottom: 7.9375 rem;
}

.mgn-lft127 {
  margin-left: 7.9375 rem;
}

.mgn-128 {
  margin: 8 rem;
}

.mgn-top128 {
  margin-top: 8 rem;
}

.mgn-rgt128 {
  margin-right: 8 rem;
}

.mgn-btm128 {
  margin-bottom: 8 rem;
}

.mgn-lft128 {
  margin-left: 8 rem;
}

.mgn-129 {
  margin: 8.0625 rem;
}

.mgn-top129 {
  margin-top: 8.0625 rem;
}

.mgn-rgt129 {
  margin-right: 8.0625 rem;
}

.mgn-btm129 {
  margin-bottom: 8.0625 rem;
}

.mgn-lft129 {
  margin-left: 8.0625 rem;
}

.mgn-130 {
  margin: 8.125 rem;
}

.mgn-top130 {
  margin-top: 8.125 rem;
}

.mgn-rgt130 {
  margin-right: 8.125 rem;
}

.mgn-btm130 {
  margin-bottom: 8.125 rem;
}

.mgn-lft130 {
  margin-left: 8.125 rem;
}

.mgn-131 {
  margin: 8.1875 rem;
}

.mgn-top131 {
  margin-top: 8.1875 rem;
}

.mgn-rgt131 {
  margin-right: 8.1875 rem;
}

.mgn-btm131 {
  margin-bottom: 8.1875 rem;
}

.mgn-lft131 {
  margin-left: 8.1875 rem;
}

.mgn-132 {
  margin: 8.25 rem;
}

.mgn-top132 {
  margin-top: 8.25 rem;
}

.mgn-rgt132 {
  margin-right: 8.25 rem;
}

.mgn-btm132 {
  margin-bottom: 8.25 rem;
}

.mgn-lft132 {
  margin-left: 8.25 rem;
}

.mgn-133 {
  margin: 8.3125 rem;
}

.mgn-top133 {
  margin-top: 8.3125 rem;
}

.mgn-rgt133 {
  margin-right: 8.3125 rem;
}

.mgn-btm133 {
  margin-bottom: 8.3125 rem;
}

.mgn-lft133 {
  margin-left: 8.3125 rem;
}

.mgn-134 {
  margin: 8.375 rem;
}

.mgn-top134 {
  margin-top: 8.375 rem;
}

.mgn-rgt134 {
  margin-right: 8.375 rem;
}

.mgn-btm134 {
  margin-bottom: 8.375 rem;
}

.mgn-lft134 {
  margin-left: 8.375 rem;
}

.mgn-135 {
  margin: 8.4375 rem;
}

.mgn-top135 {
  margin-top: 8.4375 rem;
}

.mgn-rgt135 {
  margin-right: 8.4375 rem;
}

.mgn-btm135 {
  margin-bottom: 8.4375 rem;
}

.mgn-lft135 {
  margin-left: 8.4375 rem;
}

.mgn-136 {
  margin: 8.5 rem;
}

.mgn-top136 {
  margin-top: 8.5 rem;
}

.mgn-rgt136 {
  margin-right: 8.5 rem;
}

.mgn-btm136 {
  margin-bottom: 8.5 rem;
}

.mgn-lft136 {
  margin-left: 8.5 rem;
}

.mgn-137 {
  margin: 8.5625 rem;
}

.mgn-top137 {
  margin-top: 8.5625 rem;
}

.mgn-rgt137 {
  margin-right: 8.5625 rem;
}

.mgn-btm137 {
  margin-bottom: 8.5625 rem;
}

.mgn-lft137 {
  margin-left: 8.5625 rem;
}

.mgn-138 {
  margin: 8.625 rem;
}

.mgn-top138 {
  margin-top: 8.625 rem;
}

.mgn-rgt138 {
  margin-right: 8.625 rem;
}

.mgn-btm138 {
  margin-bottom: 8.625 rem;
}

.mgn-lft138 {
  margin-left: 8.625 rem;
}

.mgn-139 {
  margin: 8.6875 rem;
}

.mgn-top139 {
  margin-top: 8.6875 rem;
}

.mgn-rgt139 {
  margin-right: 8.6875 rem;
}

.mgn-btm139 {
  margin-bottom: 8.6875 rem;
}

.mgn-lft139 {
  margin-left: 8.6875 rem;
}

.mgn-140 {
  margin: 8.75 rem;
}

.mgn-top140 {
  margin-top: 8.75 rem;
}

.mgn-rgt140 {
  margin-right: 8.75 rem;
}

.mgn-btm140 {
  margin-bottom: 8.75 rem;
}

.mgn-lft140 {
  margin-left: 8.75 rem;
}

.mgn-141 {
  margin: 8.8125 rem;
}

.mgn-top141 {
  margin-top: 8.8125 rem;
}

.mgn-rgt141 {
  margin-right: 8.8125 rem;
}

.mgn-btm141 {
  margin-bottom: 8.8125 rem;
}

.mgn-lft141 {
  margin-left: 8.8125 rem;
}

.mgn-142 {
  margin: 8.875 rem;
}

.mgn-top142 {
  margin-top: 8.875 rem;
}

.mgn-rgt142 {
  margin-right: 8.875 rem;
}

.mgn-btm142 {
  margin-bottom: 8.875 rem;
}

.mgn-lft142 {
  margin-left: 8.875 rem;
}

.mgn-143 {
  margin: 8.9375 rem;
}

.mgn-top143 {
  margin-top: 8.9375 rem;
}

.mgn-rgt143 {
  margin-right: 8.9375 rem;
}

.mgn-btm143 {
  margin-bottom: 8.9375 rem;
}

.mgn-lft143 {
  margin-left: 8.9375 rem;
}

.mgn-144 {
  margin: 9 rem;
}

.mgn-top144 {
  margin-top: 9 rem;
}

.mgn-rgt144 {
  margin-right: 9 rem;
}

.mgn-btm144 {
  margin-bottom: 9 rem;
}

.mgn-lft144 {
  margin-left: 9 rem;
}

.mgn-145 {
  margin: 9.0625 rem;
}

.mgn-top145 {
  margin-top: 9.0625 rem;
}

.mgn-rgt145 {
  margin-right: 9.0625 rem;
}

.mgn-btm145 {
  margin-bottom: 9.0625 rem;
}

.mgn-lft145 {
  margin-left: 9.0625 rem;
}

.mgn-146 {
  margin: 9.125 rem;
}

.mgn-top146 {
  margin-top: 9.125 rem;
}

.mgn-rgt146 {
  margin-right: 9.125 rem;
}

.mgn-btm146 {
  margin-bottom: 9.125 rem;
}

.mgn-lft146 {
  margin-left: 9.125 rem;
}

.mgn-147 {
  margin: 9.1875 rem;
}

.mgn-top147 {
  margin-top: 9.1875 rem;
}

.mgn-rgt147 {
  margin-right: 9.1875 rem;
}

.mgn-btm147 {
  margin-bottom: 9.1875 rem;
}

.mgn-lft147 {
  margin-left: 9.1875 rem;
}

.mgn-148 {
  margin: 9.25 rem;
}

.mgn-top148 {
  margin-top: 9.25 rem;
}

.mgn-rgt148 {
  margin-right: 9.25 rem;
}

.mgn-btm148 {
  margin-bottom: 9.25 rem;
}

.mgn-lft148 {
  margin-left: 9.25 rem;
}

.mgn-149 {
  margin: 9.3125 rem;
}

.mgn-top149 {
  margin-top: 9.3125 rem;
}

.mgn-rgt149 {
  margin-right: 9.3125 rem;
}

.mgn-btm149 {
  margin-bottom: 9.3125 rem;
}

.mgn-lft149 {
  margin-left: 9.3125 rem;
}

.mgn-150 {
  margin: 9.375 rem;
}

.mgn-top150 {
  margin-top: 9.375 rem;
}

.mgn-rgt150 {
  margin-right: 9.375 rem;
}

.mgn-btm150 {
  margin-bottom: 9.375 rem;
}

.mgn-lft150 {
  margin-left: 9.375 rem;
}

@media screen and (max-width: 47.9375em) {
  /* --- margin --- 古い・削除*/
  .mgn-btm16 {
    margin-bottom: 8px;
  }
  .mgn-btm24 {
    margin-bottom: 16px;
  }
  .mgn-btm32 {
    margin-bottom: 16px;
  }
  .mgn-btm40 {
    margin-bottom: 24px;
  }
  .mgn-btm48 {
    margin-bottom: 24px;
  }
  .mgn-btm56 {
    margin-bottom: 32px;
  }
  .mgn-btm64 {
    margin-bottom: 32px;
  }
  .mgn-btm72 {
    margin-bottom: 40px;
  }
  .mgn-btm80 {
    margin-bottom: 40px;
  }
}
.mgn-0_em {
  margin: 0em;
}

.mgn-top0_em {
  margin-top: 0em;
}

.mgn-rgt0_em {
  margin-right: 0em;
}

.mgn-btm0_em {
  margin-bottom: 0em;
}

.mgn-lft0_em {
  margin-left: 0em;
}

.mgn-1_em {
  margin: 1em;
}

.mgn-top1_em {
  margin-top: 1em;
}

.mgn-rgt1_em {
  margin-right: 1em;
}

.mgn-btm1_em {
  margin-bottom: 1em;
}

.mgn-lft1_em {
  margin-left: 1em;
}

.mgn-2_em {
  margin: 2em;
}

.mgn-top2_em {
  margin-top: 2em;
}

.mgn-rgt2_em {
  margin-right: 2em;
}

.mgn-btm2_em {
  margin-bottom: 2em;
}

.mgn-lft2_em {
  margin-left: 2em;
}

.mgn-3_em {
  margin: 3em;
}

.mgn-top3_em {
  margin-top: 3em;
}

.mgn-rgt3_em {
  margin-right: 3em;
}

.mgn-btm3_em {
  margin-bottom: 3em;
}

.mgn-lft3_em {
  margin-left: 3em;
}

.mgn-4_em {
  margin: 4em;
}

.mgn-top4_em {
  margin-top: 4em;
}

.mgn-rgt4_em {
  margin-right: 4em;
}

.mgn-btm4_em {
  margin-bottom: 4em;
}

.mgn-lft4_em {
  margin-left: 4em;
}

.mgn-5_em {
  margin: 5em;
}

.mgn-top5_em {
  margin-top: 5em;
}

.mgn-rgt5_em {
  margin-right: 5em;
}

.mgn-btm5_em {
  margin-bottom: 5em;
}

.mgn-lft5_em {
  margin-left: 5em;
}

.mgn-6_em {
  margin: 6em;
}

.mgn-top6_em {
  margin-top: 6em;
}

.mgn-rgt6_em {
  margin-right: 6em;
}

.mgn-btm6_em {
  margin-bottom: 6em;
}

.mgn-lft6_em {
  margin-left: 6em;
}

.mgn-7_em {
  margin: 7em;
}

.mgn-top7_em {
  margin-top: 7em;
}

.mgn-rgt7_em {
  margin-right: 7em;
}

.mgn-btm7_em {
  margin-bottom: 7em;
}

.mgn-lft7_em {
  margin-left: 7em;
}

.mgn-8_em {
  margin: 8em;
}

.mgn-top8_em {
  margin-top: 8em;
}

.mgn-rgt8_em {
  margin-right: 8em;
}

.mgn-btm8_em {
  margin-bottom: 8em;
}

.mgn-lft8_em {
  margin-left: 8em;
}

.mgn-9_em {
  margin: 9em;
}

.mgn-top9_em {
  margin-top: 9em;
}

.mgn-rgt9_em {
  margin-right: 9em;
}

.mgn-btm9_em {
  margin-bottom: 9em;
}

.mgn-lft9_em {
  margin-left: 9em;
}

.mgn-10_em {
  margin: 10em;
}

.mgn-top10_em {
  margin-top: 10em;
}

.mgn-rgt10_em {
  margin-right: 10em;
}

.mgn-btm10_em {
  margin-bottom: 10em;
}

.mgn-lft10_em {
  margin-left: 10em;
}

.mgn-11_em {
  margin: 11em;
}

.mgn-top11_em {
  margin-top: 11em;
}

.mgn-rgt11_em {
  margin-right: 11em;
}

.mgn-btm11_em {
  margin-bottom: 11em;
}

.mgn-lft11_em {
  margin-left: 11em;
}

.mgn-12_em {
  margin: 12em;
}

.mgn-top12_em {
  margin-top: 12em;
}

.mgn-rgt12_em {
  margin-right: 12em;
}

.mgn-btm12_em {
  margin-bottom: 12em;
}

.mgn-lft12_em {
  margin-left: 12em;
}

.mgn-13_em {
  margin: 13em;
}

.mgn-top13_em {
  margin-top: 13em;
}

.mgn-rgt13_em {
  margin-right: 13em;
}

.mgn-btm13_em {
  margin-bottom: 13em;
}

.mgn-lft13_em {
  margin-left: 13em;
}

.mgn-14_em {
  margin: 14em;
}

.mgn-top14_em {
  margin-top: 14em;
}

.mgn-rgt14_em {
  margin-right: 14em;
}

.mgn-btm14_em {
  margin-bottom: 14em;
}

.mgn-lft14_em {
  margin-left: 14em;
}

.mgn-15_em {
  margin: 15em;
}

.mgn-top15_em {
  margin-top: 15em;
}

.mgn-rgt15_em {
  margin-right: 15em;
}

.mgn-btm15_em {
  margin-bottom: 15em;
}

.mgn-lft15_em {
  margin-left: 15em;
}

.pdg-0 {
  padding: 0px;
}

.pdg-top0 {
  padding-top: 0px;
}

.pdg-rgt0 {
  padding-right: 0px;
}

.pdg-btm0 {
  padding-bottom: 0px;
}

.pdg-lft0 {
  padding-left: 0px;
}

.pdg-1 {
  padding: 1px;
}

.pdg-top1 {
  padding-top: 1px;
}

.pdg-rgt1 {
  padding-right: 1px;
}

.pdg-btm1 {
  padding-bottom: 1px;
}

.pdg-lft1 {
  padding-left: 1px;
}

.pdg-2 {
  padding: 2px;
}

.pdg-top2 {
  padding-top: 2px;
}

.pdg-rgt2 {
  padding-right: 2px;
}

.pdg-btm2 {
  padding-bottom: 2px;
}

.pdg-lft2 {
  padding-left: 2px;
}

.pdg-3 {
  padding: 3px;
}

.pdg-top3 {
  padding-top: 3px;
}

.pdg-rgt3 {
  padding-right: 3px;
}

.pdg-btm3 {
  padding-bottom: 3px;
}

.pdg-lft3 {
  padding-left: 3px;
}

.pdg-4 {
  padding: 4px;
}

.pdg-top4 {
  padding-top: 4px;
}

.pdg-rgt4 {
  padding-right: 4px;
}

.pdg-btm4 {
  padding-bottom: 4px;
}

.pdg-lft4 {
  padding-left: 4px;
}

.pdg-5 {
  padding: 5px;
}

.pdg-top5 {
  padding-top: 5px;
}

.pdg-rgt5 {
  padding-right: 5px;
}

.pdg-btm5 {
  padding-bottom: 5px;
}

.pdg-lft5 {
  padding-left: 5px;
}

.pdg-6 {
  padding: 6px;
}

.pdg-top6 {
  padding-top: 6px;
}

.pdg-rgt6 {
  padding-right: 6px;
}

.pdg-btm6 {
  padding-bottom: 6px;
}

.pdg-lft6 {
  padding-left: 6px;
}

.pdg-7 {
  padding: 7px;
}

.pdg-top7 {
  padding-top: 7px;
}

.pdg-rgt7 {
  padding-right: 7px;
}

.pdg-btm7 {
  padding-bottom: 7px;
}

.pdg-lft7 {
  padding-left: 7px;
}

.pdg-8 {
  padding: 8px;
}

.pdg-top8 {
  padding-top: 8px;
}

.pdg-rgt8 {
  padding-right: 8px;
}

.pdg-btm8 {
  padding-bottom: 8px;
}

.pdg-lft8 {
  padding-left: 8px;
}

.pdg-9 {
  padding: 9px;
}

.pdg-top9 {
  padding-top: 9px;
}

.pdg-rgt9 {
  padding-right: 9px;
}

.pdg-btm9 {
  padding-bottom: 9px;
}

.pdg-lft9 {
  padding-left: 9px;
}

.pdg-10 {
  padding: 10px;
}

.pdg-top10 {
  padding-top: 10px;
}

.pdg-rgt10 {
  padding-right: 10px;
}

.pdg-btm10 {
  padding-bottom: 10px;
}

.pdg-lft10 {
  padding-left: 10px;
}

.pdg-11 {
  padding: 11px;
}

.pdg-top11 {
  padding-top: 11px;
}

.pdg-rgt11 {
  padding-right: 11px;
}

.pdg-btm11 {
  padding-bottom: 11px;
}

.pdg-lft11 {
  padding-left: 11px;
}

.pdg-12 {
  padding: 12px;
}

.pdg-top12 {
  padding-top: 12px;
}

.pdg-rgt12 {
  padding-right: 12px;
}

.pdg-btm12 {
  padding-bottom: 12px;
}

.pdg-lft12 {
  padding-left: 12px;
}

.pdg-13 {
  padding: 13px;
}

.pdg-top13 {
  padding-top: 13px;
}

.pdg-rgt13 {
  padding-right: 13px;
}

.pdg-btm13 {
  padding-bottom: 13px;
}

.pdg-lft13 {
  padding-left: 13px;
}

.pdg-14 {
  padding: 14px;
}

.pdg-top14 {
  padding-top: 14px;
}

.pdg-rgt14 {
  padding-right: 14px;
}

.pdg-btm14 {
  padding-bottom: 14px;
}

.pdg-lft14 {
  padding-left: 14px;
}

.pdg-15 {
  padding: 15px;
}

.pdg-top15 {
  padding-top: 15px;
}

.pdg-rgt15 {
  padding-right: 15px;
}

.pdg-btm15 {
  padding-bottom: 15px;
}

.pdg-lft15 {
  padding-left: 15px;
}

.pdg-16 {
  padding: 16px;
}

.pdg-top16 {
  padding-top: 16px;
}

.pdg-rgt16 {
  padding-right: 16px;
}

.pdg-btm16 {
  padding-bottom: 16px;
}

.pdg-lft16 {
  padding-left: 16px;
}

.pdg-17 {
  padding: 17px;
}

.pdg-top17 {
  padding-top: 17px;
}

.pdg-rgt17 {
  padding-right: 17px;
}

.pdg-btm17 {
  padding-bottom: 17px;
}

.pdg-lft17 {
  padding-left: 17px;
}

.pdg-18 {
  padding: 18px;
}

.pdg-top18 {
  padding-top: 18px;
}

.pdg-rgt18 {
  padding-right: 18px;
}

.pdg-btm18 {
  padding-bottom: 18px;
}

.pdg-lft18 {
  padding-left: 18px;
}

.pdg-19 {
  padding: 19px;
}

.pdg-top19 {
  padding-top: 19px;
}

.pdg-rgt19 {
  padding-right: 19px;
}

.pdg-btm19 {
  padding-bottom: 19px;
}

.pdg-lft19 {
  padding-left: 19px;
}

.pdg-20 {
  padding: 20px;
}

.pdg-top20 {
  padding-top: 20px;
}

.pdg-rgt20 {
  padding-right: 20px;
}

.pdg-btm20 {
  padding-bottom: 20px;
}

.pdg-lft20 {
  padding-left: 20px;
}

.pdg-21 {
  padding: 21px;
}

.pdg-top21 {
  padding-top: 21px;
}

.pdg-rgt21 {
  padding-right: 21px;
}

.pdg-btm21 {
  padding-bottom: 21px;
}

.pdg-lft21 {
  padding-left: 21px;
}

.pdg-22 {
  padding: 22px;
}

.pdg-top22 {
  padding-top: 22px;
}

.pdg-rgt22 {
  padding-right: 22px;
}

.pdg-btm22 {
  padding-bottom: 22px;
}

.pdg-lft22 {
  padding-left: 22px;
}

.pdg-23 {
  padding: 23px;
}

.pdg-top23 {
  padding-top: 23px;
}

.pdg-rgt23 {
  padding-right: 23px;
}

.pdg-btm23 {
  padding-bottom: 23px;
}

.pdg-lft23 {
  padding-left: 23px;
}

.pdg-24 {
  padding: 24px;
}

.pdg-top24 {
  padding-top: 24px;
}

.pdg-rgt24 {
  padding-right: 24px;
}

.pdg-btm24 {
  padding-bottom: 24px;
}

.pdg-lft24 {
  padding-left: 24px;
}

.pdg-25 {
  padding: 25px;
}

.pdg-top25 {
  padding-top: 25px;
}

.pdg-rgt25 {
  padding-right: 25px;
}

.pdg-btm25 {
  padding-bottom: 25px;
}

.pdg-lft25 {
  padding-left: 25px;
}

.pdg-26 {
  padding: 26px;
}

.pdg-top26 {
  padding-top: 26px;
}

.pdg-rgt26 {
  padding-right: 26px;
}

.pdg-btm26 {
  padding-bottom: 26px;
}

.pdg-lft26 {
  padding-left: 26px;
}

.pdg-27 {
  padding: 27px;
}

.pdg-top27 {
  padding-top: 27px;
}

.pdg-rgt27 {
  padding-right: 27px;
}

.pdg-btm27 {
  padding-bottom: 27px;
}

.pdg-lft27 {
  padding-left: 27px;
}

.pdg-28 {
  padding: 28px;
}

.pdg-top28 {
  padding-top: 28px;
}

.pdg-rgt28 {
  padding-right: 28px;
}

.pdg-btm28 {
  padding-bottom: 28px;
}

.pdg-lft28 {
  padding-left: 28px;
}

.pdg-29 {
  padding: 29px;
}

.pdg-top29 {
  padding-top: 29px;
}

.pdg-rgt29 {
  padding-right: 29px;
}

.pdg-btm29 {
  padding-bottom: 29px;
}

.pdg-lft29 {
  padding-left: 29px;
}

.pdg-30 {
  padding: 30px;
}

.pdg-top30 {
  padding-top: 30px;
}

.pdg-rgt30 {
  padding-right: 30px;
}

.pdg-btm30 {
  padding-bottom: 30px;
}

.pdg-lft30 {
  padding-left: 30px;
}

.pdg-31 {
  padding: 31px;
}

.pdg-top31 {
  padding-top: 31px;
}

.pdg-rgt31 {
  padding-right: 31px;
}

.pdg-btm31 {
  padding-bottom: 31px;
}

.pdg-lft31 {
  padding-left: 31px;
}

.pdg-32 {
  padding: 32px;
}

.pdg-top32 {
  padding-top: 32px;
}

.pdg-rgt32 {
  padding-right: 32px;
}

.pdg-btm32 {
  padding-bottom: 32px;
}

.pdg-lft32 {
  padding-left: 32px;
}

.pdg-33 {
  padding: 33px;
}

.pdg-top33 {
  padding-top: 33px;
}

.pdg-rgt33 {
  padding-right: 33px;
}

.pdg-btm33 {
  padding-bottom: 33px;
}

.pdg-lft33 {
  padding-left: 33px;
}

.pdg-34 {
  padding: 34px;
}

.pdg-top34 {
  padding-top: 34px;
}

.pdg-rgt34 {
  padding-right: 34px;
}

.pdg-btm34 {
  padding-bottom: 34px;
}

.pdg-lft34 {
  padding-left: 34px;
}

.pdg-35 {
  padding: 35px;
}

.pdg-top35 {
  padding-top: 35px;
}

.pdg-rgt35 {
  padding-right: 35px;
}

.pdg-btm35 {
  padding-bottom: 35px;
}

.pdg-lft35 {
  padding-left: 35px;
}

.pdg-36 {
  padding: 36px;
}

.pdg-top36 {
  padding-top: 36px;
}

.pdg-rgt36 {
  padding-right: 36px;
}

.pdg-btm36 {
  padding-bottom: 36px;
}

.pdg-lft36 {
  padding-left: 36px;
}

.pdg-37 {
  padding: 37px;
}

.pdg-top37 {
  padding-top: 37px;
}

.pdg-rgt37 {
  padding-right: 37px;
}

.pdg-btm37 {
  padding-bottom: 37px;
}

.pdg-lft37 {
  padding-left: 37px;
}

.pdg-38 {
  padding: 38px;
}

.pdg-top38 {
  padding-top: 38px;
}

.pdg-rgt38 {
  padding-right: 38px;
}

.pdg-btm38 {
  padding-bottom: 38px;
}

.pdg-lft38 {
  padding-left: 38px;
}

.pdg-39 {
  padding: 39px;
}

.pdg-top39 {
  padding-top: 39px;
}

.pdg-rgt39 {
  padding-right: 39px;
}

.pdg-btm39 {
  padding-bottom: 39px;
}

.pdg-lft39 {
  padding-left: 39px;
}

.pdg-40 {
  padding: 40px;
}

.pdg-top40 {
  padding-top: 40px;
}

.pdg-rgt40 {
  padding-right: 40px;
}

.pdg-btm40 {
  padding-bottom: 40px;
}

.pdg-lft40 {
  padding-left: 40px;
}

.pdg-41 {
  padding: 41px;
}

.pdg-top41 {
  padding-top: 41px;
}

.pdg-rgt41 {
  padding-right: 41px;
}

.pdg-btm41 {
  padding-bottom: 41px;
}

.pdg-lft41 {
  padding-left: 41px;
}

.pdg-42 {
  padding: 42px;
}

.pdg-top42 {
  padding-top: 42px;
}

.pdg-rgt42 {
  padding-right: 42px;
}

.pdg-btm42 {
  padding-bottom: 42px;
}

.pdg-lft42 {
  padding-left: 42px;
}

.pdg-43 {
  padding: 43px;
}

.pdg-top43 {
  padding-top: 43px;
}

.pdg-rgt43 {
  padding-right: 43px;
}

.pdg-btm43 {
  padding-bottom: 43px;
}

.pdg-lft43 {
  padding-left: 43px;
}

.pdg-44 {
  padding: 44px;
}

.pdg-top44 {
  padding-top: 44px;
}

.pdg-rgt44 {
  padding-right: 44px;
}

.pdg-btm44 {
  padding-bottom: 44px;
}

.pdg-lft44 {
  padding-left: 44px;
}

.pdg-45 {
  padding: 45px;
}

.pdg-top45 {
  padding-top: 45px;
}

.pdg-rgt45 {
  padding-right: 45px;
}

.pdg-btm45 {
  padding-bottom: 45px;
}

.pdg-lft45 {
  padding-left: 45px;
}

.pdg-46 {
  padding: 46px;
}

.pdg-top46 {
  padding-top: 46px;
}

.pdg-rgt46 {
  padding-right: 46px;
}

.pdg-btm46 {
  padding-bottom: 46px;
}

.pdg-lft46 {
  padding-left: 46px;
}

.pdg-47 {
  padding: 47px;
}

.pdg-top47 {
  padding-top: 47px;
}

.pdg-rgt47 {
  padding-right: 47px;
}

.pdg-btm47 {
  padding-bottom: 47px;
}

.pdg-lft47 {
  padding-left: 47px;
}

.pdg-48 {
  padding: 48px;
}

.pdg-top48 {
  padding-top: 48px;
}

.pdg-rgt48 {
  padding-right: 48px;
}

.pdg-btm48 {
  padding-bottom: 48px;
}

.pdg-lft48 {
  padding-left: 48px;
}

.pdg-49 {
  padding: 49px;
}

.pdg-top49 {
  padding-top: 49px;
}

.pdg-rgt49 {
  padding-right: 49px;
}

.pdg-btm49 {
  padding-bottom: 49px;
}

.pdg-lft49 {
  padding-left: 49px;
}

.pdg-50 {
  padding: 50px;
}

.pdg-top50 {
  padding-top: 50px;
}

.pdg-rgt50 {
  padding-right: 50px;
}

.pdg-btm50 {
  padding-bottom: 50px;
}

.pdg-lft50 {
  padding-left: 50px;
}

.pdg-51 {
  padding: 51px;
}

.pdg-top51 {
  padding-top: 51px;
}

.pdg-rgt51 {
  padding-right: 51px;
}

.pdg-btm51 {
  padding-bottom: 51px;
}

.pdg-lft51 {
  padding-left: 51px;
}

.pdg-52 {
  padding: 52px;
}

.pdg-top52 {
  padding-top: 52px;
}

.pdg-rgt52 {
  padding-right: 52px;
}

.pdg-btm52 {
  padding-bottom: 52px;
}

.pdg-lft52 {
  padding-left: 52px;
}

.pdg-53 {
  padding: 53px;
}

.pdg-top53 {
  padding-top: 53px;
}

.pdg-rgt53 {
  padding-right: 53px;
}

.pdg-btm53 {
  padding-bottom: 53px;
}

.pdg-lft53 {
  padding-left: 53px;
}

.pdg-54 {
  padding: 54px;
}

.pdg-top54 {
  padding-top: 54px;
}

.pdg-rgt54 {
  padding-right: 54px;
}

.pdg-btm54 {
  padding-bottom: 54px;
}

.pdg-lft54 {
  padding-left: 54px;
}

.pdg-55 {
  padding: 55px;
}

.pdg-top55 {
  padding-top: 55px;
}

.pdg-rgt55 {
  padding-right: 55px;
}

.pdg-btm55 {
  padding-bottom: 55px;
}

.pdg-lft55 {
  padding-left: 55px;
}

.pdg-56 {
  padding: 56px;
}

.pdg-top56 {
  padding-top: 56px;
}

.pdg-rgt56 {
  padding-right: 56px;
}

.pdg-btm56 {
  padding-bottom: 56px;
}

.pdg-lft56 {
  padding-left: 56px;
}

.pdg-57 {
  padding: 57px;
}

.pdg-top57 {
  padding-top: 57px;
}

.pdg-rgt57 {
  padding-right: 57px;
}

.pdg-btm57 {
  padding-bottom: 57px;
}

.pdg-lft57 {
  padding-left: 57px;
}

.pdg-58 {
  padding: 58px;
}

.pdg-top58 {
  padding-top: 58px;
}

.pdg-rgt58 {
  padding-right: 58px;
}

.pdg-btm58 {
  padding-bottom: 58px;
}

.pdg-lft58 {
  padding-left: 58px;
}

.pdg-59 {
  padding: 59px;
}

.pdg-top59 {
  padding-top: 59px;
}

.pdg-rgt59 {
  padding-right: 59px;
}

.pdg-btm59 {
  padding-bottom: 59px;
}

.pdg-lft59 {
  padding-left: 59px;
}

.pdg-60 {
  padding: 60px;
}

.pdg-top60 {
  padding-top: 60px;
}

.pdg-rgt60 {
  padding-right: 60px;
}

.pdg-btm60 {
  padding-bottom: 60px;
}

.pdg-lft60 {
  padding-left: 60px;
}

.pdg-61 {
  padding: 61px;
}

.pdg-top61 {
  padding-top: 61px;
}

.pdg-rgt61 {
  padding-right: 61px;
}

.pdg-btm61 {
  padding-bottom: 61px;
}

.pdg-lft61 {
  padding-left: 61px;
}

.pdg-62 {
  padding: 62px;
}

.pdg-top62 {
  padding-top: 62px;
}

.pdg-rgt62 {
  padding-right: 62px;
}

.pdg-btm62 {
  padding-bottom: 62px;
}

.pdg-lft62 {
  padding-left: 62px;
}

.pdg-63 {
  padding: 63px;
}

.pdg-top63 {
  padding-top: 63px;
}

.pdg-rgt63 {
  padding-right: 63px;
}

.pdg-btm63 {
  padding-bottom: 63px;
}

.pdg-lft63 {
  padding-left: 63px;
}

.pdg-64 {
  padding: 64px;
}

.pdg-top64 {
  padding-top: 64px;
}

.pdg-rgt64 {
  padding-right: 64px;
}

.pdg-btm64 {
  padding-bottom: 64px;
}

.pdg-lft64 {
  padding-left: 64px;
}

.pdg-65 {
  padding: 65px;
}

.pdg-top65 {
  padding-top: 65px;
}

.pdg-rgt65 {
  padding-right: 65px;
}

.pdg-btm65 {
  padding-bottom: 65px;
}

.pdg-lft65 {
  padding-left: 65px;
}

.pdg-66 {
  padding: 66px;
}

.pdg-top66 {
  padding-top: 66px;
}

.pdg-rgt66 {
  padding-right: 66px;
}

.pdg-btm66 {
  padding-bottom: 66px;
}

.pdg-lft66 {
  padding-left: 66px;
}

.pdg-67 {
  padding: 67px;
}

.pdg-top67 {
  padding-top: 67px;
}

.pdg-rgt67 {
  padding-right: 67px;
}

.pdg-btm67 {
  padding-bottom: 67px;
}

.pdg-lft67 {
  padding-left: 67px;
}

.pdg-68 {
  padding: 68px;
}

.pdg-top68 {
  padding-top: 68px;
}

.pdg-rgt68 {
  padding-right: 68px;
}

.pdg-btm68 {
  padding-bottom: 68px;
}

.pdg-lft68 {
  padding-left: 68px;
}

.pdg-69 {
  padding: 69px;
}

.pdg-top69 {
  padding-top: 69px;
}

.pdg-rgt69 {
  padding-right: 69px;
}

.pdg-btm69 {
  padding-bottom: 69px;
}

.pdg-lft69 {
  padding-left: 69px;
}

.pdg-70 {
  padding: 70px;
}

.pdg-top70 {
  padding-top: 70px;
}

.pdg-rgt70 {
  padding-right: 70px;
}

.pdg-btm70 {
  padding-bottom: 70px;
}

.pdg-lft70 {
  padding-left: 70px;
}

.pdg-71 {
  padding: 71px;
}

.pdg-top71 {
  padding-top: 71px;
}

.pdg-rgt71 {
  padding-right: 71px;
}

.pdg-btm71 {
  padding-bottom: 71px;
}

.pdg-lft71 {
  padding-left: 71px;
}

.pdg-72 {
  padding: 72px;
}

.pdg-top72 {
  padding-top: 72px;
}

.pdg-rgt72 {
  padding-right: 72px;
}

.pdg-btm72 {
  padding-bottom: 72px;
}

.pdg-lft72 {
  padding-left: 72px;
}

.pdg-73 {
  padding: 73px;
}

.pdg-top73 {
  padding-top: 73px;
}

.pdg-rgt73 {
  padding-right: 73px;
}

.pdg-btm73 {
  padding-bottom: 73px;
}

.pdg-lft73 {
  padding-left: 73px;
}

.pdg-74 {
  padding: 74px;
}

.pdg-top74 {
  padding-top: 74px;
}

.pdg-rgt74 {
  padding-right: 74px;
}

.pdg-btm74 {
  padding-bottom: 74px;
}

.pdg-lft74 {
  padding-left: 74px;
}

.pdg-75 {
  padding: 75px;
}

.pdg-top75 {
  padding-top: 75px;
}

.pdg-rgt75 {
  padding-right: 75px;
}

.pdg-btm75 {
  padding-bottom: 75px;
}

.pdg-lft75 {
  padding-left: 75px;
}

.pdg-76 {
  padding: 76px;
}

.pdg-top76 {
  padding-top: 76px;
}

.pdg-rgt76 {
  padding-right: 76px;
}

.pdg-btm76 {
  padding-bottom: 76px;
}

.pdg-lft76 {
  padding-left: 76px;
}

.pdg-77 {
  padding: 77px;
}

.pdg-top77 {
  padding-top: 77px;
}

.pdg-rgt77 {
  padding-right: 77px;
}

.pdg-btm77 {
  padding-bottom: 77px;
}

.pdg-lft77 {
  padding-left: 77px;
}

.pdg-78 {
  padding: 78px;
}

.pdg-top78 {
  padding-top: 78px;
}

.pdg-rgt78 {
  padding-right: 78px;
}

.pdg-btm78 {
  padding-bottom: 78px;
}

.pdg-lft78 {
  padding-left: 78px;
}

.pdg-79 {
  padding: 79px;
}

.pdg-top79 {
  padding-top: 79px;
}

.pdg-rgt79 {
  padding-right: 79px;
}

.pdg-btm79 {
  padding-bottom: 79px;
}

.pdg-lft79 {
  padding-left: 79px;
}

.pdg-80 {
  padding: 80px;
}

.pdg-top80 {
  padding-top: 80px;
}

.pdg-rgt80 {
  padding-right: 80px;
}

.pdg-btm80 {
  padding-bottom: 80px;
}

.pdg-lft80 {
  padding-left: 80px;
}

.pdg-81 {
  padding: 81px;
}

.pdg-top81 {
  padding-top: 81px;
}

.pdg-rgt81 {
  padding-right: 81px;
}

.pdg-btm81 {
  padding-bottom: 81px;
}

.pdg-lft81 {
  padding-left: 81px;
}

.pdg-82 {
  padding: 82px;
}

.pdg-top82 {
  padding-top: 82px;
}

.pdg-rgt82 {
  padding-right: 82px;
}

.pdg-btm82 {
  padding-bottom: 82px;
}

.pdg-lft82 {
  padding-left: 82px;
}

.pdg-83 {
  padding: 83px;
}

.pdg-top83 {
  padding-top: 83px;
}

.pdg-rgt83 {
  padding-right: 83px;
}

.pdg-btm83 {
  padding-bottom: 83px;
}

.pdg-lft83 {
  padding-left: 83px;
}

.pdg-84 {
  padding: 84px;
}

.pdg-top84 {
  padding-top: 84px;
}

.pdg-rgt84 {
  padding-right: 84px;
}

.pdg-btm84 {
  padding-bottom: 84px;
}

.pdg-lft84 {
  padding-left: 84px;
}

.pdg-85 {
  padding: 85px;
}

.pdg-top85 {
  padding-top: 85px;
}

.pdg-rgt85 {
  padding-right: 85px;
}

.pdg-btm85 {
  padding-bottom: 85px;
}

.pdg-lft85 {
  padding-left: 85px;
}

.pdg-86 {
  padding: 86px;
}

.pdg-top86 {
  padding-top: 86px;
}

.pdg-rgt86 {
  padding-right: 86px;
}

.pdg-btm86 {
  padding-bottom: 86px;
}

.pdg-lft86 {
  padding-left: 86px;
}

.pdg-87 {
  padding: 87px;
}

.pdg-top87 {
  padding-top: 87px;
}

.pdg-rgt87 {
  padding-right: 87px;
}

.pdg-btm87 {
  padding-bottom: 87px;
}

.pdg-lft87 {
  padding-left: 87px;
}

.pdg-88 {
  padding: 88px;
}

.pdg-top88 {
  padding-top: 88px;
}

.pdg-rgt88 {
  padding-right: 88px;
}

.pdg-btm88 {
  padding-bottom: 88px;
}

.pdg-lft88 {
  padding-left: 88px;
}

.pdg-89 {
  padding: 89px;
}

.pdg-top89 {
  padding-top: 89px;
}

.pdg-rgt89 {
  padding-right: 89px;
}

.pdg-btm89 {
  padding-bottom: 89px;
}

.pdg-lft89 {
  padding-left: 89px;
}

.pdg-90 {
  padding: 90px;
}

.pdg-top90 {
  padding-top: 90px;
}

.pdg-rgt90 {
  padding-right: 90px;
}

.pdg-btm90 {
  padding-bottom: 90px;
}

.pdg-lft90 {
  padding-left: 90px;
}

.pdg-91 {
  padding: 91px;
}

.pdg-top91 {
  padding-top: 91px;
}

.pdg-rgt91 {
  padding-right: 91px;
}

.pdg-btm91 {
  padding-bottom: 91px;
}

.pdg-lft91 {
  padding-left: 91px;
}

.pdg-92 {
  padding: 92px;
}

.pdg-top92 {
  padding-top: 92px;
}

.pdg-rgt92 {
  padding-right: 92px;
}

.pdg-btm92 {
  padding-bottom: 92px;
}

.pdg-lft92 {
  padding-left: 92px;
}

.pdg-93 {
  padding: 93px;
}

.pdg-top93 {
  padding-top: 93px;
}

.pdg-rgt93 {
  padding-right: 93px;
}

.pdg-btm93 {
  padding-bottom: 93px;
}

.pdg-lft93 {
  padding-left: 93px;
}

.pdg-94 {
  padding: 94px;
}

.pdg-top94 {
  padding-top: 94px;
}

.pdg-rgt94 {
  padding-right: 94px;
}

.pdg-btm94 {
  padding-bottom: 94px;
}

.pdg-lft94 {
  padding-left: 94px;
}

.pdg-95 {
  padding: 95px;
}

.pdg-top95 {
  padding-top: 95px;
}

.pdg-rgt95 {
  padding-right: 95px;
}

.pdg-btm95 {
  padding-bottom: 95px;
}

.pdg-lft95 {
  padding-left: 95px;
}

.pdg-96 {
  padding: 96px;
}

.pdg-top96 {
  padding-top: 96px;
}

.pdg-rgt96 {
  padding-right: 96px;
}

.pdg-btm96 {
  padding-bottom: 96px;
}

.pdg-lft96 {
  padding-left: 96px;
}

.pdg-97 {
  padding: 97px;
}

.pdg-top97 {
  padding-top: 97px;
}

.pdg-rgt97 {
  padding-right: 97px;
}

.pdg-btm97 {
  padding-bottom: 97px;
}

.pdg-lft97 {
  padding-left: 97px;
}

.pdg-98 {
  padding: 98px;
}

.pdg-top98 {
  padding-top: 98px;
}

.pdg-rgt98 {
  padding-right: 98px;
}

.pdg-btm98 {
  padding-bottom: 98px;
}

.pdg-lft98 {
  padding-left: 98px;
}

.pdg-99 {
  padding: 99px;
}

.pdg-top99 {
  padding-top: 99px;
}

.pdg-rgt99 {
  padding-right: 99px;
}

.pdg-btm99 {
  padding-bottom: 99px;
}

.pdg-lft99 {
  padding-left: 99px;
}

.pdg-100 {
  padding: 100px;
}

.pdg-top100 {
  padding-top: 100px;
}

.pdg-rgt100 {
  padding-right: 100px;
}

.pdg-btm100 {
  padding-bottom: 100px;
}

.pdg-lft100 {
  padding-left: 100px;
}

.pdg-101 {
  padding: 101px;
}

.pdg-top101 {
  padding-top: 101px;
}

.pdg-rgt101 {
  padding-right: 101px;
}

.pdg-btm101 {
  padding-bottom: 101px;
}

.pdg-lft101 {
  padding-left: 101px;
}

.pdg-102 {
  padding: 102px;
}

.pdg-top102 {
  padding-top: 102px;
}

.pdg-rgt102 {
  padding-right: 102px;
}

.pdg-btm102 {
  padding-bottom: 102px;
}

.pdg-lft102 {
  padding-left: 102px;
}

.pdg-103 {
  padding: 103px;
}

.pdg-top103 {
  padding-top: 103px;
}

.pdg-rgt103 {
  padding-right: 103px;
}

.pdg-btm103 {
  padding-bottom: 103px;
}

.pdg-lft103 {
  padding-left: 103px;
}

.pdg-104 {
  padding: 104px;
}

.pdg-top104 {
  padding-top: 104px;
}

.pdg-rgt104 {
  padding-right: 104px;
}

.pdg-btm104 {
  padding-bottom: 104px;
}

.pdg-lft104 {
  padding-left: 104px;
}

.pdg-105 {
  padding: 105px;
}

.pdg-top105 {
  padding-top: 105px;
}

.pdg-rgt105 {
  padding-right: 105px;
}

.pdg-btm105 {
  padding-bottom: 105px;
}

.pdg-lft105 {
  padding-left: 105px;
}

.pdg-106 {
  padding: 106px;
}

.pdg-top106 {
  padding-top: 106px;
}

.pdg-rgt106 {
  padding-right: 106px;
}

.pdg-btm106 {
  padding-bottom: 106px;
}

.pdg-lft106 {
  padding-left: 106px;
}

.pdg-107 {
  padding: 107px;
}

.pdg-top107 {
  padding-top: 107px;
}

.pdg-rgt107 {
  padding-right: 107px;
}

.pdg-btm107 {
  padding-bottom: 107px;
}

.pdg-lft107 {
  padding-left: 107px;
}

.pdg-108 {
  padding: 108px;
}

.pdg-top108 {
  padding-top: 108px;
}

.pdg-rgt108 {
  padding-right: 108px;
}

.pdg-btm108 {
  padding-bottom: 108px;
}

.pdg-lft108 {
  padding-left: 108px;
}

.pdg-109 {
  padding: 109px;
}

.pdg-top109 {
  padding-top: 109px;
}

.pdg-rgt109 {
  padding-right: 109px;
}

.pdg-btm109 {
  padding-bottom: 109px;
}

.pdg-lft109 {
  padding-left: 109px;
}

.pdg-110 {
  padding: 110px;
}

.pdg-top110 {
  padding-top: 110px;
}

.pdg-rgt110 {
  padding-right: 110px;
}

.pdg-btm110 {
  padding-bottom: 110px;
}

.pdg-lft110 {
  padding-left: 110px;
}

.pdg-111 {
  padding: 111px;
}

.pdg-top111 {
  padding-top: 111px;
}

.pdg-rgt111 {
  padding-right: 111px;
}

.pdg-btm111 {
  padding-bottom: 111px;
}

.pdg-lft111 {
  padding-left: 111px;
}

.pdg-112 {
  padding: 112px;
}

.pdg-top112 {
  padding-top: 112px;
}

.pdg-rgt112 {
  padding-right: 112px;
}

.pdg-btm112 {
  padding-bottom: 112px;
}

.pdg-lft112 {
  padding-left: 112px;
}

.pdg-113 {
  padding: 113px;
}

.pdg-top113 {
  padding-top: 113px;
}

.pdg-rgt113 {
  padding-right: 113px;
}

.pdg-btm113 {
  padding-bottom: 113px;
}

.pdg-lft113 {
  padding-left: 113px;
}

.pdg-114 {
  padding: 114px;
}

.pdg-top114 {
  padding-top: 114px;
}

.pdg-rgt114 {
  padding-right: 114px;
}

.pdg-btm114 {
  padding-bottom: 114px;
}

.pdg-lft114 {
  padding-left: 114px;
}

.pdg-115 {
  padding: 115px;
}

.pdg-top115 {
  padding-top: 115px;
}

.pdg-rgt115 {
  padding-right: 115px;
}

.pdg-btm115 {
  padding-bottom: 115px;
}

.pdg-lft115 {
  padding-left: 115px;
}

.pdg-116 {
  padding: 116px;
}

.pdg-top116 {
  padding-top: 116px;
}

.pdg-rgt116 {
  padding-right: 116px;
}

.pdg-btm116 {
  padding-bottom: 116px;
}

.pdg-lft116 {
  padding-left: 116px;
}

.pdg-117 {
  padding: 117px;
}

.pdg-top117 {
  padding-top: 117px;
}

.pdg-rgt117 {
  padding-right: 117px;
}

.pdg-btm117 {
  padding-bottom: 117px;
}

.pdg-lft117 {
  padding-left: 117px;
}

.pdg-118 {
  padding: 118px;
}

.pdg-top118 {
  padding-top: 118px;
}

.pdg-rgt118 {
  padding-right: 118px;
}

.pdg-btm118 {
  padding-bottom: 118px;
}

.pdg-lft118 {
  padding-left: 118px;
}

.pdg-119 {
  padding: 119px;
}

.pdg-top119 {
  padding-top: 119px;
}

.pdg-rgt119 {
  padding-right: 119px;
}

.pdg-btm119 {
  padding-bottom: 119px;
}

.pdg-lft119 {
  padding-left: 119px;
}

.pdg-120 {
  padding: 120px;
}

.pdg-top120 {
  padding-top: 120px;
}

.pdg-rgt120 {
  padding-right: 120px;
}

.pdg-btm120 {
  padding-bottom: 120px;
}

.pdg-lft120 {
  padding-left: 120px;
}

.pdg-121 {
  padding: 121px;
}

.pdg-top121 {
  padding-top: 121px;
}

.pdg-rgt121 {
  padding-right: 121px;
}

.pdg-btm121 {
  padding-bottom: 121px;
}

.pdg-lft121 {
  padding-left: 121px;
}

.pdg-122 {
  padding: 122px;
}

.pdg-top122 {
  padding-top: 122px;
}

.pdg-rgt122 {
  padding-right: 122px;
}

.pdg-btm122 {
  padding-bottom: 122px;
}

.pdg-lft122 {
  padding-left: 122px;
}

.pdg-123 {
  padding: 123px;
}

.pdg-top123 {
  padding-top: 123px;
}

.pdg-rgt123 {
  padding-right: 123px;
}

.pdg-btm123 {
  padding-bottom: 123px;
}

.pdg-lft123 {
  padding-left: 123px;
}

.pdg-124 {
  padding: 124px;
}

.pdg-top124 {
  padding-top: 124px;
}

.pdg-rgt124 {
  padding-right: 124px;
}

.pdg-btm124 {
  padding-bottom: 124px;
}

.pdg-lft124 {
  padding-left: 124px;
}

.pdg-125 {
  padding: 125px;
}

.pdg-top125 {
  padding-top: 125px;
}

.pdg-rgt125 {
  padding-right: 125px;
}

.pdg-btm125 {
  padding-bottom: 125px;
}

.pdg-lft125 {
  padding-left: 125px;
}

.pdg-126 {
  padding: 126px;
}

.pdg-top126 {
  padding-top: 126px;
}

.pdg-rgt126 {
  padding-right: 126px;
}

.pdg-btm126 {
  padding-bottom: 126px;
}

.pdg-lft126 {
  padding-left: 126px;
}

.pdg-127 {
  padding: 127px;
}

.pdg-top127 {
  padding-top: 127px;
}

.pdg-rgt127 {
  padding-right: 127px;
}

.pdg-btm127 {
  padding-bottom: 127px;
}

.pdg-lft127 {
  padding-left: 127px;
}

.pdg-128 {
  padding: 128px;
}

.pdg-top128 {
  padding-top: 128px;
}

.pdg-rgt128 {
  padding-right: 128px;
}

.pdg-btm128 {
  padding-bottom: 128px;
}

.pdg-lft128 {
  padding-left: 128px;
}

.pdg-129 {
  padding: 129px;
}

.pdg-top129 {
  padding-top: 129px;
}

.pdg-rgt129 {
  padding-right: 129px;
}

.pdg-btm129 {
  padding-bottom: 129px;
}

.pdg-lft129 {
  padding-left: 129px;
}

.pdg-130 {
  padding: 130px;
}

.pdg-top130 {
  padding-top: 130px;
}

.pdg-rgt130 {
  padding-right: 130px;
}

.pdg-btm130 {
  padding-bottom: 130px;
}

.pdg-lft130 {
  padding-left: 130px;
}

.pdg-131 {
  padding: 131px;
}

.pdg-top131 {
  padding-top: 131px;
}

.pdg-rgt131 {
  padding-right: 131px;
}

.pdg-btm131 {
  padding-bottom: 131px;
}

.pdg-lft131 {
  padding-left: 131px;
}

.pdg-132 {
  padding: 132px;
}

.pdg-top132 {
  padding-top: 132px;
}

.pdg-rgt132 {
  padding-right: 132px;
}

.pdg-btm132 {
  padding-bottom: 132px;
}

.pdg-lft132 {
  padding-left: 132px;
}

.pdg-133 {
  padding: 133px;
}

.pdg-top133 {
  padding-top: 133px;
}

.pdg-rgt133 {
  padding-right: 133px;
}

.pdg-btm133 {
  padding-bottom: 133px;
}

.pdg-lft133 {
  padding-left: 133px;
}

.pdg-134 {
  padding: 134px;
}

.pdg-top134 {
  padding-top: 134px;
}

.pdg-rgt134 {
  padding-right: 134px;
}

.pdg-btm134 {
  padding-bottom: 134px;
}

.pdg-lft134 {
  padding-left: 134px;
}

.pdg-135 {
  padding: 135px;
}

.pdg-top135 {
  padding-top: 135px;
}

.pdg-rgt135 {
  padding-right: 135px;
}

.pdg-btm135 {
  padding-bottom: 135px;
}

.pdg-lft135 {
  padding-left: 135px;
}

.pdg-136 {
  padding: 136px;
}

.pdg-top136 {
  padding-top: 136px;
}

.pdg-rgt136 {
  padding-right: 136px;
}

.pdg-btm136 {
  padding-bottom: 136px;
}

.pdg-lft136 {
  padding-left: 136px;
}

.pdg-137 {
  padding: 137px;
}

.pdg-top137 {
  padding-top: 137px;
}

.pdg-rgt137 {
  padding-right: 137px;
}

.pdg-btm137 {
  padding-bottom: 137px;
}

.pdg-lft137 {
  padding-left: 137px;
}

.pdg-138 {
  padding: 138px;
}

.pdg-top138 {
  padding-top: 138px;
}

.pdg-rgt138 {
  padding-right: 138px;
}

.pdg-btm138 {
  padding-bottom: 138px;
}

.pdg-lft138 {
  padding-left: 138px;
}

.pdg-139 {
  padding: 139px;
}

.pdg-top139 {
  padding-top: 139px;
}

.pdg-rgt139 {
  padding-right: 139px;
}

.pdg-btm139 {
  padding-bottom: 139px;
}

.pdg-lft139 {
  padding-left: 139px;
}

.pdg-140 {
  padding: 140px;
}

.pdg-top140 {
  padding-top: 140px;
}

.pdg-rgt140 {
  padding-right: 140px;
}

.pdg-btm140 {
  padding-bottom: 140px;
}

.pdg-lft140 {
  padding-left: 140px;
}

.pdg-141 {
  padding: 141px;
}

.pdg-top141 {
  padding-top: 141px;
}

.pdg-rgt141 {
  padding-right: 141px;
}

.pdg-btm141 {
  padding-bottom: 141px;
}

.pdg-lft141 {
  padding-left: 141px;
}

.pdg-142 {
  padding: 142px;
}

.pdg-top142 {
  padding-top: 142px;
}

.pdg-rgt142 {
  padding-right: 142px;
}

.pdg-btm142 {
  padding-bottom: 142px;
}

.pdg-lft142 {
  padding-left: 142px;
}

.pdg-143 {
  padding: 143px;
}

.pdg-top143 {
  padding-top: 143px;
}

.pdg-rgt143 {
  padding-right: 143px;
}

.pdg-btm143 {
  padding-bottom: 143px;
}

.pdg-lft143 {
  padding-left: 143px;
}

.pdg-144 {
  padding: 144px;
}

.pdg-top144 {
  padding-top: 144px;
}

.pdg-rgt144 {
  padding-right: 144px;
}

.pdg-btm144 {
  padding-bottom: 144px;
}

.pdg-lft144 {
  padding-left: 144px;
}

.pdg-145 {
  padding: 145px;
}

.pdg-top145 {
  padding-top: 145px;
}

.pdg-rgt145 {
  padding-right: 145px;
}

.pdg-btm145 {
  padding-bottom: 145px;
}

.pdg-lft145 {
  padding-left: 145px;
}

.pdg-146 {
  padding: 146px;
}

.pdg-top146 {
  padding-top: 146px;
}

.pdg-rgt146 {
  padding-right: 146px;
}

.pdg-btm146 {
  padding-bottom: 146px;
}

.pdg-lft146 {
  padding-left: 146px;
}

.pdg-147 {
  padding: 147px;
}

.pdg-top147 {
  padding-top: 147px;
}

.pdg-rgt147 {
  padding-right: 147px;
}

.pdg-btm147 {
  padding-bottom: 147px;
}

.pdg-lft147 {
  padding-left: 147px;
}

.pdg-148 {
  padding: 148px;
}

.pdg-top148 {
  padding-top: 148px;
}

.pdg-rgt148 {
  padding-right: 148px;
}

.pdg-btm148 {
  padding-bottom: 148px;
}

.pdg-lft148 {
  padding-left: 148px;
}

.pdg-149 {
  padding: 149px;
}

.pdg-top149 {
  padding-top: 149px;
}

.pdg-rgt149 {
  padding-right: 149px;
}

.pdg-btm149 {
  padding-bottom: 149px;
}

.pdg-lft149 {
  padding-left: 149px;
}

.pdg-150 {
  padding: 150px;
}

.pdg-top150 {
  padding-top: 150px;
}

.pdg-rgt150 {
  padding-right: 150px;
}

.pdg-btm150 {
  padding-bottom: 150px;
}

.pdg-lft150 {
  padding-left: 150px;
}

/* --- position --- */
.pos_rel {
  position: relative;
}

.pos_ab {
  position: absolute;
}

/* --- float --- */
.flt-rgt {
  float: right;
}

.flt-lft {
  float: left;
}

/* breadcrumbs
**************************************** */
.breadcrumbs {
  padding: 16px 0;
}

.breadcrumbs a {
  color: var(--clr-main, #1d7fb7);
  text-decoration: underline;
}

.breadcrumbs a:hover {
  text-decoration: none;
}

.breadcrumbs .breadcrumb_last {
  display: table-cell;
  max-width: 280px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}

.btn > *,
.btn-2 > * {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 0.79em 1em;
  width: 100%;
  max-width: 240px;
  color: var(--btn-clr-main, #1d7fb7);
  text-align: center;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .btn > *,
.btn-2 > * {
    max-width: 100%;
  }
}
.btn > *:not(.btn-cta),
.btn-2 > *:not(.btn-cta) {
  border: 1px solid;
}
.btn > * .arrow,
.btn-2 > * .arrow {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  border: 1px solid;
}
@media screen and (max-width: 47.9375em) {
  .btn > * .arrow,
.btn-2 > * .arrow {
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 4px;
  }
}
.btn > * .arrow svg,
.btn-2 > * .arrow svg {
  max-width: 100%;
  height: auto;
}
.btn > * .arrow .cls-2,
.btn-2 > * .arrow .cls-2 {
  fill: none;
  stroke: var(--btn-clr-main, #1d7fb7);
  stroke-width: 1px;
  transition: var(--transit-default, all 0.4s ease-out);
}
.btn > *:hover, a:hover .btn > *,
.btn-2 > *:hover,
a:hover .btn-2 > * {
  color: var(--btn-clr-main-hvr, #fff);
  border-color: var(--btn-bg-main-hvr, #1d7fb7);
  background-color: var(--btn-bg-main-hvr, #1d7fb7);
}
.btn > *:hover svg, a:hover .btn > * svg,
.btn-2 > *:hover svg,
a:hover .btn-2 > * svg {
  fill: var(--btn-clr-main-hvr, #fff);
}
.btn > *:hover .arrow .cls-2, a:hover .btn > * .arrow .cls-2,
.btn-2 > *:hover .arrow .cls-2,
a:hover .btn-2 > * .arrow .cls-2 {
  stroke: var(--btn-clr-main-hvr, #fff);
}

.btn-2 > * {
  width: auto;
  max-width: none;
  padding-left: 2rem;
  padding-right: 4rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.btn-2 > *:not(.btn-cta) {
  border: none;
  border-bottom: solid 1px transparent;
}
.btn-2 > * .arrow {
  right: 0;
  transition: var(--transit-default, all 0.4s ease-out);
}
.btn-2 > *:hover, a:hover .btn-2 > * {
  color: var(--btn-clr-main, #1d7fb7);
  border-color: var(--btn-clr-main, #1d7fb7);
  background-color: transparent;
}
.btn-2 > *:hover .arrow, a:hover .btn-2 > * .arrow {
  background-color: var(--btn-clr-main, #1d7fb7);
}

.flex > .btn a {
  max-width: 100%;
}

.btn.txt-ctr a {
  margin-left: auto;
  margin-right: auto;
}

/* --- btn-next_page --- */
.btn-next_page a {
  max-width: 100%;
  text-align: left;
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #fff);
}
.btn-wh a .arrow .cls-2 {
  stroke: var(--btn-clr-wht, #fff);
}
.btn-wh a:hover {
  color: var(--btn-clr-wht-hvr, #333);
  border-color: var(--btn-bg-wht-hvr, #fff);
  background-color: var(--btn-bg-wht-hvr, #fff);
}
.btn-wh a:hover svg {
  fill: var(--btn-clr-wht-hvr, #333);
}
.btn-wh a:hover .arrow .cls-2 {
  stroke: var(--btn-clr-wht-hvr, #333);
}

.btn-wh svg {
  fill: var(--btn-clr-wht, #fff);
}

/* --- btn-cta --- */
.btn-cta a {
  color: var(--btn-clr-cta, #fff);
  border-color: var(--btn-bg-cta, #1d7fb7) !important;
  background-color: var(--btn-bg-cta, #1d7fb7);
}
.btn-cta a:hover {
  color: var(--btn-clr-cta-hvr, #fff);
  background-color: var(--btn-bg-cta-hvr, #015691);
}

@media screen and (max-width: 47.9375em) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}
/* ##############################################################################

  FORMY

############################################################################## */
/*! purgecss start ignore */
#formy_form table {
  width: 100%;
}

#formy_form th,
#formy_form td {
  padding: 16px;
  vertical-align: middle;
  border-bottom: solid 1px #eee;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th,
#formy_form td {
    display: block;
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
}

#formy_form th {
  font-weight: normal;
  white-space: nowrap;
  text-align: left;
  width: 34%;
}
@media screen and (max-width: 47.9375em) {
  #formy_form th {
    border-bottom: none;
    padding-bottom: 0;
    white-space: normal;
    font-weight: bold;
  }
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form table textarea {
  width: 100%;
  border-radius: 3px;
  vertical-align: bottom;
}

#formy_form table input[type=text],
#formy_form table input[type=email],
#formy_form table input[type=tel],
#formy_form table input[type=date],
#formy_form table input[type=password],
#formy_form select,
#formy_form textarea {
  margin: 0;
  padding: 5px 15px;
  border: 1px solid #ccc;
  font: inherit;
  font-size: 1rem;
}

#formy_form textarea {
  height: 100px;
}

#formy_form select {
  height: 40px;
}

#formy_form ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#formy_form input:hover {
  opacity: 0.7;
}

#formy_form textarea:hover {
  opacity: 0.7;
}

#formy_form input:focus {
  outline: none;
}

#formy_form .parsley-validated {
  background-color: #eee;
}

#formy_form .parsley-error {
  background-color: #fee;
}

#formy_form .parsley-success {
  background-color: #fff;
}

.help_text {
  font-size: 0.875rem; /* 14px */
  color: #999;
}

.hidden_help {
  display: none;
}

.formy_privacy div {
  overflow-y: scroll;
  height: 140px;
  border: solid 1px #ccc;
  font-size: 0.875rem; /* 14px */
  padding: 8px 16px;
}

.requiredIcon {
  background-color: #f55;
  color: #fff;
  margin: 0 0 0 1em;
  font-size: 0.75rem; /* 12px */
  padding: 2px 5px;
  border-radius: 3px;
  float: right;
}

#formy_btn {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding-top: 32px;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn {
    padding-top: 8px;
  }
}

#formy_btn input {
  font-size: inherit;
  border: none;
  cursor: pointer;
  color: #fff;
  border-radius: 3px;
  padding: 16px 32px;
  margin-bottom: 24px;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (max-width: 47.9375em) {
  #formy_btn input {
    width: 100%;
    background-size: contain;
    background-position: center;
  }
}

#formy_form ul li input[type=radio],
#formy_form ul li input[type=checkbox] {
  display: none !important;
}

#formy_form ul li label {
  position: relative;
  display: inline-block;
  padding: 0.5em 8px 0.5em 40px;
  line-height: 1.6;
  vertical-align: top;
  cursor: pointer;
}

#formy_form ul li label:hover {
  opacity: 0.7;
}

#formy_form ul li label::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border: 2px solid #788b93;
  left: 16px;
  top: 12px;
}

#formy_form ul li input[type=radio] + label::before {
  border-radius: 10px;
}

#formy_form ul li input[type=radio]:checked + label,
#formy_form ul li input[type=checkbox]:checked + label {
  color: #e75f5f;
  font-weight: bold;
}

#formy_form ul li input[type=radio]:checked + label::before,
#formy_form ul li input[type=checkbox]:checked + label::before {
  border-color: #e75f5f;
}

#formy_form ul li input[type=radio]:checked + label::after,
#formy_form ul li input[type=checkbox]:checked + label::after {
  content: "";
  width: 10px;
  height: 18px;
  top: 4px;
  left: 20px;
  border-right: 2px solid #e75f5f;
  border-bottom: 2px solid #e75f5f;
  display: block;
  position: absolute;
  z-index: 10;
  transform: rotate(45deg);
}

.formy_confirm {
  background-color: #4dbaff;
}

.formy_submit_disabled {
  background-color: #ccc;
}

#formy_btn .formy_submit_disabled:hover {
  opacity: 1;
  cursor: default;
}

#formy_btn input.autoConfirmBack {
  position: relative;
  background-color: transparent;
  color: #555;
  padding: 8px 27px;
  min-width: auto;
  font-size: 92%;
  border: 1px solid #aaa;
  border-radius: 32px;
}

.formy_send {
  background-color: #ff6600;
}

#total_required {
  padding: 16px;
  color: #f55555;
  text-align: center;
}

@media print, screen and (min-width: 768px) {
  #formy_form .confirm-message {
    text-align: center;
  }
}
@media screen and (max-width: 47.9375em) {
  #formy_form td {
    padding-top: 0;
  }
  .autoConfirmBack {
    margin-bottom: 8px;
  }
}
/*! purgecss end ignore */
/* --- anchor --- */
.anchor {
  position: absolute;
  top: -80px; /* 固定ヘッダーの高さ */
  left: 0;
  pointer-events: none;
}
@media screen and (max-width: 67.5em) {
  .anchor .anchor {
    top: -64px; /* 固定ヘッダーの高さ */
  }
}

/* contact
********************************************** */
.section-contact .cta_tel {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.section-contact .cta_tel a {
  padding: 24px 40px;
  border: 1px solid;
  background-color: var(--clr-wht, #fff);
}
@media screen and (max-width: 47.9375em) {
  .section-contact .cta_tel a {
    display: block;
    padding: 16px;
  }
}

/* flex
**************************************** */
/* --- ブロック要素 --- */
.flex {
  display: flex;
}

/* --- インライン要素 --- */
.flex-inline {
  display: inline-flex;
}

/* --- 逆向き --- */
.flex-reverse {
  flex-direction: row-reverse;
}

/* --- 縦並び --- */
.flex-column {
  flex-direction: column;
}

/* --- 水平方向揃え --- */
.flex-j-start {
  justify-content: flex-start;
}

.flex-j-end {
  justify-content: flex-end;
}

.flex-j-ctr {
  justify-content: center;
}

.flex-j-between {
  justify-content: space-between;
}

.flex-j-around {
  justify-content: space-around;
}

/* --- 垂直方向揃え --- */
.flex-a-start {
  align-items: flex-start;
}

.flex-a-end {
  align-items: flex-end;
}

.flex-a-ctr {
  align-items: center;
}

.flex-a-baseline {
  align-items: baseline;
}

.flex-a-stretch {
  align-items: stretch;
}

/* --- 子要素の折り返し設定 --- */
.flex-c-nowrap {
  flex-wrap: nowrap;
}

.flex-c-wrap {
  flex-wrap: wrap;
}

/* --- 子要素の複数行設定 --- */
.flex-c-reverse {
  flex-wrap: wrap-reverse;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-start {
  align-content: flex-start;
}

.flex-c-end {
  align-content: flex-end;
}

.flex-c-ctr {
  align-content: center;
}

.flex-c-baseline {
  align-content: baseline;
}

.flex-c-stretch {
  align-content: stretch;
}

@media screen and (max-width: 47.9375em) {
  /* --- 縦並び - sp --- */
  .flex-sp-block {
    flex-direction: column;
  }
}
.flex-col2,
.flex-col3,
.flex-col4 {
  flex-wrap: wrap;
}

.flex-col1 > * {
  width: 100%;
}

.flex-col2 > * {
  width: 50%;
}

.flex-col3 > * {
  width: 33.3333333333%;
}

.flex-col4 > * {
  width: 25%;
}

.flex-col2.gap {
  gap: 4%;
}

.flex-col3.gap {
  gap: 3%;
}

.flex-col4.gap {
  gap: 2%;
}

.flex-col2.gap > * {
  width: 48%;
  margin-bottom: 4%;
}

.flex-col3.gap > * {
  width: 31.33%;
  margin-bottom: 3%;
}

.flex-col4.gap > * {
  width: 23.5%;
  margin-bottom: 2%;
}

@media screen and (min-width: 48em) {
  .flex-col2.gap-wide {
    gap: 6%;
  }
  .flex-col3.gap-wide {
    gap: 5%;
  }
  .flex-col4.gap-wide {
    gap: 4%;
  }
  .flex-col2.gap-wide > * {
    width: 47%;
    margin-bottom: 6%;
  }
  .flex-col3.gap-wide > * {
    width: 30%;
    margin-bottom: 5%;
  }
  .flex-col4.gap-wide > * {
    width: 22%;
    margin-bottom: 4%;
  }
  .flex-col2.column-gap > *,
.flex-col3.column-gap > *,
.flex-col4.column-gap > * {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 47.9375em) {
  .flex-col2.flex-sp-block > *,
.flex-col3.flex-sp-block > *,
.flex-col4.flex-sp-block > * {
    width: 100%;
  }
  .flex-col2.gap.flex-sp-block > *,
.flex-col3.gap.flex-sp-block > *,
.flex-col4.gap.flex-sp-block > * {
    margin-bottom: 24px;
  }
  .flex-col2.gap.flex-sp-block > *:last-child,
.flex-col3.gap.flex-sp-block > *:last-child,
.flex-col4.gap.flex-sp-block > *:last-child {
    margin-bottom: 0;
  }
  .flex-col4.gap.flex-sp-col3 > * {
    width: 32%;
  }
  .flex-col4.gap.flex-sp-col2 > * {
    width: 49%;
  }
  .flex-col3.gap.flex-sp-col2 > * {
    width: 48.5%;
  }
}
/* ##############################################################################

    FOOTER

############################################################################## */
/* footer_schedule
********************************************** */
.subpage .footer_schedule {
  margin-top: 40px;
}

.footer_schedule {
  padding-top: 84px;
  padding-bottom: 68px;
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .footer_schedule {
    padding-top: 42px;
    padding-bottom: 34px;
  }
}
.footer_schedule::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 30%;
  z-index: 0;
  display: block;
  width: 88.5416666667vw;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/schedule-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 67.5em) {
  .footer_schedule::before {
    width: 91%;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer_schedule::before {
    width: 93%;
    bottom: 20%;
  }
}

.footer_schedule .ttl-01 {
  position: absolute;
  z-index: 20;
  margin-bottom: 0;
  line-height: 1;
  white-space: nowrap;
}
@media screen and (max-width: 67.5em) {
  .footer_schedule .ttl-01 {
    left: 1%;
    right: auto;
  }
}
@media screen and (min-width: 48em) {
  .footer_schedule .ttl-01 {
    writing-mode: vertical-rl;
    font-feature-settings: "pkna";
    transform: rotate(180deg);
    bottom: 20%;
    right: 88.5416666667vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer_schedule .ttl-01 {
    top: -0.5em;
    left: 3%;
  }
}

@media screen and (max-width: 87.5em) {
  .footer_schedule .inner {
    margin-right: 3%;
  }
}
@media screen and (max-width: 87.5em) {
  .footer_schedule .inner {
    max-width: calc(94% - (100% - 88.5416666667vw));
  }
}
@media screen and (max-width: 67.5em) {
  .footer_schedule .inner {
    max-width: 86%;
  }
}
@media screen and (max-width: 47.9375em) {
  .footer_schedule .inner {
    max-width: 95%;
    margin-right: 0;
  }
}

.xo-event-calendar {
  font-family: "Sen", sans-serif;
  text-align: center;
}

.xo-months {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 47.9375em) {
  .xo-months {
    display: block;
  }
}

.xo-month-wrap {
  width: 32%;
  padding: 1.6666666667vw;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
}
@media screen and (max-width: 47.9375em) {
  .xo-month-wrap {
    width: 100%;
    padding: 24px;
  }
  .xo-month-wrap:last-child {
    display: none;
  }
}

.xo-event-calendar table.xo-month .month-header > span {
  padding-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-align: right;
  font-size: 1.5rem;
  letter-spacing: -0.05em;
  line-height: 1;
}

.xo-event-calendar table.xo-month .month-header > span span {
  font-size: 2.5rem;
  letter-spacing: -0.05em;
}

.xo-event-calendar table.xo-month,
.xo-event-calendar table.xo-month td,
.xo-event-calendar table.xo-month th {
  border: none;
}

.xo-event-calendar table.xo-month td,
.xo-event-calendar table.xo-month th {
  padding-top: 16px;
  padding-bottom: 16px;
}

.xo-event-calendar table.xo-month .month-dayname td {
  padding: 10px 4px;
}

.xo-event-calendar table.xo-month .month-dayname td div {
  height: auto;
  font-size: 1.25rem;
  letter-spacing: -0.08em;
  text-align: center;
  border-radius: 50%;
}

.xo-event-calendar table.xo-month > thead th.sunday,
.xo-event-calendar table.xo-month .month-dayname td:first-child div {
  color: #9A796F;
}

.xo-event-calendar table.xo-month > thead th.saturday,
.xo-event-calendar table.xo-month .month-dayname td:last-child div {
  color: #486E7A;
}

.xo-event-calendar table.xo-month .month-dayname td div.today {
  color: var(--clr-body, #333);
}

.xo-event-calendar p.holiday-title {
  text-align: left;
}

.xo-event-calendar p.holiday-title span {
  border-radius: 50%;
}

.xo-event-calendar p.holiday-title span {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  transform: translateY(0.4rem);
}

/* footer_reserve
********************************************** */
.footer_reserve {
  position: relative;
  z-index: 2;
  padding-top: 80px;
  padding-bottom: 100px;
  margin-bottom: -80px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/cta-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
@media screen and (max-width: 47.9375em) {
  .footer_reserve {
    padding-top: 40px;
    padding-bottom: 50px;
    margin-bottom: -40px;
  }
}

.footer_reserve .ttl-01 {
  margin-bottom: 0.75rem;
}

.reserve--cta .gnav--contact a {
  width: 240px;
  height: 64px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 1.2;
}
@media screen and (max-width: 47.9375em) {
  .reserve--cta .gnav--contact a {
    width: 100%;
    height: 68px;
    padding-top: 0.75em;
    margin-top: 8px;
  }
}

@media screen and (max-width: 47.9375em) {
  .reserve--cta .gnav--contact .font-en {
    display: inline-block;
    margin-top: 4px;
  }
}

.reserve--cta .gnav--tel a {
  width: 240px;
  height: 64px;
  border: solid 1px;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}
@media screen and (max-width: 47.9375em) {
  .reserve--cta .gnav--tel a {
    width: 100%;
  }
}
.reserve--cta .gnav--tel a::before {
  display: none;
}

@media screen and (min-width: 48em) {
  .reserve--cta .gnav--contact + .gnav--tel {
    margin-left: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .reserve--cta .gnav--contact + .gnav--tel {
    margin-top: 4px;
  }
}

/* footer_top
********************************************** */
.footer,
.footer_top {
  background-color: #393939;
  background-image: url(/wp/wp-content/themes/standard_sass/images/common/footer-bg.jpg);
  background-size: cover;
}

.footer_top {
  padding-top: 96px;
  padding-bottom: 32px;
}
@media screen and (max-width: 47.9375em) {
  .footer_top {
    padding-top: 80px;
  }
}

.footer_top .inner {
  max-width: 1200px;
}
@media screen and (max-width: 47.9375em) {
  .footer_top .inner {
    width: 76%;
  }
}

.footer--logo {
  transform: translateX(-56px);
}
@media screen and (max-width: 47.9375em) {
  .footer--logo {
    transform: translateX(-28px);
  }
}
@media screen and (max-width: 47.9375em) {
  .footer--logo img {
    width: 102px;
  }
}

@media screen and (max-width: 47.9375em) {
  .footer_top .txtarea {
    width: 100%;
  }
}

/* ---sns_area --- */
.sns_area {
  margin-top: 28px;
}
@media screen and (max-width: 47.9375em) {
  .sns_area {
    margin-top: 24px;
  }
}

.sns--list li:not(:last-child) {
  margin-right: 0.5rem;
}
@media screen and (max-width: 47.9375em) {
  .sns--list li:not(:last-child) {
    margin-right: 1rem;
  }
}

.sns--list a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .sns--list a {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.sns--list a:hover {
  opacity: 0.7;
}

.sns--list svg {
  width: 30px;
  fill: #fff;
}

/* ---fnav --- */
@media screen and (max-width: 47.9375em) {
  .fnav {
    width: 100%;
    margin-top: 40px;
  }
}

@media screen and (min-width: 48em) {
  .fnav--menu {
    margin-top: 24px;
    margin-left: 7.7083333333vw;
  }
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu {
    width: 50%;
  }
}

.fnav--menu a {
  position: relative;
  display: block;
}
@media screen and (min-width: 48em) {
  .fnav--menu a {
    margin-bottom: 0.75rem;
    padding-left: 2em;
  }
}
@media screen and (max-width: 47.9375em) {
  .fnav--menu a {
    margin-bottom: 0.5rem;
  }
}
@media screen and (min-width: 48em) {
  .fnav--menu a::before, .fnav--menu a::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    margin: auto;
    opacity: 0;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .fnav--menu a::before {
    left: 0.5em;
    width: 0.5em;
    height: 0.5em;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
  .fnav--menu a::after {
    left: 0;
    width: 1em;
    height: 1px;
    border-bottom: 1px solid;
  }
  .fnav--menu a:hover::before, .fnav--menu a:hover::after {
    opacity: 1;
  }
}

/* footer_btm
*************************************************** */
.footer_btm {
  padding: 8px 0;
  color: #fff;
  background-color: #141515;
}
@media screen and (max-width: 47.9375em) {
  .footer_btm {
    line-height: 1.5;
  }
}

.pbl a {
  opacity: 0.5;
}
.pbl a:hover {
  opacity: 1;
}

.copyright small {
  font-size: 0.625rem;
}

@media screen and (min-width: 48em) {
  .footer_btm .copyright {
    margin-right: 1.5em;
  }
}
/* ##############################################################################

    HEADER

############################################################################## */
/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 117px;
  z-index: 9999;
  transition: var(--transit-default, all 0.4s ease-out);
}
.header > .inner {
  max-width: 1274px;
  height: 100%;
}
@media screen and (max-width: 67.5em) {
  .header {
    height: 64px;
    background-color: var(--bg-wht, #fff);
  }
  .header > .inner {
    width: 100%;
    max-width: 100%;
    padding-left: 4%;
  }
}

.page-contact:not(.page-thanks) .header {
  position: absolute;
}

.header--logo {
  transition: var(--transit-default, all 0.4s ease-out);
}
@media print, screen and (min-width: 67.5625em) {
  .header--logo {
    position: absolute;
    top: 50%;
    left: 3.3333333333vw;
    transform: translateY(-55%);
  }
}

.header--logo a,
.footer--logo a {
  display: block;
}

.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

@media print, screen and (min-width: 67.5625em) {
  .home .header:not(.header-sm) .header--logo {
    transform: translateY(-30%);
  }
}

@media print, screen and (min-width: 67.5625em) {
  .header-sm {
    background-color: var(--bg-wht, #fff);
    height: 104px;
  }
}
@media screen and (max-width: 67.5em) {
  .header--logo img {
    width: auto;
    max-height: 40.5px;
  }
}
/* gnav
********************************************** */
.gnav a {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.01em;
  line-height: 1.2;
}

.gnav--link {
  position: relative;
  flex-direction: column;
  padding-left: 1.25em;
  padding-right: 1.25em;
  line-height: 1.4;
}
@media screen and (min-width: 67.5625em) and (max-width: 96.25em) {
  .gnav--link {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.gnav--link .font-en {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.menu-item-has-children {
  position: relative;
}

.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.head_sub_nav a {
  padding: 0.5em 1em;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}

.cta_tel a > span {
  display: block;
}

.cta_tel .tel {
  display: flex;
  align-items: center;
}

.cta_tel svg {
  fill: var(--clr-body, #333);
  margin-right: 0.4375rem;
}

.cta_tel .num {
  font-size: 1.875rem; /* 30px */
}

.cta_contact svg {
  fill: var(--clr-wht, #fff);
}

.gnav--cta a {
  flex-direction: column;
  letter-spacing: 0;
}

.gnav--tel .num {
  font-size: 1.5rem;
}

.gnav--tel .hours {
  font-size: 0.9375rem;
}

.gnav--tel a {
  display: flex !important;
}

@media screen and (min-width: 67.5625em) and (max-width: 79.375em) {
  .gnav .gnav--contact {
    position: absolute;
    top: 100%;
    right: 3.3333333333vw;
  }
}

.gnav--contact a {
  width: 12.1875rem;
  height: 4.5625rem;
  padding: 1em;
  line-height: 1.4;
}
@media screen and (min-width: 67.5625em) and (max-width: 96.25em) {
  .gnav--contact a {
    width: 10.5rem;
  }
}
.gnav--contact a::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 1rem;
  height: 1px;
  border-bottom: 1px solid;
}

.gnav--contact .font-en {
  position: relative;
  font-size: 1.25rem;
}

.gnav--contact svg {
  position: absolute;
  top: 50%;
  right: 1.3125rem;
  transform: translateY(-50%) rotate(-90deg);
}

.header:not(.header-sm) .gnav--contact.pc a,
.hero--contact a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 7.75rem;
  height: 7.75rem;
  height: 7.75rem;
  border-radius: 50%;
  letter-spacing: 0;
  transform: translateY(10px);
}
@media screen and (min-width: 67.5625em) and (max-width: 79.375em) {
  .header:not(.header-sm) .gnav--contact.pc a,
.hero--contact a {
    transform: translateY(-10px);
  }
}
.header:not(.header-sm) .gnav--contact.pc a::before,
.hero--contact a::before {
  display: none;
}

@media screen and (max-width: 67.5em) {
  .header .gnav--contact.pc {
    display: none;
  }
}
@media screen and (min-width: 67.5625em) {
  .header .gnav--contact.sp {
    display: none;
  }
}

.header:not(.header-sm) .gnav--contact.pc svg,
.hero--contact svg {
  position: static;
  transform: none;
  margin-bottom: 0.75rem;
}

.header:not(.header-sm) .gnav--contact.pc .font-en,
.hero--contact .font-en {
  position: relative;
  font-size: 1rem;
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.header:not(.header-sm) .gnav--contact.pc .font-en::before, .header:not(.header-sm) .gnav--contact.pc .font-en::after,
.hero--contact .font-en::before,
.hero--contact .font-en::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  height: 1em;
  border-right: 1px solid;
}
.header:not(.header-sm) .gnav--contact.pc .font-en::before,
.hero--contact .font-en::before {
  left: 0;
  transform: rotate(-20deg);
}
.header:not(.header-sm) .gnav--contact.pc .font-en::after,
.hero--contact .font-en::after {
  right: 0;
  transform: rotate(20deg);
}

@media print, screen and (min-width: 67.5625em) {
  .gnav {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .gnav_btn,
.contact_btn,
.tel_btn {
    display: none;
  }
  .gnav--menu {
    padding-right: 1.5rem;
  }
  /* ---CTA --- */
}
@media screen and (min-width: 67.5625em) and (min-width: 67.5625em) and (max-width: 96.25em) {
  .gnav--menu {
    padding-right: 0.75rem;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--link {
    text-align: center;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li > .gnav--link {
    height: 2.4375rem !important;
  }
  .gnav--menu > li > .gnav--link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2.5rem;
    height: 2.5rem;
    background-color: #C9D6DA;
    border-radius: 50%;
    z-index: -1;
    opacity: 0;
    transition: var(--transit-default, all 0.4s ease-out);
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover::before,
.gnav--menu > li.current-menu-item > .gnav--link::before {
    opacity: 1;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a {
    background-color: #fff;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav_sub_toggle {
    display: none;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav--wrap {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 12em;
    padding-top: 32px;
    z-index: 100;
    transition: var(--transit-default, all 0.4s ease-out);
    opacity: 0;
    pointer-events: none;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--tel a {
    position: relative;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    align-items: flex-end;
  }
}
@media screen and (min-width: 67.5625em) and (min-width: 67.5625em) and (max-width: 96.25em) {
  .gnav--tel a {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media screen and (min-width: 67.5625em) and (min-width: 67.5625em) and (max-width: 79.375em) {
  .gnav--tel a {
    padding-right: 0;
  }
}
@media print, screen and (min-width: 67.5625em) {
  .gnav--tel a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 2.4375rem;
    background-color: var(--clr-main, #1d7fb7);
  }
}
@media screen and (max-width: 67.5em) {
  .gnav,
.gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 70%;
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
.gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
.gnav--cta {
    display: block;
  }
  .gnav--menu {
    margin-bottom: 20px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li {
    border-bottom: 1px dotted var(--clr-main, #1d7fb7);
  }
  .gnav--menu a {
    align-items: flex-start;
    padding: 12.4px 16px;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    display: none;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
.gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
.contact_btn,
.gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    border-left: 1px solid var(--clr-main, #1d7fb7);
    z-index: 9999;
  }
  .tel_btn {
    right: 128px;
  }
  .contact_btn {
    right: 64px;
  }
  .tel_btn a,
.contact_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
    padding-bottom: 12px;
    line-height: 1;
  }
  .contact_btn a {
    color: #fff;
    background-color: var(--btn-bg-cta, #1d7fb7);
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .contact_btn svg {
    fill: var(--clr-wht, #fff);
  }
  .tel_btn .txt,
.contact_btn .txt,
.gnav_btn .txt {
    margin-top: 6px;
    font-size: 10px;
  }
  .gnav_btn {
    right: 0;
    padding-bottom: 12px;
    line-height: 1;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn.active {
    background-color: #f7f7f7;
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-main, #1d7fb7);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-8px) rotate(45deg);
  }
  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    text-align: center;
  }
  .gnav--tel .num {
    font-size: 1.625rem;
  }
  .gnav--tel .hours {
    font-size: 1rem;
  }
  .gnav--contact a {
    width: 100%;
    padding: 10px 16px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 47.9375em) {
  .gnav--menu a {
    padding: 13.1px 16px;
  }
}
/* hero
********************************************** */
.home {
  overflow: hidden;
}

.hero {
  position: relative;
  padding: 56px 2.0833333333vw 0;
}
@media screen and (max-width: 47.9375em) {
  .hero {
    padding: 28px 3.3333333333vw 0;
    margin-bottom: 80px;
  }
}
.hero::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 100;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/fv-deco.png);
  background-repeat: no-repeat;
  background-position: top 10% center;
  pointer-events: none;
  mix-blend-mode: screen;
}

.hero_bg {
  height: 584px;
}
@media screen and (max-width: 47.9375em) {
  .hero_bg {
    height: 280px;
  }
}

.hero_catch {
  position: absolute;
  top: 69%;
  right: 6.25vw;
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
@media screen and (max-width: 47.9375em) {
  .hero_catch {
    top: auto;
    right: 3%;
    bottom: -22%;
    max-width: calc(100% - 150px);
    font-size: 0.5rem;
  }
}

.hero .copyright {
  position: absolute;
  top: 30%;
  left: 2.9166666667vw;
  text-align: center;
  writing-mode: vertical-rl;
  font-feature-settings: "pkna";
}
@media screen and (max-width: 47.9375em) {
  .hero .copyright {
    display: none;
  }
}

.hero_cta {
  position: absolute;
  left: 4.6875vw;
  bottom: -170px;
  z-index: 200;
  width: 13.75rem;
  padding: 0.75rem 0.75rem 1.5rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 110px 110px 0 0;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
}
@media screen and (max-width: 47.9375em) {
  .hero_cta {
    bottom: -60%;
    left: 1%;
    transform: scale(0.6, 0.6);
    transform-origin: left bottom;
  }
}
.hero_cta::before {
  content: "";
  position: absolute;
  top: -24px;
  left: 8px;
  right: 0;
  display: block;
  height: 57px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/cta-txt.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.hero--contact a {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
  width: 12.1875rem;
  height: 12.1875rem;
  transform: translateY(0px);
}

.hero--contact svg {
  width: 18px;
  height: 24px;
}

.hero--contact .font-en {
  margin-bottom: 20px;
  font-size: 1.5rem;
  line-height: 1;
}

.hero--tel {
  margin-bottom: 8px;
}

.hero--tel .num {
  font-size: 1.5rem;
  letter-spacing: -0.04em;
}

.hero_slides {
  position: absolute;
  top: -117px;
  right: 0;
  width: 1400px;
  max-width: 80%;
}
@media screen and (max-width: 47.9375em) {
  .hero_slides {
    top: -54px;
    left: 6%;
    right: auto;
    width: 150vw;
    max-width: none;
  }
}

.hero_slide .slick-list,
.stylist_slide .slick-list {
  overflow: visible !important;
}

.hero_slide .child {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}

.hero_slide .child img {
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
  opacity: 0;
}

.hero_slide .child .img_lft {
  width: 34.28%;
  margin-top: 108px;
  transform: translateY(16px);
  animation: fade_top cubic-bezier(0.215, 0.61, 0.355, 1) 2.5s forwards;
  animation-delay: 0.5s;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide .child .img_lft {
    width: 48%;
    margin-top: 54px;
  }
}

.hero_slide .child .img_ctr {
  width: 34.28%;
  margin-top: 204px;
  transform: translateY(-16px);
  animation: fade_btm cubic-bezier(0.215, 0.61, 0.355, 1) 2.5s forwards;
  animation-delay: 0.9s;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide .child .img_ctr {
    width: 48%;
    margin-top: 90px;
  }
}

.hero_slide .child .img_rgt {
  width: 25.71%;
  transform: translateY(16px);
  animation: fade_top cubic-bezier(0.215, 0.61, 0.355, 1) 2.5s forwards;
  animation-delay: 1.3s;
}
@media screen and (max-width: 47.9375em) {
  .hero_slide .child .img_rgt {
    display: none;
  }
}

@keyframes fade_top {
  0% {
    opacity: 0;
    transform: translateY(16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fade_btm {
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.hero_slide,
.hero_slide .slick-list,
.hero_slide .slick-track,
.hero_slide .slick-slider,
.hero_slide .slick-slide {
  width: 100%;
  height: 100% !important;
}

.hero_slide .slick-list {
  position: relative;
  overflow: hidden;
}

/* home_concept
********************************************** */
.home_concept {
  padding-top: 260px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-a-blue.png), url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-o-blue.png), url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-c-blue.png), url(/wp/wp-content/themes/standard_sass/images/home/concept-deco-t-blue.png);
  background-position: top 24px right, top 40% left 23%, bottom 21% right 8%, bottom 72px left 4%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_concept {
    padding-top: 130px;
  }
}

.concept .txtarea {
  flex: 1;
  padding-left: 5%;
  padding-right: 5%;
}

.concept .txt-deco {
  font-size: 0.875rem;
  line-height: 2.28;
  letter-spacing: 0.01em;
  color: #B7C8CD;
}

.concept-01 .imgarea {
  position: relative;
  width: 50%;
  margin-right: 1%;
  padding-top: 108px;
}
@media screen and (max-width: 71.875em) {
  .concept-01 .imgarea {
    width: 45%;
  }
}
@media screen and (max-width: 47.9375em) {
  .concept-01 .imgarea {
    width: 100%;
    padding-top: 32px;
  }
}

.concept-01 .imgarea .img-01 {
  width: min(41.6666666667vw, 800px);
  height: 450px;
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 47.9375em) {
  .concept-01 .imgarea .img-01 {
    width: 90%;
    height: 240px;
  }
}

.concept-01 .imgarea .img-02 {
  position: absolute;
  bottom: -228px;
  right: min(29.6875vw, 570px);
  width: min(20.2083333333vw, 388px);
  height: 300px;
}
@media screen and (max-width: 47.9375em) {
  .concept-01 .imgarea .img-02 {
    bottom: -80px;
    width: 50%;
    height: 150px;
    right: auto;
    left: 0;
  }
}

.concept-01 .deco-01 {
  position: absolute;
  top: 40%;
  right: 8%;
  width: min(15.7291666667vw, 302px);
}
@media screen and (max-width: 47.9375em) {
  .concept-01 .deco-01 {
    top: 84%;
    right: 2%;
    width: 40%;
  }
}

.concept-02 {
  margin-top: -120px;
  margin-bottom: 134px;
}
@media screen and (max-width: 47.9375em) {
  .concept-02 {
    position: relative;
    z-index: 10;
    margin-top: 110px;
    margin-bottom: 32px;
  }
}

.concept-02 .txtarea {
  padding-left: 24%;
}
@media screen and (min-width: 125.0625em) {
  .concept-02 .txtarea {
    padding-left: 30%;
  }
}
@media screen and (max-width: 47.9375em) {
  .concept-02 .txtarea {
    padding-left: 5%;
    padding-right: 20%;
  }
}

.concept-02 .imgarea {
  position: relative;
  width: 42%;
}
@media screen and (max-width: 47.9375em) {
  .concept-02 .imgarea {
    width: 100%;
  }
}

.concept-02 .imgarea .img-03 {
  width: min(33.3333333333vw, 640px);
  height: 360px;
}
@media screen and (max-width: 47.9375em) {
  .concept-02 .imgarea .img-03 {
    width: 80%;
    height: 170px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: 0;
  }
}

.concept-02 .deco-02 {
  position: absolute;
  top: 51%;
  left: -17%;
  width: min(19.6875vw, 378px);
  mix-blend-mode: multiply;
}
@media screen and (max-width: 47.9375em) {
  .concept-02 .deco-02 {
    width: 45%;
    left: 2%;
  }
}

.concept-03 {
  margin-bottom: 140px;
}
@media screen and (max-width: 47.9375em) {
  .concept-03 {
    margin-bottom: 72px;
  }
}

.concept-03 .txtarea {
  position: relative;
  padding-top: 88px;
}
@media screen and (max-width: 47.9375em) {
  .concept-03 .txtarea {
    padding-top: 0;
  }
}

@media screen and (min-width: 48em) {
  .concept-03 .txt-deco {
    position: absolute;
    right: 20%;
    bottom: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .concept-03 .txt-deco {
    margin-top: 24px;
    padding-left: 20%;
  }
}

.concept-03 .imgarea {
  position: relative;
  width: 61.5%;
  margin-right: 1%;
}
@media screen and (max-width: 47.9375em) {
  .concept-03 .imgarea {
    width: 100%;
    margin-top: 24px;
  }
}

.concept-03 .imgarea .img-04 {
  width: min(41.6666666667vw, 800px);
  height: 480px;
  margin-left: auto;
  margin-right: 0;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
}
@media screen and (max-width: 47.9375em) {
  .concept-03 .imgarea .img-04 {
    width: 80%;
    height: 240px;
    margin-left: 0;
    margin-right: auto;
  }
}

.concept-03 .imgarea .img-05 {
  position: absolute;
  bottom: -260px;
  right: min(54.1666666667vw, 1040px);
  width: min(7.1354166667vw, 137px);
  height: 300px;
}
@media screen and (max-width: 47.9375em) {
  .concept-03 .imgarea .img-05 {
    right: 5%;
    bottom: -60px;
    width: 20%;
    height: 150px;
  }
}

@media screen and (max-width: 47.9375em) {
  .concept-04 {
    margin-top: -48px;
  }
}

.concept-04 .txtarea {
  padding-left: 10%;
}
@media screen and (max-width: 47.9375em) {
  .concept-04 .txtarea {
    padding-left: 5%;
    padding-right: 30%;
  }
}

.concept-04 .imgarea {
  position: relative;
  width: 44%;
}
@media screen and (max-width: 47.9375em) {
  .concept-04 .imgarea {
    width: 100%;
  }
}

.concept-04 .imgarea .img-06 {
  width: min(41.6666666667vw, 800px);
  height: 318px;
}
@media screen and (max-width: 47.9375em) {
  .concept-04 .imgarea .img-06 {
    width: 80%;
    height: 100px;
    margin-top: 28px;
    margin-left: auto;
    margin-right: 0;
  }
}

.concept-04 .deco-03 {
  position: absolute;
  top: -107%;
  left: 4.5%;
  z-index: -1;
  width: min(21.0416666667vw, 404px);
}
@media screen and (max-width: 47.9375em) {
  .concept-04 .deco-03 {
    top: -152%;
    left: auto;
    right: -3%;
    width: 50%;
  }
}

/* home_about
********************************************** */
.home_about {
  height: 720px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/about-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_about {
    height: auto;
  }
}

.home_about .bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 870px;
  max-width: calc(100% - 180px);
  height: 100%;
  padding-left: 108px;
  padding-right: 108px;
  margin-left: 90px;
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .home_about .bg {
    max-width: 90%;
    padding: 12% 8%;
    margin-left: 5%;
  }
}

.home_about .bg div {
  width: 100%;
}

.home_about p {
  max-width: 550px;
  margin-bottom: 2.5rem;
  letter-spacing: -0.05em;
}

/* home_menu
********************************************** */
.home_menu {
  position: relative;
  padding-top: 124px;
  padding-bottom: 110px;
}
@media screen and (max-width: 47.9375em) {
  .home_menu {
    padding-top: 62px;
    padding-bottom: 8px;
  }
}

.home_menu .bg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/menu-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.home_menu .inner {
  max-width: 1232px;
}

.home_menu .ttl-01 {
  position: absolute;
  bottom: -0.6em;
  left: 0.3em;
  z-index: 20;
  line-height: 1;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-feature-settings: "pkna";
  mix-blend-mode: normal;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .ttl-01 {
    bottom: 0.4em;
    left: -0.4em;
  }
}

.home_menu .txtarea {
  position: relative;
  z-index: 10;
  width: 56.1%;
  padding: 7% 8% 6%;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
  letter-spacing: -0.05em;
}
@media screen and (max-width: 47.9375em) {
  .home_menu .txtarea {
    width: 90%;
    padding: 8% 8% 10%;
    transform: translateY(-40px);
  }
}

.menu_slide {
  width: 43.8%;
  transform: translateX(16%);
  background-color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .menu_slide {
    width: 100%;
    transform: translateX(0);
  }
}

.menu_slide .slick-list,
.stylist_slide .slick-list,
.style_slide .slick-list {
  overflow: visible !important;
}

.menu_slide .post--img {
  height: 540px;
}
@media screen and (max-width: 47.9375em) {
  .menu_slide .post--img {
    height: 280px;
  }
}

.menu_slide .num {
  position: absolute;
  top: -4rem;
  left: -4rem;
  z-index: 10;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 3rem;
  line-height: 1;
}
@media screen and (max-width: 47.9375em) {
  .menu_slide .num {
    top: -2rem;
    left: -2rem;
  }
}

.menu_slide .num .circle {
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}

.menu_slide .num .circle:nth-child(1) {
  right: 0;
}

.menu_slide .num .circle:nth-child(2) {
  left: 0;
}

.menu_slide .num .circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid;
  transition: all 0.4s ease-out;
}

.menu_slide .num .circle:nth-child(1)::before {
  top: 0;
  right: 0;
  border-left: 0;
  border-radius: 0 100% 100% 0%/0 50% 50% 0;
  transform-origin: left;
}

.menu_slide .num .circle:nth-child(2)::before {
  bottom: 0;
  left: 0;
  border-right: 0;
  border-radius: 100% 0 0 100%/50% 0 0 50%;
  transform-origin: right;
}

.menu_slide .slick-active .num .circle {
  opacity: 1;
}

.menu_slide .slick-active .num .circle:nth-child(1)::before {
  animation: circle_progress 4s linear forwards;
}

.menu_slide .slick-active .num .circle:nth-child(2)::before {
  animation: circle_progress 4s linear 4s forwards;
}

@keyframes circle_progress {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.menu_slide .slick-arrow,
.stylist_slide .slick-arrow,
.style_slide .slick-arrow {
  position: absolute;
  bottom: 0;
  z-index: 100;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  padding: 12px;
  border-radius: 50%;
  border: 1px solid;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}
@media screen and (max-width: 47.9375em) {
  .menu_slide .slick-arrow,
.stylist_slide .slick-arrow,
.style_slide .slick-arrow {
    display: none !important;
  }
}
.menu_slide .slick-arrow.prev-arrow,
.stylist_slide .slick-arrow.prev-arrow,
.style_slide .slick-arrow.prev-arrow {
  right: -100px;
  transform: scale(-1, 1);
}
.menu_slide .slick-arrow.next-arrow,
.stylist_slide .slick-arrow.next-arrow,
.style_slide .slick-arrow.next-arrow {
  right: -180px;
}
.menu_slide .slick-arrow svg,
.stylist_slide .slick-arrow svg,
.style_slide .slick-arrow svg {
  width: 100%;
  height: auto;
}
.menu_slide .slick-arrow .cls-2,
.stylist_slide .slick-arrow .cls-2,
.style_slide .slick-arrow .cls-2 {
  fill: none;
  stroke: var(--btn-clr-main, #1d7fb7);
  transition: var(--transit-default, all 0.4s ease-out);
}
.menu_slide .slick-arrow:hover,
.stylist_slide .slick-arrow:hover,
.style_slide .slick-arrow:hover {
  color: var(--btn-clr-main-hvr, #fff);
  border-color: var(--btn-bg-main-hvr, #1d7fb7);
  background-color: var(--btn-bg-main-hvr, #1d7fb7);
}
.menu_slide .slick-arrow:hover svg,
.stylist_slide .slick-arrow:hover svg,
.style_slide .slick-arrow:hover svg {
  fill: var(--btn-clr-main-hvr, #fff);
}
.menu_slide .slick-arrow:hover .cls-2,
.stylist_slide .slick-arrow:hover .cls-2,
.style_slide .slick-arrow:hover .cls-2 {
  stroke: var(--btn-clr-main-hvr, #fff);
}

.menu_slide .slick-dots {
  position: absolute;
  top: 64px;
  left: -20px;
  display: flex;
  flex-direction: column;
}

.menu_slide .slick-dots li {
  display: flex;
}

.menu_slide .slick-dots button {
  width: 0.5rem;
  height: 0.5rem;
  padding: 0;
  margin-bottom: 4px;
  font-size: 0;
  border: solid 1px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
}

.menu_slide .slick-dots .slick-active button {
  background-color: var(--clr-body);
}

/* home_stylist
********************************************** */
.home_stylist {
  padding-top: 104px;
  padding-bottom: 128px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-a-blue.png), url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-o-blue.png);
  background-position: top 104px right, top 42% left 12%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_stylist {
    padding-top: 52px;
    padding-bottom: 64px;
    background-position: top 54px right -15%, bottom 10% left -200px;
  }
}

.home_style {
  padding-top: 104px;
  padding-bottom: 128px;
  background-color: #F3F1EE;
}
@media screen and (max-width: 47.9375em) {
  .home_style {
    padding-top: 52px;
    padding-bottom: 64px;
  }
}

.home_stylist .ttl-01,
.home_style .ttl-01 {
  margin-bottom: 1rem;
}

.stylist_slide .slick-list li,
.style_slide .slick-list li {
  width: 20%;
  padding: 0 1.4583333333vw;
}
@media screen and (max-width: 47.9375em) {
  .stylist_slide .slick-list li,
.style_slide .slick-list li {
    width: 33%;
    padding: 0 8px;
  }
}

.stylist_slide .link,
.style_slide .link {
  display: block;
}

.stylist_slide .post--img,
.style_slide .post--img {
  position: relative;
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.2);
}
.stylist_slide .post--img::before,
.style_slide .post--img::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.stylist_slide .post--img {
  margin-bottom: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .stylist_slide .post--img {
    margin-bottom: 1.5rem;
  }
}

.stylist_slide .more,
.style_slide .more {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: rgba(44, 44, 44, 0.3);
  opacity: 0;
  font-size: 1.125rem;
  transition: var(--transit-default, all 0.4s ease-out);
}

.stylist_slide .link:hover .more,
.style_slide .link:hover .more {
  opacity: 1;
}

.stylist_slide .txt {
  letter-spacing: -0.025em;
}

.stylist_slide .name {
  font-size: 1.5rem;
  letter-spacing: -0.025em;
}

.stylist_slide .slick-arrow,
.style_slide .slick-arrow {
  top: -208px;
  bottom: auto;
}
.stylist_slide .slick-arrow.prev-arrow,
.style_slide .slick-arrow.prev-arrow {
  right: 380px;
}
.stylist_slide .slick-arrow.next-arrow,
.style_slide .slick-arrow.next-arrow {
  right: 300px;
}

.stylist_slide .slick-dots,
.style_slide .slick-dots {
  display: flex;
  width: 80%;
  max-width: 800px;
  height: 2px;
  margin-top: 56px;
  margin-left: auto;
  margin-right: auto;
  background-color: #C9D6DA;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 49%, rgb(201, 214, 218) 50%, rgb(201, 214, 218) 50%);
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .stylist_slide .slick-dots,
.style_slide .slick-dots {
    margin-top: 36px;
  }
}

.stylist_slide .slick-dots li,
.style_slide .slick-dots li {
  flex: 1;
}

.stylist_slide .slick-dots button,
.style_slide .slick-dots button {
  display: block;
  width: 100%;
  height: 2px;
  padding: 0;
  margin: 0;
  font-size: 0;
  border: none;
  border-radius: 8px;
  background-color: #C9D6DA;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 49%, rgb(201, 214, 218) 50%, rgb(201, 214, 218) 50%);
  cursor: pointer;
}

.stylist_slide .slick-dots .slick-active button,
.style_slide .slick-dots .slick-active button {
  background: #6F909A;
  border-radius: 8px;
}

.style_slide .slick-dots,
.style_slide .slick-dots button {
  background: linear-gradient(to bottom, rgb(243, 241, 238) 49%, rgb(35, 35, 35) 50%, rgb(35, 35, 35) 50%);
}

.style_slide .slick-dots .slick-active button {
  background: #232323;
}

.home_stylist .btn-2 a,
.home_style .btn-2 a {
  padding-left: 0;
  padding-right: 2.5rem;
  font-size: 1.5rem;
}
.home_stylist .btn-2 a .arrow,
.home_style .btn-2 a .arrow {
  width: 1.125rem;
  height: 1.125rem;
  padding: 4px;
}

/* home_grayhair
********************************************** */
.home_grayhair {
  padding-bottom: 96px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-c-blue.png), url(/wp/wp-content/themes/standard_sass/images/home/stylist-deco-t-blue.png);
  background-position: top 20% right 16%, bottom 32px left 2%;
  background-repeat: no-repeat;
}
@media screen and (max-width: 47.9375em) {
  .home_grayhair {
    padding-bottom: 48px;
    background-position: top 0 right -200px, bottom 0 left -100px;
  }
}

.home_grayhair .ttl-01,
.home_news .ttl-01,
.home_blog .ttl-01 {
  margin-bottom: 2rem;
}

.home_grayhair .bnrarea {
  display: inline-block;
}

.home_grayhair .deco {
  position: absolute;
  top: -20%;
  left: -31%;
  z-index: -1;
  width: min(19.6875vw, 378px);
}
@media screen and (max-width: 47.9375em) {
  .home_grayhair .deco {
    top: -85%;
    left: -29%;
    width: 50%;
  }
}

.home_grayhair .bnrarea .img {
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
}

/* home_post
********************************************** */
.home_post {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/post-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_post {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.home_post .inner {
  max-width: 1024px;
}

/* --- home_news --- */
.home_news .news_list {
  background-color: #fff;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
}
@media screen and (max-width: 47.9375em) {
  .home_news .news_list {
    padding-left: 1.5rem;
    padding-right: 1.25rem;
  }
}

/* --- home_blog --- */
.home_blog .posts-blog .post {
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.3);
}
@media screen and (max-width: 47.9375em) {
  .home_blog .posts-blog .post:last-child {
    display: none;
  }
}

/* home_recruit
********************************************** */
.home_recruit {
  height: 590px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/recruit-bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media screen and (max-width: 47.9375em) {
  .home_recruit {
    height: auto;
    background-position: left 37% center;
  }
}

.home_recruit .inner {
  max-width: 1500px;
  height: 100%;
}

@media screen and (max-width: 47.9375em) {
  .home_recruit .txtarea {
    width: 60%;
    margin-left: auto;
    margin-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 23.75em) {
  .home_recruit .ttl-01 {
    font-size: 2.75rem;
  }
}

/* home_info
********************************************** */
.home_info {
  padding-top: 80px;
  padding-bottom: 64px;
  background-image: url(/wp/wp-content/themes/standard_sass/images/home/concept-deco03.svg);
  background-repeat: no-repeat;
  background-size: 475px auto;
  background-position: left 47% bottom 8%;
}
@media screen and (max-width: 47.9375em) {
  .home_info {
    padding-top: 56px;
    padding-bottom: 80px;
    background-size: 50% auto;
    background-position: right 10% bottom 30%;
  }
}
.home_info::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  display: block;
  width: 20.8333333333vw;
  background-color: #F4F4F4;
}

.home_info .txtarea {
  flex: 1;
  padding-left: 10.5208333333vw;
  line-height: 1.8;
  letter-spacing: -0.05em;
}

.home_info .imgarea {
  width: 43.2291666667vw;
  margin-top: -24px;
  padding-right: 2.9166666667vw;
}
@media screen and (max-width: 47.9375em) {
  .home_info .imgarea {
    width: 100%;
    margin-top: 16px;
  }
}

.home_info .imgarea .img-01,
.home_info .imgarea .img-02 {
  max-width: 90%;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.2);
}
@media screen and (max-width: 47.9375em) {
  .home_info .imgarea .img-01,
.home_info .imgarea .img-02 {
    max-width: 60%;
  }
}

.home_info .imgarea .img-02 {
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: 0;
}
@media screen and (max-width: 47.9375em) {
  .home_info .imgarea .img-02 {
    margin-top: -40px;
  }
}

/* --- inner --- */
.inner {
  width: 90%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 47.9375em) {
  .inner {
    width: 86%;
  }
}

.inner-sm {
  max-width: 880px;
}

.inner-lg {
  max-width: 1440px;
}

.inner-xl {
  max-width: calc(100% - 112px);
}
@media screen and (max-width: 67.5em) {
  .inner-xl {
    max-width: 90%;
  }
}
@media screen and (max-width: 47.9375em) {
  .inner-xl {
    max-width: 86%;
  }
}

/* --- section_pdg --- */
.section_pdg {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.section_pdg-sm {
  padding-top: 40px;
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .section_pdg-sm {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  background-color: var(--bg-off_wht, #f7f7f7);
  height: 400px;
}
@media screen and (max-width: 47.9375em) {
  .page_ttl {
    height: 200px;
  }
}

.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}

.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-size: 5rem;
  letter-spacing: -0.025em;
  line-height: 1.5;
  font-family: var(--font-en, "Montserrat", sans-serif);
}
@media screen and (max-width: 47.9375em) {
  .page_ttl-en {
    font-size: 2.5rem;
  }
}

.page_ttl-jp {
  font-size: 1rem;
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  letter-spacing: -0.025em;
}

.ttl-01,
.ttl-02,
.ttl-03,
.ttl-04 {
  position: relative;
}

.ttl-02,
.ttl-03:not(:last-child),
.ttl-04:not(:last-child) {
  margin-bottom: 1em;
}

.ttl-01 {
  margin-bottom: 3rem;
  font-size: 6rem;
  line-height: 1.2;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .ttl-01 {
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }
}

.ttl-01.deco::after {
  content: "";
  display: block;
  width: 26px;
  border-top: 6px dotted;
  margin-top: 1.75rem;
}
@media screen and (max-width: 47.9375em) {
  .ttl-01.deco::after {
    width: 14px;
    border-top-width: 3px;
    margin-top: 1rem;
  }
}

.ttl-02 {
  font-size: 2.5rem; /* 40px */
}
@media screen and (max-width: 47.9375em) {
  .ttl-02 {
    font-size: 1.75rem;
  }
}

.lps_parts--img_text .inner-lg .ttl-03 {
  font-size: 2.5rem; /* 40px */
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .inner-lg .ttl-03 {
    font-size: 1.5rem;
  }
}

.ttl-03 {
  font-size: 1.75rem; /* 28px */
}
@media screen and (max-width: 47.9375em) {
  .ttl-03 {
    font-size: 1.5rem;
  }
}

.ttl-04 {
  font-size: 1.5rem; /* 24px */
}

.ttl-04 small {
  display: block;
  opacity: 0.5;
  margin-top: 0.5em;
  font-weight: 500;
}

.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
}

/* ##############################################################################

    ARCHIVE

############################################################################## */
/* main_column
**************************************** */
.main_column {
  width: calc(100% - 80px - 264px);
  margin-right: 80px;
}

/* --- post --- */
.post {
  position: relative;
}

.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.post--img {
  overflow: hidden;
  background-color: #f8f8f8;
}

.post--img img:not(.img-cover) {
  inset: 0;
  max-width: 80%;
  margin: auto;
}

.hover-lg .post--img img {
  transition: all 0.8s ease-out;
}

.hover-lg:hover .post--img img {
  transform: scale(1.05);
}

.post--date {
  letter-spacing: 0.1em;
}

.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}

.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.75rem;
  border: 1px solid;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.125em 1em;
  float: left;
  white-space: nowrap;
  margin: 2px;
}

.cat_list a:hover {
  color: var(--clr-wht, #fff);
  border-color: var(--clr-main, #1d7fb7);
  background-color: var(--clr-main, #1d7fb7);
}

@media screen and (max-width: 68.75em) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
}
/* side_column
**************************************** */
.side_column {
  width: 264px;
}
@media screen and (max-width: 68.75em) {
  .side_column {
    width: 100%;
  }
}

.side_section:not(:last-child) {
  margin-bottom: 4rem;
}

.side--ttl {
  letter-spacing: 0.2em;
  line-height: 1.2;
  margin-bottom: 1em;
}

.side--ttl small {
  opacity: 0.5;
  letter-spacing: 0.08em;
  display: block;
  margin-top: 0.5em;
  font-weight: 500;
}

.mce-content-body .side--list a {
  text-decoration: none;
}
.mce-content-body .side--list a:hover {
  opacity: 0.7;
}

.mce-content-body .side--list .children {
  margin-top: 0;
}

.mce-content-body .side--list .children li::before {
  background-color: var(--clr-wht, #fff);
}

/* --- list --- */
.side--list a {
  display: block;
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 1rem;
}

.posts-side .post--link:hover {
  opacity: 0.7;
}

.posts-side .post--img {
  margin-right: 1rem;
  width: 4rem;
  height: 4rem;
}

.posts-side .txtarea {
  flex: 1;
}

.posts-side .post--date {
  margin-bottom: 0;
}

.posts-side .post--ttl {
  line-height: 1.4;
}

.posts-side .post--txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .posts-side .post--txt {
    margin-top: 6px;
    -webkit-line-clamp: 2;
    line-height: 1.5;
  }
}

/* --- archive --- */
.side_column .archive_list {
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #D3D3D3;
}

.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  transition: var(--trans-default, all 0.4s ease-out);
}
.side_column .archive_list--ttl:hover {
  opacity: 0.7;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}

.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}

.side_column .archive_month {
  display: none;
  padding-bottom: 0.25em;
}

.side_column .archive_month li {
  position: relative;
  padding-left: 1.5em;
}
.side_column .archive_month li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0.5em;
  width: 0.5em;
  height: 1px;
  border-bottom: 1px solid;
}

.side_column .archive_month a:hover {
  opacity: 0.7;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 10;
}

.archive-pulldown .archive_list {
  position: relative;
}

.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}

.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #D3D3D3;
}
.archive-pulldown .archive_list a:hover {
  background-color: #C9D6DA;
}

.archive-pulldown .archive_list--label {
  margin-right: 0.5rem;
}

.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}

.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}

.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}

/* --- cat --- */
.cat {
  margin-bottom: 2.5rem;
  z-index: 2;
}
@media screen and (max-width: 47.9375em) {
  .cat {
    border: 1px solid;
  }
}

.cat--list a {
  position: relative;
  display: block;
  padding: 0.5em 1em;
}
@media screen and (max-width: 47.9375em) {
  .cat--list a {
    background-color: var(--clr-wht, #fff);
  }
}
@media print, screen and (min-width: 48em) {
  .cat--list a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1.5rem;
    height: 1.5rem;
    background-color: #C9D6DA;
    border-radius: 50%;
    z-index: -1;
    opacity: 0;
    transition: var(--transit-default, all 0.4s ease-out);
  }
}

@media print, screen and (min-width: 48em) {
  .cat--list .current-cat a::before,
.cat--list a:hover::before {
    opacity: 1;
  }
}

@media screen and (max-width: 47.9375em) {
  .cat--label {
    position: relative;
    padding: 0.5em 1em;
  }
  .cat--label::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1em;
    margin: auto;
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 1px solid;
    border-right: 1px solid;
    transform: rotate(45deg);
  }
  .cat--pulldown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .cat--pulldown.active {
    opacity: 1;
    pointer-events: auto;
  }
  .cat--close {
    position: relative;
    width: 2.8571428571rem;
    height: 2.8571428571rem;
    align-self: flex-end;
    margin-right: 7%;
  }
  .cat--close::before, .cat--close::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 90%;
    height: 1px;
    border-bottom: 1px solid;
  }
  .cat--close::before {
    transform: rotate(45deg);
  }
  .cat--close::after {
    transform: rotate(-45deg);
  }
  .cat--list {
    display: block;
    border: 1px solid;
    width: 86%;
    max-height: 323px;
    overflow: auto;
  }
  .cat--list li:not(:last-child) {
    border-bottom: 1px solid #D3D3D3;
  }
  .cat--list .current-cat a {
    background-color: #C9D6DA;
  }
}
/* style
**************************************** */
@media print, screen and (min-width: 48em) {
  .archive .posts-style {
    display: flex;
    flex-wrap: wrap;
  }
}

.archive .posts-style .post {
  margin-bottom: 8px;
}
@media print, screen and (min-width: 48em) {
  .archive .posts-style .post {
    width: calc(33% - 8px);
    margin-right: 8px;
  }
}

.posts-style .post--img {
  width: 100%;
}
.posts-style .post--img::before {
  content: "";
  display: block;
  padding-top: 133.3333333333%;
}

.posts-style .post--link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.posts-style .post--txtarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  transition: var(--transit-default, all 0.4s ease-out);
}

.posts-style .post:hover .post--txtarea {
  background-color: rgba(44, 44, 44, 0.3);
}

.posts-style .post--ttl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default, all 0.4s ease-out);
}

.posts-style .post--txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: var(--transit-default, all 0.4s ease-out);
}

.posts-style .post:hover .post--ttl,
.posts-style .post:hover .post--txt {
  opacity: 1;
}

/* blog
**************************************** */
.posts-blog .post {
  box-shadow: 0 0 16px rgba(90, 116, 138, 0.3);
  background-color: #fff;
}

.posts-blog .post--img::before {
  padding-top: 75%;
}

.posts-blog .post--txtarea {
  padding: 0.375rem 1.25rem 0.75rem;
  word-break: break-word;
}

.posts-blog .cat_list {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.posts-blog .post--ttl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* news
**************************************** */
/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}

.news--archive li {
  margin-left: 8px;
  margin-right: 8px;
}

.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 1rem 2rem;
  margin-bottom: 0;
  letter-spacing: 0.01em;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post {
    display: block;
    padding: 1rem 0 1.5rem;
  }
}

.news_list .post:not(:first-child) {
  border-top: 1px solid #D3D3D3;
}

.news_list .post--info {
  margin-right: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info {
    margin-bottom: 4px;
    margin-right: 0;
  }
}
.news_list .post--info::before {
  content: "";
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: solid 1px;
  margin-right: 2rem;
}
@media screen and (max-width: 47.9375em) {
  .news_list .post--info::before {
    margin-right: 0.5rem;
  }
}

.news_list .post--date {
  min-width: 10em;
}

.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}

.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}

/* ##############################################################################

    SEARCH

############################################################################## */
#search-list .section_pdg {
  padding-top: 50px;
  padding-bottom: 50px;
}

#search-list .ttl-01 {
  margin: 70px 0 0;
}

/* ##############################################################################

    SINGLE

############################################################################## */
/* --- アイキャッチ --- */
.eyecatch {
  margin-bottom: 2rem;
}

/* --- 目次 --- */
.ez-toc-debug-messages {
  display: none;
}

div#ez-toc-container {
  padding: 24px;
  background: #fafafa;
}

.ez-toc-title-container {
  margin-bottom: 10px;
}

div#ez-toc-container p.ez-toc-title {
  color: #000;
  font-weight: bold;
  font-size: 1.25rem;
}

#ez-toc-container.counter-hierarchy ul {
  font-size: 1.0625rem;
}

#ez-toc-container.counter-hierarchy ul li {
  margin-top: 8px;
}

#ez-toc-container.counter-hierarchy ul ul {
  margin-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a {
  position: relative;
  display: inline-block;
  padding-left: 1rem;
}

#ez-toc-container.counter-hierarchy ul ul a::before {
  content: "";
  position: absolute;
  top: 0.65rem;
  left: 0;
  width: 0.6rem;
  height: 0;
  border-bottom: 1px solid;
}

/* pass
**************************************** */
#pass p {
  margin-bottom: 1em;
}

#pass input[name=post_password] {
  padding: 5px;
  border: solid 1px #aaa;
  outline: none;
}

#pass input[type=submit] {
  padding: 4px 16px;
  letter-spacing: 0.15em;
  background-color: #ddd;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
}

#pass input[type=submit]:hover {
  opacity: 0.7;
}

/* blog--share
**************************************** */
.blog--share,
.blog--related {
  margin-top: 2.5rem;
}

.blog--share_ttl {
  line-height: 1.2;
}

.blog--share_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.375rem; /* 6px */
}
@media screen and (max-width: 47.9375em) {
  .blog--share_list {
    grid-template-columns: repeat(2, 1fr);
  }
}

.blog--share_list a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0.5em;
  line-height: 1;
  font-size: 0.75rem; /* 12px */
  font-weight: bold;
  border: 1px solid #ddd;
}

.blog--share_list li.twitter a {
  color: #2BA1F2;
}
.blog--share_list li.facebook a {
  color: #2477F2;
}
.blog--share_list li.line a {
  color: #27C754;
}
.blog--share_list li.pocket a {
  color: #EF4056;
}
.blog--share_list li.linkedin a {
  color: #2867B2;
}
.blog--share_list li.hatena a {
  color: #29A4DE;
}

.blog--share_list img {
  margin-right: 10px;
}

.blog--share_list a:hover {
  opacity: 0.5;
}

/* single-style
**************************************** */
.single-style .main_column .ttl-02 {
  margin-bottom: 1.5rem;
}

.single-style .lps_parts--slide li {
  display: flex !important;
  background-color: #f8f8f8;
}
.single-style .lps_parts--slide li:before {
  content: "";
  display: block;
  padding-top: 110%;
}
.single-style .lps_parts--slide li img {
  max-height: 100%;
  width: auto;
}

.post-stylist {
  margin-top: 80px;
  margin-bottom: 80px;
  padding-top: 40px;
  border-top: solid 1px;
}
@media screen and (max-width: 47.9375em) {
  .post-stylist {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.post-stylist .imgarea {
  width: 30%;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(90, 116, 138, 0.2);
}
.post-stylist .imgarea::before {
  padding-top: 100%;
}
@media screen and (max-width: 47.9375em) {
  .post-stylist .imgarea {
    width: 70%;
    max-width: 260px;
  }
}

@media screen and (min-width: 48em) {
  .post-stylist .txtarea {
    flex: 1;
    padding-left: 40px;
  }
}
@media screen and (max-width: 47.9375em) {
  .post-stylist .txtarea {
    width: 100%;
    padding-top: 24px;
  }
}

.post-stylist .writer {
  line-height: 1.2;
}

.post-stylist .instagram {
  display: inline-block;
  width: 1.5rem;
  margin-left: 0.5rem;
  transform: translateY(2px);
}

/* blog--related
**************************************** */
.posts-scroll {
  overflow: auto;
  padding-bottom: 2rem;
}

.posts-scroll .post {
  display: block;
  width: 46%;
  flex-shrink: 0;
}
@media screen and (max-width: 47.9375em) {
  .posts-scroll .post {
    width: 70%;
  }
}

.posts-scroll .post:not(:last-child) {
  margin-right: 1rem;
}

.posts-scroll:not(.posts-style) .post--img {
  margin-bottom: 1rem;
}
.posts-scroll:not(.posts-style) .post--img::before {
  padding-top: 75%;
}

.posts-scroll .post--txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.posts-scroll::-webkit-scrollbar {
  height: 6px;
}

.posts-scroll::-webkit-scrollbar-thumb {
  background-color: #999;
}

.posts-scroll::-webkit-scrollbar-track-piece {
  background-color: #eee;
}

/* wp-pagenavi
**************************************** */
.wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2.5rem;
}

.wp-pagenavi .pages {
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
}

.wp-pagenavi a {
  position: relative;
  padding: 0.35em 1em;
  background-color: var(--clr-main, #1d7fb7);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.wp-pagenavi a:hover {
  opacity: 0.7;
}

.wp-pagenavi a {
  color: #fff;
}

.wp-pagenavi > *:not(.pages) {
  max-width: 38.4615384615%;
}

.archive .wp-pagenavi > *:not(.pages) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 47.9375em) {
  .archive .wp-pagenavi > *:not(.pages) {
    width: 2rem;
    height: 2rem;
  }
}

.wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
  width: 40px;
}

.wp-pagenavi > *:not(.pages) {
  margin-bottom: 4px;
}

.wp-pagenavi > *:not(.pages):not(:last-child) {
  margin-right: 4px;
}

.wp-pagenavi .extend {
  width: 24px !important;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-top: 1px solid;
  border-right: 1px solid;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .last::before,
.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  width: 0.5rem;
  height: 0.5rem;
}

.wp-pagenavi a[rel=prev]::before,
.wp-pagenavi .first::before,
.wp-pagenavi .first::after {
  transform: rotate(-135deg);
}

.wp-pagenavi a[rel=next]::before,
.wp-pagenavi .last::before,
.wp-pagenavi .last::after {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=prev]::before {
  transform: rotate(45deg);
}

.wp-pagenavi-single a[rel=next]::before {
  transform: rotate(-135deg);
}

.wp-pagenavi .first::before,
.wp-pagenavi .last::before {
  left: -0.375rem;
}

.wp-pagenavi .first::after,
.wp-pagenavi .last::after {
  right: -0.375rem;
}

@media screen and (max-width: 47.9375em) {
  .wp-pagenavi-single a[rel=prev],
.wp-pagenavi-single a[rel=next] {
    width: sprem(32);
  }
}
/* sitemap
*************************************************** */
.sitemap--menu {
  flex: 1;
}

.sitemap--menu a,
.sitemap--menu span {
  display: block;
}

.sitemap--menu a {
  position: relative;
}

.sitemap--menu a:hover {
  color: var(--clr-main, #1D7FB7);
}

.sitemap--menu > li > a {
  font-size: 1.125rem; /* 18px */
  border-bottom: 1px solid #D3D3D3;
  padding-bottom: 0.5em;
  margin-bottom: 1.25em;
}

.sitemap_sub_nav--wrap {
  padding-left: 1.25em;
  margin-bottom: 1.5em;
}

.sitemap_sub_nav a {
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 1.25em;
}

.sitemap_sub_nav a::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 6px;
  height: 6px;
  border-top: 1px solid var(--clr-main, #1D7FB7);
  border-right: 1px solid var(--clr-main, #1D7FB7);
  transform: rotate(45deg);
}

@media print, screen and (min-width: 48em) {
  .sitemap--menu:not(:last-child) {
    margin-right: 7.41%; /* 80px */
  }
}
@media screen and (max-width: 47.9375em) {
  .sitemap--menu:not(:last-child) {
    margin-bottom: 24px;
  }
}
/* lps_parts--button
********************************************** */
.lps_parts--button .btn:first-child {
  margin-left: auto;
}

.lps_parts--button .btn:last-child {
  margin-right: auto;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--column.column-2 + .lps_parts--button,
.lps_parts--column.column-3 + .lps_parts--button,
.lps_parts--column.column-4 + .lps_parts--button {
    margin-top: 0;
  }
}
/* lps_parts--column
********************************************** */
.lps_parts--column:not(.column-1) + .lps_parts--column {
  margin-top: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
}

.lps_parts--column .column_item,
.lps_parts--column .text {
  display: flex;
  flex-direction: column;
}

.lps_parts--column:not(.column-1) .text {
  height: 100%;
}

.lps_parts--column .img {
  flex-shrink: 0;
}

.lps_parts--column .img + .text {
  padding-top: 24px;
}

.lps_parts--column .bg-wh .text {
  padding: 6%;
}

.lps_parts--column:not(.column-1) table {
  width: 100%;
}

.lps_parts--column.column-1 .box {
  padding: 40px;
}

.lps_parts--column.column-3 .btn a,
.lps_parts--column.column-4 .btn a {
  min-width: 100%;
}

@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
.lps_parts--column:not(.column-1) + .lps_parts--column {
    margin-top: 24px;
  }
  .lps_parts--column .lps_parts--child {
    display: block;
  }
  .lps_parts--column {
    width: 100%;
    max-width: 100%;
  }
  .lps_parts--column .column_item {
    width: 100%;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-bottom: 24px;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
  .lps_parts--column .column_item:not(:last-child) {
    margin-right: 0;
  }
  .lps_parts--column .text.bg-wh,
.lps_parts--column.column-1 .box {
    padding: 24px;
  }
}
/* post-edit-link
********************************************** */
.post-edit-link {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
  padding: 16px;
  border-radius: 50%;
  border: 1px solid #999;
}
@media screen and (max-width: 47.9375em) {
  .post-edit-link {
    display: none;
  }
}
.post-edit-link:hover {
  background-color: #ddd;
}
.post-edit-link::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIzLjAuMywgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuODrOOCpOODpOODvF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiCgkgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDAxIDQwMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAxIDQwMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiM5OTk5OTk7fQo8L3N0eWxlPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzcwLjEsMjUxLjljLTUuNSwwLTEwLDQuNS0xMCwxMHY4OC43YzAsMTYuNS0xMy40LDI5LjktMzAsMzBINDkuOWMtMTYuNSwwLTI5LjktMTMuNC0zMC0zMFY5MC4zCgljMC0xNi41LDEzLjQtMjkuOSwzMC0zMGg4OC43YzUuNSwwLDEwLTQuNSwxMC0xMGMwLTUuNS00LjUtMTAtMTAtMTBINDkuOUMyMi40LDQwLjQsMCw2Mi43LDAsOTAuM3YyNjAuMwoJYzAsMjcuNiwyMi40LDQ5LjksNDkuOSw0OS45aDI4MC4yYzI3LjYsMCw0OS45LTIyLjQsNDkuOS00OS45di04OC43QzM4MC4xLDI1Ni40LDM3NS42LDI1MS45LDM3MC4xLDI1MS45eiIvPgo8cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzc2LjEsMTQuN2MtMTcuNi0xNy42LTQ2LTE3LjYtNjMuNiwwTDEzNC40LDE5Mi45Yy0xLjIsMS4yLTIuMSwyLjctMi42LDQuNGwtMjMuNCw4NC42Yy0xLDMuNSwwLDcuMiwyLjYsOS43CgljMi41LDIuNSw2LjMsMy41LDkuNywyLjZsODQuNi0yMy40YzEuNy0wLjUsMy4yLTEuMyw0LjQtMi42TDM4Ny45LDkwYzE3LjUtMTcuNiwxNy41LTQ2LDAtNjMuNkwzNzYuMSwxNC43eiBNMTU2LjIsMTk5LjNMMzAyLDUzLjUKCWw0Nyw0N0wyMDMuMiwyNDYuNEwxNTYuMiwxOTkuM3ogTTE0Ni44LDIxOC4ybDM3LjYsMzcuNmwtNTIsMTQuNEwxNDYuOCwyMTguMnogTTM3My43LDc1LjhsLTEwLjYsMTAuNmwtNDctNDdsMTAuNi0xMC42CgljOS43LTkuNywyNS42LTkuNywzNS4zLDBsMTEuNywxMS43QzM4My41LDUwLjMsMzgzLjUsNjYuMSwzNzMuNyw3NS44eiIvPgo8L3N2Zz4K");
}

/* lps_parts--faq
********************************************** */
.lps_parts--faq .faq_item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .faq_item:not(:last-child) {
    margin-bottom: 20px;
  }
}

.lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
  padding: 20px 48px 20px 104px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03,
.lps_parts--faq .mce-content-body {
    padding: 12px 24px 12px 52px;
  }
}

.lps_parts--faq .ttl-03 {
  background-color: var(--clr-main, #1d7fb7);
  margin-bottom: 0 !important;
  font-size: 1.5rem; /* 24px */
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03 {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}

.lps_parts--faq .mce-content-body {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .mce-content-body {
    line-height: 1.5;
  }
}

.lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
  position: absolute;
  left: 48px;
  display: block;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
  font-size: 2rem; /* 32px */
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after,
.lps_parts--faq .mce-content-body::after {
    left: 20px;
    font-size: 1.5rem;
  }
}

.lps_parts--faq .ttl-03::after {
  content: "Q";
  top: 12px;
  color: #fff;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .ttl-03::after {
    top: 6px;
  }
}

.lps_parts--faq .mce-content-body::after {
  content: "A";
  top: 2px;
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .mce-content-body::after {
    top: 6px;
  }
}

.lps_parts--faq .mce-content-body .editor-ttl2 {
  margin-top: 0;
}

.lps_parts--faq .text img {
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--faq .text img {
    float: none !important;
    margin-top: 2px;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 8px !important;
    border-radius: 8px;
  }
}

/* lps_parts--flow
********************************************** */
.lps_parts--flow .flow_item {
  position: relative;
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 86px;
  counter-increment: number;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item {
    padding-left: 48px;
  }
}

.lps_parts--flow .flow_item:not(:last-child) {
  padding-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child) {
    padding-bottom: 32px;
  }
}

.lps_parts--flow .ttl-03 {
  font-size: 2rem; /* 32px */
  color: var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03 {
    font-size: 1.429rem; /* 20px */
    line-height: 1.5;
  }
}
.lps_parts--flow .ttl-03::after {
  content: counter(number, decimal-leading-zero);
  position: absolute;
  top: -8px;
  left: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  background-color: var(--clr-main, #1D7FB7);
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-weight: 400;
  font-size: 1.625rem; /* 26px */
  letter-spacing: 0;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .ttl-03::after {
    top: -2px;
    left: -48px;
    width: 2.25rem;
    height: 2.25rem;
    font-size: 1.286rem; /* 18px */
  }
}

.lps_parts--flow .flow_item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 64px;
  left: 36px;
  display: block;
  width: 0;
  height: calc(100% - 80px);
  border-right: 1px solid var(--clr-main, #1d7fb7);
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .flow_item:not(:last-child)::before {
    top: 38px;
    left: 14px;
    height: calc(100% - 48px);
  }
}

.lps_parts--flow .txt-wh .ttl-03 {
  color: #fff;
}

.lps_parts--flow .flow_item.txt-wh:not(:last-child)::before {
  border-color: #fff;
}

.lps_parts--flow .txt-wh .ttl-03::after {
  color: var(--clr-main, #1D7FB7);
  background-color: #fff;
}

.lps_parts--flow .text img {
  float: left;
  max-width: 248px;
  margin-top: 0;
  margin-right: 40px;
  overflow: hidden;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--flow .text img {
    float: none !important;
    max-width: 100%;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
  }
}

/* ##############################################################################

    Loops モジュール

############################################################################## */
.btn .icon-svg {
  position: absolute;
  top: 50%;
  display: block;
  transform: translateY(-50%);
  transition: var(--transit-default, all 0.4s ease-out);
}

.icon-new_tab {
  width: 0.875rem;
  height: 0.875rem;
}

.btn .icon-new_tab {
  right: 1.875rem;
}

.icon-pdf {
  width: 1.6875rem;
  height: 0.75rem;
}

.btn .icon-pdf {
  left: 0.9375rem;
}

.lps_sec {
  position: relative;
}

.lps_sec-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-position: center;
}

/* ttl
********************************************** */
.lps_sec .ttl-01 {
  text-align: center;
}

/* lps_parts
********************************************** */
.lps_parts {
  position: relative;
  z-index: 1;
}

.lps_parts .img-detail::before {
  padding-top: 66.666%;
}

.lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
  margin-top: 80px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts + .ttl-02,
.lps_parts--button + .ttl-02,
.lps_parts--button + .lps_parts,
.lps_parts + .lps_parts {
    margin-top: 40px;
  }
}

.lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
  margin-top: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--column + .lps_parts--column,
.lps_parts + .lps_parts--button {
    margin-top: 24px;
  }
}

.lps_parts iframe {
  width: 100%;
}

.lps_parts .text:not(:last-child) {
  margin-bottom: 24px;
}

.lps_parts--column .btn,
.lps_parts--slide .btn {
  text-align: center;
}

.lps_parts--column *:not(.btn) + .btn {
  margin-top: auto;
}

.lps_parts--column .btn a {
  margin-left: auto;
  margin-right: auto;
}

.lps_parts .mce-content-body:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 47.9375em) {
  .lps_parts .mce-content-body:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 47.9375em) {
  .lps_parts .btn {
    text-align: center;
  }
  .lps_parts--column.column-2 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-3 .lps_parts--child.flex-sp-block .column_item,
.lps_parts--column.column-4 .lps_parts--child.flex-sp-block .column_item {
    width: 100%;
    margin-right: 0;
  }
}
/* lps_parts--img_text
********************************************** */
.lps_parts--img_text .anchor {
  padding-top: 60px;
  margin-top: -60px;
}

.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child {
  width: 100%;
}

.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.lps_parts--img_text .img_text--child .img-detail {
  height: 100%;
}

.lps_parts--img_text .bg-wh .img_text--txt {
  padding: 5%;
}

.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}

.lps_parts--img_text .text {
  justify-content: flex-start;
}

.lps_parts--img_text .img_text--txt table {
  width: 100%;
}

.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}

.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}

.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}

.lps_parts--img_text .inner:not(.inner-lg) .img-detail {
  border-radius: 50%;
  overflow: hidden;
}

.lps_parts--img_text .inner:not(.inner-lg) .img-detail::before {
  padding-top: 100%;
}

@media print, screen and (min-width: 48em) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--img_text .anchor {
    padding-top: 8px;
    margin-top: -8px;
  }
  .lps_parts--img_text .bg-wh .img_text--txt {
    padding: 32px 24px;
  }
  .lps_parts--img_text .btn {
    text-align: center;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 40px;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img-detail {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}
/* lps_parts--slide
********************************************** */
.lps_parts--slide .img img {
  display: inline-block;
}

.lps_parts--slide .img + .text {
  margin-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .img + .text {
    margin-top: 16px;
  }
}

.lps_parts--slide .slick-list {
  margin-bottom: 32px;
}

.lps_parts--slide .slick-arrow {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  text-decoration: none;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow {
    width: 56px;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow {
    width: 40px;
  }
}

.lps_parts--slide .slick-prev {
  left: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-prev {
    left: 0;
  }
}

.lps_parts--slide .slick-next {
  right: -60px;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-next {
    right: 0;
  }
}

.lps_parts--slide .slick-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-dots {
    position: relative;
    bottom: 4px;
  }
}

.lps_parts--slide .slick-arrow::after {
  content: "";
  display: block;
  width: 1.875rem;
  height: 1.875rem;
  transform: rotate(45deg);
}
@media screen and (max-width: 67.5em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.25rem;
    height: 1.25rem;
  }
}
@media screen and (max-width: 47.9375em) {
  .lps_parts--slide .slick-arrow::after {
    width: 1.1428571429rem;
    height: 1.1428571429rem;
  }
}

.lps_parts--slide .slick-prev::after {
  border-bottom: 1px solid;
  border-left: 1px solid;
}

.lps_parts--slide .slick-next::after {
  border-top: 1px solid;
  border-right: 1px solid;
}

.lps_parts--slide .slick-dots li {
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: var(--transit-default, all 0.4s ease-out);
  margin: 0.375rem;
}

.lps_parts--slide .slick-dots li.slick-active {
  background-color: var(--clr-main, #1d7fb7);
}

.lps_parts--slide .slick-dots li:hover {
  opacity: 0.7;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}