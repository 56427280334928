@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 117px;
  z-index: z(header);
  transition: var(--transit-default, all .4s ease-out);
  & > .inner {
    max-width: 1274px;
    height: 100%;
  }
  @include mq(med) {
    height: 64px;
    background-color: var(--bg-wht, #fff);
    & > .inner {
      width: 100%;
      max-width: 100%;
      padding-left: 4%;
    }
  }
}
.page-contact:not(.page-thanks) .header {
  position: absolute;
}
.header--logo {
  transition: var(--transit-default, all .4s ease-out);
  @include mq(med, min, ps) {
    position: absolute;
    top: 50%;
    left: vw(64);
    transform: translateY(-55%);
  }
}
.header--logo a,
.footer--logo a {
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}
.home .header:not(.header-sm) .header--logo {
  @include mq(med, min, ps) {
    transform: translateY(-30%);
  }
}

@include mq(med, min, ps) {
  .header-sm {
    background-color: var(--bg-wht, #fff);
    height: 104px;
  }
}
@include mq(med) {
  .header--logo img {
    width: auto;
    max-height: 40.5px;
  }
}

/* gnav
********************************************** */
.gnav a {
  display: flex;
  @include center-flex;
  letter-spacing: .01em;
  line-height: 1.2;
}
.gnav--link {
  position: relative;
  flex-direction: column;
  padding-left: 1.25em;
  padding-right: 1.25em;
  line-height: 1.4;
  @include mq(med, min, ps) {
    @include mq(1540) {
      padding-left: .5em;
      padding-right: .5em;
    }
  }
}
.gnav--link .font-en {
  text-transform: uppercase;
  letter-spacing: .05em;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  padding: .5em 1em;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  display: flex;
  align-items: center;
}
.cta_tel svg {
  fill: var(--clr-body, #333);
  margin-right: rem(7);
}
.cta_tel .num {
  font-size: 1.875rem; /* 30px */
}
.cta_contact svg {
  fill: var(--clr-wht, #fff);
}
.gnav--cta a {
  flex-direction: column;
  letter-spacing: 0;
}
.gnav--tel .num {
  font-size: rem(24);
}
.gnav--tel .hours {
  font-size: rem(15);
}
.gnav--tel a {
  display: flex !important;
}
.gnav .gnav--contact {
  @include mq(med, min, ps) {
    @include mq(1270) {
      position: absolute;
      top: 100%;
      right: vw(64);
    }
  }
}
.gnav--contact a {
  width: rem(195);
  height: rem(73);
  padding: 1em;
  line-height: 1.4;
  @include mq(med, min, ps) {
    @include mq(1540) {
      width: rem(168);
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: rem(16);
    height: 1px;
    border-bottom: 1px solid;
  }
}
.gnav--contact .font-en {
  position: relative;
  font-size: rem(20);
}
.gnav--contact svg {
  position: absolute;
  top: 50%;
  right: rem(21);
  transform: translateY(-50%) rotate(-90deg);
}
.header:not(.header-sm) .gnav--contact.pc a,
.hero--contact a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @include rect(124);
  height: rem(124);
  border-radius: 50%;
  letter-spacing: 0;
  transform: translateY(10px);
  @include mq(med, min, ps) {
    @include mq(1270) {
      transform: translateY(-10px);
    }
  }
  &::before {
    display: none;
  }
}
.header .gnav--contact {
  @include mq(med) {
    &.pc {
      display: none;
    }
  }
  @include mq(med, min) {
    &.sp {
      display: none;
    }
  }
}
.header:not(.header-sm) .gnav--contact.pc svg,
.hero--contact svg {
  position: static;
  transform: none;
  margin-bottom: rem(12);
}
.header:not(.header-sm) .gnav--contact.pc .font-en,
.hero--contact .font-en {
  position: relative;
  font-size: rem(16);
  padding-left: .5em;
  padding-right: .5em;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 1em;
    border-right: 1px solid;
  }
  &::before {
    left: 0;
    transform: rotate(-20deg);
  }
  &::after {
    right: 0;
    transform: rotate(20deg);
  }
}

@include mq(med, min, ps) {
  .gnav {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .gnav_btn,
  .contact_btn,
  .tel_btn {
    display: none;
  }
  .gnav--menu {
    padding-right: rem(24);
    @include mq(med, min, ps) {
      @include mq(1540) {
        padding-right: rem(12);
      }
    }
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link {
    height: rem(39) !important;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      @include rect(40);
      background-color: #C9D6DA;
      border-radius: 50%;
      z-index: -1;
      opacity: 0;
      transition: var(--transit-default, all .4s ease-out);
    }
  }
  .gnav--menu > li > .gnav--link:not([href="javascript:void(0);"]):hover::before,
  .gnav--menu > li.current-menu-item > .gnav--link::before {
    opacity: 1;
  }
  .head_sub_nav a {
    background-color: #fff;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .head_sub_nav--wrap {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 12em;
    padding-top: 32px;
    z-index: 100;
    transition: var(--transit-default, all .4s ease-out);
    opacity: 0;
    pointer-events: none;
  }
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: 0.7;
  }

  /* ---CTA --- */
  .gnav--tel a {
    position: relative;
    padding-left: rem(36);
    padding-right: rem(36);
    align-items: flex-end;
    @include mq(med, min, ps) {
      @include mq(1540) {
        padding-left: rem(20);
        padding-right: rem(20);
      }
      @include mq(1270) {
        padding-right: 0;
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: rem(39);
      background-color: var(--clr-main, #1d7fb7);
    }
  }
}
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav {
    width: 70%;
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    background-color: #f7f7f7;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu {
    margin-bottom: 20px;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
  }
  .gnav--menu li {
    border-bottom: 1px dotted var(--clr-main, #1d7fb7);
  }
  .gnav--menu a {
    align-items: flex-start;
    padding: 12.4px 16px;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-main, #1d7fb7);
    border-right: 1px solid var(--clr-main, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-main, #1d7fb7);
    display: none;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all .4s ease-out);
    color: var(--clr-main, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .contact_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 64px;
    height: 64px;
    border-left: 1px solid var(--clr-main, #1d7fb7);
    z-index: 9999;
  }
  .tel_btn {
    right: 128px;
  }
  .contact_btn {
    right: 64px;
  }
  .tel_btn a,
  .contact_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
    padding-bottom: 12px;
    line-height: 1;
  }
  .contact_btn a {
    color: #fff;
    background-color: var(--btn-bg-cta, #1d7fb7);
  }
  .tel_btn svg {
    fill: var(--clr-main, #1d7fb7);
  }
  .contact_btn svg {
    fill: var(--clr-wht, #fff);
  }
  .tel_btn .txt,
  .contact_btn .txt,
  .gnav_btn .txt {
    margin-top: 6px;
    font-size: 10px;
  }
  .gnav_btn {
    right: 0;
    padding-bottom: 12px;
    line-height: 1;
    transition: var(--transit-default, all .4s ease-out);
  }
  .gnav_btn.active {
    background-color: #f7f7f7;
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all .4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--clr-main, #1d7fb7);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-45deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-8px) rotate(45deg);
  }

  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 16px;
  }
  .gnav--tel {
    text-align: center;
  }
  .gnav--tel .num {
    font-size: rem(26);
  }
  .gnav--tel .hours {
    font-size: rem(16);
  }
  .gnav--contact a {
    width: 100%;
    padding: 10px 16px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}
@include mq(sp) {
  .gnav--menu a {
    padding: 13.1px 16px;
  }
}