@use '../abstracts' as *;
@use 'sass:math';

/* --- font-size --- */
// フォントサイズを8pxから140pxまでREMで生成
@for $i from 8 to 140 {
  .fz-#{$i} { font-size: math.div($i, 16) + rem}
}
// フォンt-サイズをREM:14引く1px
// これは古い .fz-{数字}のクラスをマネする
@include mq(sp) {
  @for $i from 8 to 140 {
    .fz-#{$i} { font-size: math.div(($i - 1), 14) + rem}
  }
}

/* txt
**************************************** */
.txt-lg { font-size: rem(18); }
.txt-sm { font-size: rem(14); }
.txt-xs { font-size: rem(12); }
.txt-wh,
.txt-wh * { color: var(--clr-wht, #fff); }
.txt-main,
.txt-main * { color: var(--clr-main, #1d7fb7); }
.txt-sub,
.txt-sub * { color: var(--clr-sub, #eee); }

/* --- font --- */
h1, h2, h3, h4, h5, h6, th {
  font-weight: 400;
}
.font-jp,
.bold {
  font-family: var(--font-jp, YuGothic, "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", sans-serif);
  font-weight: 700;
}
.font-en {
  font-family: var(--font-en, 'Montserrat', sans-serif);
  font-weight: 400;
}

/* txt
**************************************** */
.txt-ctr,
.txt-ctr-pc {
  text-align: center;
}
.txt-rgt,
.txt-rgt-pc {
  text-align: right;
}

@include mq(sp) {
  .txt-lg { font-size: sprem(15); }
  .txt-sm { font-size: sprem(13); }
  .txt-xs { font-size: sprem(11); }
  .txt-ctr-pc,
  .txt-rgt-pc { text-align: left; }
}